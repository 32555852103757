import React from 'react';

import { Box } from '@mui/material';
import { IssueComment, Issue } from 'types/api';
import styles from 'styles/components/CommentsEditorPanel.module.scss';

import SideSlider, { SideSliderFooter } from '../SideSlider/SideSlider';
import CommentField from '../CommentField/CommentField';
import CommentTaskInfo from '../CommentTaskInfo/CommentTaskInfo';
import Divider from '../Divider/Divider';
import CommentBox from '../CommentBox/CommentBox';

import useComments from './useComments';
import Footer from './Footer';

interface CommentEditorPanelProps {
  issue: Issue;
  onClose: () => void;
}

const CommentsEditorPanel = ({ issue, onClose }: CommentEditorPanelProps): React.ReactElement => {
  const { comments, fetchComments, addComment, editComment, togglePinComment, deleteComment } =
    useComments(issue.id);

  React.useEffect(() => {
    fetchComments();
  }, [issue.id]);

  return (
    <SideSlider handleCloseSlider={onClose} isOpen={true} title='Comments'>
      <CommentTaskInfo task={issue} />
      <Divider />
      <CommentField onSubmit={addComment} />
      <Box>
        <Box className={styles.comments_container}>
          {comments.map((comment: IssueComment) => (
            <CommentBox
              key={comment.id}
              comment={comment}
              onTogglePinComment={togglePinComment}
              onDelete={deleteComment}
              onEdit={editComment}
            />
          ))}
        </Box>
      </Box>
      <SideSliderFooter>
        <Footer onClose={onClose} />
      </SideSliderFooter>
    </SideSlider>
  );
};

export default CommentsEditorPanel;
