import React, { ReactElement, useState } from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import '../../styles/main.scss';
import IconMenu from 'components/Menu/Menu';
import ResponsiveAppBar from 'components/AppBar/ResponsiveAppBar';
import Grid from '@mui/material/Grid';
import { User } from 'types/user';
import Accordion from 'components/Accordion/Accordion';
import TaskStats from 'components/TaskStats/TaskStats';
import Button from 'components/Button/Button';
import {
  RightArrowLongIcon,
  SearchIcon,
  EstimateTimeIconSmall,
  ArrowDownUpIcon,
  UpLongArrow,
  DownLongArrow,
  CrossBlueIcon,
  SmallAvatarIcon
} from 'components/Icons';
import Divider from 'components/Divider/Divider';
import Input from 'components/Input/Input';
import Checkbox from 'components/Checkbox/Checkbox';
import IconTextCombo from 'components/IconTextCombo/IconTextCombo';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import ChipButton from 'components/ChipButton/ChipButton';
import AutocompleteGroup from 'components/AutocompleteGroup/AutocompleteGroup';
import SideSlider, { SideSliderFooter } from 'components/SideSlider/SideSlider';
import CommentTaskInfo from 'components/CommentTaskInfo/CommentTaskInfo';

import { Issue } from 'types/api';
import RangeSlider from 'components/RangeSlider/RangeSlider';

const mockTask: Issue = {
  id: '1',
  name: 'Committee thumbs-up and thumbs-down feature',
  issueId: '1',
  key: 'OP-1',
  priorityId: '1',
  description: 'Sample descirption',
  dueDate: '10.02.23',
  epicName: 'Uncategorized',
  flagged: false,
  status: {
    id: '10000',
    name: 'In Progress',
    iconUrl: 'http://sampleavatar.url/image.jpg',
    variant: 'default'
  },
  progress: {
    total: 0,
    progress: 0,
    percent: 0
  },
  timeTracking: {
    originalEstimate: '2h',
    remainingEstimate: '0h'
  },
  reporter: {
    name: 'Owais Afaq',
    profilePic: 'http://sampleavatar.url/image.jpg'
  },

  priority: {
    id: '1',
    name: 'P1',
    color: '#F00'
  },
  categories: []
};

const user: User = {
  userEmail: '',
  userName: 'Mr robot',
  userPicture:
    'https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png',
  emailVerified: true,
  token: '',
  isAuthenticated: false
};

interface ChipData {
  label: string;
}

const options = [
  { value: 'ascending', label: 'Ascending', icon: <UpLongArrow />,  },
  { value: 'descending', label: 'Descending', icon: <DownLongArrow /> }
];

const reporters = [
  { label: 'Ethan', icon: <SmallAvatarIcon />, },
  { label: 'Noah', icon: <SmallAvatarIcon /> },
  { label: 'Lucas 3', icon: <SmallAvatarIcon /> }
];

function UIProfile(): ReactElement {
  // const [selectedValue] = useState('weekly');

  const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number): void => {
    setActiveTab(newValue);
  };

  const tabLabels = ['General', 'Profile', 'Users', 'Groups', 'Billing'];

  const [searchTask, setSearchTask] = useState('');

  const [expandAll, setExpandAll] = useState(false);

  const [isChecked, setIsChecked] = useState(true);

  const handleCheckboxChange = (event: any): void => {
    setIsChecked(event.target.checked);
  };

  //Chip Data
  const [chips, setChips] = useState<ChipData[]>([
    { label: 'Last Activity' },
    { label: 'Reporter' }
  ]);

  const handleDelete = (chipToDelete: string): void => {
    setChips((currentChips) => currentChips.filter((chipData) => chipData.label !== chipToDelete));
  };

  const handleSelectedValuesChange = (selectedValues: reporter[]): void => {
    // Handle the selected values here
    console.log('Selected Values:', selectedValues);
  };

  type reporter = {
    label: string;
  };

  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const handleCloseSlider = (): void => {
    setIsSliderOpen(false);
  };

  const handleEditClick = (): void => {
    setIsSliderOpen(true);
  };

  const [houreRange, sethoureRange] = useState<[number, number]>([0, 100]);
   const [DayRange, setDayRange] = useState<[number, number]>([0, 24]);

  // percentage range change
  const HourChange = (values: [number, number]): void => {
    sethoureRange(values);
  };

  // time range change
  const DayChange = (values: [number, number]): void => {
    setDayRange(values);
  };

  return (
    <div>
      <Box className='wrapper'>
        <ResponsiveAppBar user={user} />
        <Box className='content_area '>
          <Box className='inner_content_area'>
            <Box className='d-flex justify-content-between align-items-center mb-20'>
              <Typography variant='h2' className='mb-0 page_heading'>
                BackLog
              </Typography>
              <Button size='large' iconPosition='right' disabled icon={<RightArrowLongIcon />}>
                Move to prioritize
              </Button>
            </Box>

            <SideSlider
              title='Task Details'
              isOpen={isSliderOpen}
              handleCloseSlider={handleCloseSlider}
            >
              <Box>
                <CommentTaskInfo task={mockTask}></CommentTaskInfo>
              </Box>
              <Divider></Divider>
              <Box className='thin_scrollbar overflow_scroll task_drawer_content'>
                <Typography variant='body2'>
                  <strong>Task description</strong>
                </Typography>
                <Typography>
                  Incorporating a &quot;Committee Thumbs-Up and Thumbs-Down Feature&quot; into your system is
                  a dynamic and interactive way to enhance decision-making processes within a
                  committee or group setting. This feature empowers committee members to express
                  their opinions, agreement, or disagreement with proposed ideas, actions, or
                  resolutions in a streamlined and user-friendly manner.
                </Typography>
                <ol type='1'>
                  <li>Efficient Feedback Mechanism: The &quot;Thumbs-Up and Thumbs-Down&quot; feature allows committee members to swiftly convey their support or dissent on a given matter without the need for lengthy explanations or discussions.</li>
                  <li>User-Friendly Interface: The interface is intuitive, ensuring that all members can easily participate in the voting process. The option for a simple thumbs-up (approval) or thumbs-down (disapproval) eliminates any potential confusion.</li>
                  <li>Real-time Visibility: As committee members cast their votes, the results are displayed in real-time. This transparency encourages engagement and fosters a sense of involvement in the decision-making process.</li>
                  <li>Data Insights: The feature collects and compiles data on the number of thumbs-up and thumbs-down votes for each item under consideration. This valuable information can help committees assess the level of consensus or disagreement on specific issues.</li>
                </ol>
                <Typography>
                  Incorporating a &quot;Committee Thumbs-Up and Thumbs-Down Feature&quot; into your system is
                  a dynamic and interactive way to enhance decision-making processes within a
                  committee or group setting. This feature empowers committee members to express
                  their opinions, agreement, or disagreement with proposed ideas, actions, or
                  resolutions in a streamlined and user-friendly manner.
                </Typography>
                <ol type='1'>
                  <li>Efficient Feedback Mechanism: The &quot;Thumbs-Up and Thumbs-Down&quot; feature allows committee members to swiftly convey their support or dissent on a given matter without the need for lengthy explanations or discussions.</li>
                  <li>User-Friendly Interface: The interface is intuitive, ensuring that all members can easily participate in the voting process. The option for a simple thumbs-up (approval) or thumbs-down (disapproval) eliminates any potential confusion.</li>
                  <li>Real-time Visibility: As committee members cast their votes, the results are displayed in real-time. This transparency encourages engagement and fosters a sense of involvement in the decision-making process.</li>
                  <li>Data Insights: The feature collects and compiles data on the number of thumbs-up and thumbs-down votes for each item under consideration. This valuable information can help committees assess the level of consensus or disagreement on specific issues.</li>
                </ol>
              </Box>
              <SideSliderFooter>
                <Button size='large' variant='secondary' onClick={() => setIsSliderOpen(false)}>
                  Close
                </Button>
              </SideSliderFooter>
            </SideSlider>

            <Grid container spacing={2.5} style={{ height: 'calc(100vh - 90px)' }}>
            <Grid item style={{ width: 340, ...(window.innerWidth <= 1060 && { width: 'auto' }) }}>
                <Box className='backlog_filters_wrap thin_scrollbar overflow_scroll'>
                  <Box className='inner_header'>
                    <Typography variant='subtitle2'>
                      <strong>Filter</strong>
                    </Typography>
                    <Box className='mt-30'>
                      <Box className='d-flex justify-content-between mb-15'>
                        <Typography variant='subtitle2'>
                          <strong>2 filters active</strong>
                        </Typography>
                        <Button variant='tertiary' icon={<CrossBlueIcon />}>
                          Clear filters
                        </Button>
                      </Box>
                      {chips.map((chipData, index) => (
                        <ChipButton
                          key={index}
                          label={chipData.label}
                          active
                          onDelete={() => handleDelete(chipData.label)}
                          className='mr-10'
                        />
                      ))}
                    </Box>
                  </Box>
                  <Box className='inner_accordian_content'>
                    <Accordion
                      title='Last activity'
                      expandAll={expandAll}
                      setExpandAll={setExpandAll}
                    >
                      <RangeSlider onChange={DayChange} isDay />

                      
                      
                    </Accordion>
                    <Accordion title='Assignee' expandAll={expandAll} setExpandAll={setExpandAll}>
                    <AutocompleteGroup

                        options={reporters}
                        onSelectedValuesChange={handleSelectedValuesChange}
                      ></AutocompleteGroup>
                    </Accordion>
                    <Accordion title='Reporter' expandAll={expandAll} setExpandAll={setExpandAll}>
                    <AutocompleteGroup

                        options={reporters}
                        onSelectedValuesChange={handleSelectedValuesChange}
                      ></AutocompleteGroup>
                    </Accordion>
                    <Accordion title='Task type' expandAll={expandAll} setExpandAll={setExpandAll}>
                    <Checkbox
                            checked={true}
                            onChange={handleCheckboxChange}
                            label='Story'
                            className='mb-8'
                          ></Checkbox>
                          <Checkbox
                            checked={true}
                            onChange={handleCheckboxChange}
                            label='Task'
                            className='mb-8'
                          ></Checkbox>
                    </Accordion>
                    <Accordion
                      title='JIRA status'
                      expandAll={expandAll}
                      setExpandAll={setExpandAll}
                    >
                      <Checkbox
                            checked={true}
                            onChange={handleCheckboxChange}
                            label='Reopened'
                            className='mb-8'
                          ></Checkbox>
                          <Checkbox
                            checked={true}
                            onChange={handleCheckboxChange}
                            label='In review'
                            className='mb-8'
                          ></Checkbox>
                          <Checkbox
                            checked={true}
                            onChange={handleCheckboxChange}
                            label='QA'
                            className='mb-8'
                          ></Checkbox>
                          <Checkbox
                            checked={true}
                            onChange={handleCheckboxChange}
                            label='Rejected'
                            className='mb-8'
                          ></Checkbox>
                    </Accordion>
                    <Accordion
                      title='Estimated hours'
                      expandAll={expandAll}
                      setExpandAll={setExpandAll}
                    >
                      <RangeSlider onChange={HourChange} />
                    </Accordion>
                    <Accordion
                      title='Logged hours'
                      expandAll={expandAll}
                      setExpandAll={setExpandAll}
                    >
                      <RangeSlider onChange={HourChange} />
                    </Accordion>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs>
                <Box className='backlog_task_content_area'>
                  <Box className='box_panel md'>
                    <Typography variant='subtitle2' className='mb-5'>
                      <strong>Add task to prioritize</strong>
                    </Typography>
                    <Typography variant='subtitle1' className='mb-10'>
                      Select tasks from the backlog to set their priority. Any task that you don’t
                      add will remain in the backlog, and you can come back to it in the future.
                    </Typography>

                    <TaskStats
                      statsData={[
                        { num: 742, label: 'Tasks imported' },
                        { num: 0, label: 'Selected' }
                      ]}
                    />

                    <Grid container spacing={2.5}>
                    <Grid item xs={12} lg={5}>
                        <Input
                          placeholder='Search tasks'
                          type='text'
                          value={searchTask}
                          onChange={setSearchTask}
                          leftIcon={<SearchIcon />}
                          className='mb-0'
                        ></Input>
                      </Grid>
                      <Grid item xs={12} lg={7}>
                        <Box className='d-flex align-items-center justify-content-end'>
                          <Checkbox
                            checked={true}
                            onChange={handleCheckboxChange}
                            label='Select all'
                            className='mb-0 mr-20'
                          ></Checkbox>
                          <Box className='d-flex' width={190}>
                            <SelectDropdown
                              size='small'
                              className='mb-0'
                              options={options}
                              label='Sort by'
                              baseIcon={<ArrowDownUpIcon />}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Divider className='divider'></Divider>
                    <Box className='thin_scrollbar overflow_scroll backlog_inner_content'>
                      <Box
                        className='d-flex justify-content-between align-items-center box_panel xs mb-10 cursor-pointer'
                        onClick={handleEditClick}
                      >
                        <Checkbox
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          label='When added on new team'
                          className='mb-0'
                        ></Checkbox>
                        <Box width={220} className='d-flex justify-content-end'>
                          <IconTextCombo title='12h ago' icon={<EstimateTimeIconSmall />} />
                        </Box>
                      </Box>
                      <Box
                        className='d-flex justify-content-between align-items-center box_panel xs mb-10 cursor-pointer'
                        onClick={handleEditClick}
                      >
                        <Checkbox
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          label='When added on new team'
                          className='mb-0'
                        ></Checkbox>
                        <Box width={220} className='d-flex justify-content-end'>
                          <IconTextCombo title='12h ago' icon={<EstimateTimeIconSmall />} />
                        </Box>
                      </Box>
                      <Box
                        className='d-flex justify-content-between align-items-center box_panel xs mb-10 cursor-pointer'
                        onClick={handleEditClick}
                      >
                        <Checkbox
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          label='When added on new team'
                          className='mb-0'
                        ></Checkbox>
                        <Box width={220} className='d-flex justify-content-end'>
                          <IconTextCombo title='12h ago' icon={<EstimateTimeIconSmall />} />
                        </Box>
                      </Box>
                      <Box
                        className='d-flex justify-content-between align-items-center box_panel xs mb-10 cursor-pointer'
                        onClick={handleEditClick}
                      >
                        <Checkbox
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          label='When added on new team'
                          className='mb-0'
                        ></Checkbox>
                        <Box width={220} className='d-flex justify-content-end'>
                          <IconTextCombo title='12h ago' icon={<EstimateTimeIconSmall />} />
                        </Box>
                      </Box>
                      <Box
                        className='d-flex justify-content-between align-items-center box_panel xs mb-10 cursor-pointer'
                        onClick={handleEditClick}
                      >
                        <Checkbox
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          label='When added on new team'
                          className='mb-0'
                        ></Checkbox>
                        <Box width={220} className='d-flex justify-content-end'>
                          <IconTextCombo title='12h ago' icon={<EstimateTimeIconSmall />} />
                        </Box>
                      </Box>
                      <Box
                        className='d-flex justify-content-between align-items-center box_panel xs mb-10 cursor-pointer'
                        onClick={handleEditClick}
                      >
                        <Checkbox
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          label='When added on new team'
                          className='mb-0'
                        ></Checkbox>
                        <Box width={220} className='d-flex justify-content-end'>
                          <IconTextCombo title='12h ago' icon={<EstimateTimeIconSmall />} />
                        </Box>
                      </Box>
                      <Box
                        className='d-flex justify-content-between align-items-center box_panel xs mb-10 cursor-pointer'
                        onClick={handleEditClick}
                      >
                        <Checkbox
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          label='When added on new team'
                          className='mb-0'
                        ></Checkbox>
                        <Box
                          width={220}
                          className='d-flex justify-content-end'
                          onClick={handleEditClick}
                        >
                          <IconTextCombo title='12h ago' icon={<EstimateTimeIconSmall />} />
                        </Box>
                      </Box>
                      <Box
                        className='d-flex justify-content-between align-items-center box_panel xs mb-10 cursor-pointer'
                        onClick={handleEditClick}
                      >
                        <Checkbox
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          label='When added on new team'
                          className='mb-0'
                        ></Checkbox>
                        <Box width={220} className='d-flex justify-content-end'>
                          <IconTextCombo title='12h ago' icon={<EstimateTimeIconSmall />} />
                        </Box>
                      </Box>
                      <Box
                        className='d-flex justify-content-between align-items-center box_panel xs mb-10 cursor-pointer'
                        onClick={handleEditClick}
                      >
                        <Checkbox
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          label='When added on new team'
                          className='mb-0'
                        ></Checkbox>
                        <Box width={220} className='d-flex justify-content-end'>
                          <IconTextCombo title='12h ago' icon={<EstimateTimeIconSmall />} />
                        </Box>
                      </Box>
                      <Box
                        className='d-flex justify-content-between align-items-center box_panel xs mb-10 cursor-pointer'
                        onClick={handleEditClick}
                      >
                        <Checkbox
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          label='When added on new team'
                          className='mb-0'
                        ></Checkbox>
                        <Box width={220} className='d-flex justify-content-end'>
                          <IconTextCombo title='12h ago' icon={<EstimateTimeIconSmall />} />
                        </Box>
                      </Box>
                      <Box
                        className='d-flex justify-content-between align-items-center box_panel xs mb-10 cursor-pointer'
                        onClick={handleEditClick}
                      >
                        <Checkbox
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          label='When added on new team'
                          className='mb-0'
                        ></Checkbox>
                        <Box width={220} className='d-flex justify-content-end'>
                          <IconTextCombo title='12h ago' icon={<EstimateTimeIconSmall />} />
                        </Box>
                      </Box>
                      <Box
                        className='d-flex justify-content-between align-items-center box_panel xs mb-10 cursor-pointer'
                        onClick={handleEditClick}
                      >
                        <Checkbox
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          label='When added on new team'
                          className='mb-0'
                        ></Checkbox>
                        <Box width={220} className='d-flex justify-content-end'>
                          <IconTextCombo title='12h ago' icon={<EstimateTimeIconSmall />} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default UIProfile;
