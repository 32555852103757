import React, { ReactElement } from 'react';
import { GeneralScreenProps } from 'types/general';
import '../../styles/main.scss';
import { Button } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { cleanUserState } from 'redux/slices/userSlice';
import { cleanIssuesState } from 'redux/slices/issuesSlice';

function GuestUserScreen(props: GeneralScreenProps): ReactElement {

  const { logout, isAuthenticated, getAccessTokenSilently } =
    useAuth0();
    const dispatch = useDispatch();

  return (
    <div className='App General-page'>
      <header className='Full-page-centered'>
        {props.imgUrl && (
          <img
            src={props.imgUrl}
            className='General-screen-logo'
            data-testid='General-screen-logo'
          />
        )}
        <p>
          You currently are not a part of any organization.  <br />
          Please ask your organization manager to send you an invite.
        </p>


        <Button
            variant='outlined'
            onClick={() => {
              logout({
                logoutParams: { returnTo: window.location.origin }
              });
              dispatch(cleanUserState());
              dispatch(cleanIssuesState());
            }}
          >
            Logout
          </Button>
      </header>
    </div>
  );
}

export default GuestUserScreen;
