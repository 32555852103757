import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from '../../styles/components/BillingInfo.module.scss';
import HideShow from 'components/HideShow/HideShow';

interface BillingInfoProps {
  heading?: string;
  price?: string;
  totalPrice?: string;
  subscriptionName?: string;
  date?: string;
  prorductInfo?: string;
  salesTax?: string;
}

function BillingInfo({
  heading = 'Your next bill',
  subscriptionName,
  date,
  totalPrice,
  price,
  prorductInfo,
  salesTax = '€0'
}: BillingInfoProps): React.ReactElement {
  return (
    <Box className={styles.billing_info_wrap}>
      <Typography variant='subtitle2' className={styles.main_heading}>
        <strong>{heading}</strong>
      </Typography>

      <Typography variant='h3' className={styles.normal_price}>
        {price}
      </Typography>
      <Typography variant='subtitle1'>on {date}</Typography>
      <Box className={styles.hide_show_wrapper}>
        <HideShow initiallyVisible={true}>
          <Box className={styles.inner_row}>
            <Typography variant='body2' className={styles.subscription_name}>
              {subscriptionName}
            </Typography>

            <Typography variant='body2'>{price}</Typography>
          </Box>
          <Box className={styles.inner_row}>
            <Typography variant='body2'>{prorductInfo}</Typography>
          </Box>
          <Box className={styles.inner_row}>
            <Typography variant='body2'>Sales tax</Typography>
            <Typography variant='body2'>{salesTax}</Typography>
          </Box>
          <Box className={styles.inner_row}>
            <Typography variant='subtitle2'>
              <strong>Total</strong>
            </Typography>
            <Typography variant='subtitle2'>
              <strong>{totalPrice}</strong>
            </Typography>
          </Box>
        </HideShow>
      </Box>
    </Box>
  );
}

export default BillingInfo;
