import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { RootState } from '../redux/store';
import { User } from 'types/user';
import { shallowEqual, useSelector } from 'react-redux';

function RouterGuard(props: { route: ReactElement; path?: string }): ReactElement {
  const { isLoading } = useAuth0();
  const user: User = useSelector((state: RootState) => state.user, shallowEqual);

  if (!isLoading) {
    if (user.organizationId === 'empty-org' && (props?.path != '/guest-user')) {
      return <Navigate to={'/guest-user'} />;
    }
    return user.isAuthenticated ? props.route : <Navigate to={'/login'} />;
  }
  return <></>;
}

const guardedRoute = (component: ReactElement, path?: string): ReactElement => {
  return <RouterGuard route={component} path={path} />;
};

const developmentRoute = (component: ReactElement): ReactElement => {
  return process.env.REACT_APP_ENV === 'development' ? component : <Navigate to={'/'} />;
};

export { guardedRoute, developmentRoute };
