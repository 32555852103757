import React from 'react';
import { Button, Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { ApiClient } from 'services/ApiClient';

export const JiraAuth: React.FC = () => {
  const [searchParams] = useSearchParams();

  const authCode = searchParams.get('code');

  React.useEffect(() => {
    async function exchangeCode(): Promise<void> {
      // /organization/auth/jwt/<userId>
      const result = await ApiClient.post('/organization/auth/jwt/1', {
        code: authCode
      });

      console.log({ result });
    }

    if (authCode) {
      exchangeCode();
    }
  }, [authCode]);

  const onJiraClick = async (): Promise<void> => {
    // /organization/auth/jira/<userId>
    const result = await ApiClient.get<{ redirectUri: string }>('/organization/auth/jira/1');

    window.location.href = result.data.redirectUri;
  };

  return (
    <div>
      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        position='fixed'
        height='100%'
        width='100%'
      >
        <Button variant='contained' onClick={onJiraClick}>
          Jira Auth SSO
        </Button>
      </Box>
    </div>
  );
};
