import { createSlice } from '@reduxjs/toolkit';

export const statuses = createSlice({
  name: 'issueStatuses',
  initialState: [],
  reducers: {
    updateStatuses: (_, action) => action.payload
  }
});

export const { updateStatuses } = statuses.actions;

export default statuses.reducer;
