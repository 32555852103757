import React, { ReactNode } from 'react';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import styles from 'styles/components/AvatarName.module.scss';

interface ImageProps {
  src: string;
  alt?: string;
  name?: string;
  email?: string;
  width?: string;
  className?: string;
  noMargin?: boolean;
  children?: ReactNode;
}

function AvatarName({
  src,
  alt,
  name,
  width,
  noMargin,
  className,
  children,
  email
}: ImageProps): React.ReactElement {
  return (
    <Box
      className={`${styles.avatar_wrapper} ${noMargin ? styles.no_margin : ''} ${
        styles[`${className}`]
      }`}
    >
      <Box width={width}>
        <Avatar className={styles.image} src={src} sx={{ width: width, height: width }} alt={alt}>
          {children}
        </Avatar>
      </Box>

      {name && (
        <Box>
          <Box className={styles.name}>{name}</Box>
          <Box className={styles.email}>{email}</Box>
        </Box>
      )}
    </Box>
  );
}

export default AvatarName;
