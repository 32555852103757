import React, { ReactElement, useState } from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import '../../styles/main.scss';
import IconMenu from 'components/Menu/Menu';
import Grid from '@mui/material/Grid';
import UserTable from 'components/UsersTable/UsersTable';
import { PlusIcon } from 'components/Icons';
import ResponsiveAppBar from 'components/AppBar/ResponsiveAppBar';
import TabNavigation from 'components/TabNavigation/TabNavigation';
import Button from 'components/Button/Button';
import SideSlider, { SideSliderFooter } from 'components/SideSlider/SideSlider';
import Input from 'components/Input/Input';
import AutocompleteGroup from 'components/AutocompleteGroup/AutocompleteGroup';
import { User } from 'types/user';

const user: User = {
  userEmail: '',
  userName: 'Mr robot',
  userPicture:
    'https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png',
  emailVerified: true,
  token: '',
  isAuthenticated: false
};

function UISettings(): ReactElement {
  const [activeTab, setActiveTab] = useState(2);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number): void => {
    setActiveTab(newValue);
  };

  const tabLabels = ['General', 'Profile', 'Users', 'Groups', 'Billing'];

  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const handleCloseSlider = (): void => {
    setIsSliderOpen(false);
  };

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  const options = [{ label: 'Option 1' }, { label: 'Option 2' }, { label: 'Option 3' }];

  const handleSelectedValuesChange = (selectedValues: Option[]): void => {
    // Handle the selected values here
    console.log('Selected Values:', selectedValues);
  };

  type Option = {
    label: string;
  };

  return (
    <div>
      <Box className='wrapper'>
        <ResponsiveAppBar user={user} />
        <Box className='content_area '>
          <Box className='inner_content_area'>
            <Typography variant='h2' className='page_heading'>Settings</Typography>
            <TabNavigation
              labels={tabLabels}
              value={activeTab}
              onChange={handleTabChange}
            ></TabNavigation>

            <SideSlider
              isOpen={isSliderOpen}
              handleCloseSlider={handleCloseSlider}
              title='Invite User'
            >
              <Input label='Name' type='text' value={name} onChange={setName} />
              <Input label='Email' type='email' value={email} onChange={setEmail} />
              <AutocompleteGroup
                options={options}
                label='Add to group'
                onSelectedValuesChange={handleSelectedValuesChange}
              ></AutocompleteGroup>
              <SideSliderFooter>
                <Button size='large' variant='secondary' onClick={() => setIsSliderOpen(false)}>
                  Cancel
                </Button>
                <Button size='large' disabled>
                  Send invite
                </Button>
              </SideSliderFooter>
            </SideSlider>

            <Grid container spacing={2.5} className='mb-30'>
              <Grid item sm={12} md={12} lg={3.5}>
                <Typography variant='h4'>Users</Typography>
                <Typography>
                  Manage existing users, change roles/permissions. or add new team users.
                </Typography>
              </Grid>
              <Grid item sm={12} md={12} lg={8.5}>
                <Grid container spacing={2.5} className='mb-20'>
                  <Grid item xs={6}>
                    <Typography variant='h4' className='mb-0'>
                      Users
                    </Typography>
                  </Grid>
                  <Grid item xs={6} justifyContent={'flex-end'} display={'flex'}>
                    <Button
                      variant='tertiary'
                      size='small'
                      onClick={() => setIsSliderOpen(true)}
                      icon={<PlusIcon />}
                    >
                      Add user
                    </Button>
                  </Grid>
                </Grid>

                <UserTable></UserTable>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default UISettings;
