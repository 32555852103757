import { IssueStatus } from 'types/settings';
import { ApiClient } from '../ApiClient';

export const getStatuses = async (): Promise<IssueStatus[]> => {
  try {
    const statusesResponse = await ApiClient.get<IssueStatus[]>('/setting/statuses');
    return statusesResponse.data;
  } catch (e: unknown) {
    console.error('GetPriorities Error: ', e);
    throw e;
  }
};
