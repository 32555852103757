import { Issue } from 'types/api';

export const mockTasks: Issue[] = [
  {
    id: '1',
    name: 'Integration to catalyst One HR system',
    issueId: '10000',
    key: 'OP-1',
    priorityId: '1',
    description:
      'MVP needs to be designed and developed with room for scalability (to eventually grow into market leading product).',
    dueDate: '2023-09-11T21:34:03.019',
    epicName: 'Uncategorized',
    flagged: false,
    status: {
      id: '10000',
      name: 'In progress',
      iconUrl: 'https://optimyse.atlassian.net/',
      variant: 'warning'
    },
    progress: {
      total: 0,
      progress: 0,
      percent: 0
    },
    timeTracking: {
      originalEstimate: '2h',
      remainingEstimate: '0h'
    },
    reporter: {
      name: 'Owais Afaq',
      profilePic:
        'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/5b6800a82ba0e03be0531914/22692eb9-fea5-44da-b4e3-fa9c204eb25e/48'
    },
    priority: {
      id: '1',
      name: 'P1',
      color: '#F00'
    },
    categories: []
  },
  {
    id: '2',
    name: 'Backend web app (scalable)',
    issueId: '10001',
    key: 'OP-2',
    priorityId: '2',
    description:
      'MVP needs to be designed and developed with room for scalability (to eventually grow into market leading product).',
    dueDate: '2023-09-11T21:34:03.019',
    epicName: 'Uncategorized',
    flagged: false,
    status: {
      id: '10000',
      name: 'Backlog',
      iconUrl: 'https://optimyse.atlassian.net/',
      variant: 'default'
    },
    progress: {
      total: 0,
      progress: 0,
      percent: 85
    },
    timeTracking: {
      originalEstimate: '2h',
      remainingEstimate: '0h'
    },
    reporter: {
      name: 'Owais Afaq',
      profilePic:
        'https://avatar-management--avatars.us-west-2.prod.public.atl-paas.net/5b6800a82ba0e03be0531914/22692eb9-fea5-44da-b4e3-fa9c204eb25e/48'
    },
    priority: {
      id: '2',
      name: 'P2',
      color: '#F90'
    },
    categories: []
  }
];
