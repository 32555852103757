import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from '../../styles/components/Subscription.module.scss';

interface SubscriptionProps {
  heading?: string;
  subscriptionName?: string;
  date?: string;
  noOfSeat?: string;
  children: React.ReactNode;
}

function Subscription({ heading = 'Your subscription', subscriptionName, date, noOfSeat, children }: SubscriptionProps): React.ReactElement {
  return (
    <Box className={styles.subscription_wrap}>
      <Typography variant='subtitle2' className={styles.main_heading}><strong>{heading}</strong></Typography>
      <Box  className={styles.inner_row}>
        <Typography variant='h3'><strong>{subscriptionName}</strong></Typography>
        <Typography variant='h3'><strong>{noOfSeat} seat</strong></Typography>
      </Box>
      <Box className={styles.inner_row}>
        <Typography variant='subtitle1'>Last updated {date}</Typography>
        <Typography variant='subtitle1'>Last updated {date}</Typography>
      </Box>
      <Box className={styles.inner_row}>
        {children}
      </Box>
    </Box>
  );
}

export default Subscription;
