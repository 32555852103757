import React, { ReactElement } from 'react';
import { GeneralScreenProps } from 'types/general';

import '../../styles/main.scss';

function GeneralScreen(props: GeneralScreenProps): ReactElement {
  return (
    <div className='App General-page'>
      <header className='Full-page-centered'>
        {props.imgUrl && (
          <img
            src={props.imgUrl}
            className='General-screen-logo'
            data-testid='General-screen-logo'
          />
        )}
        {props.text && <p data-testid='General-screen-text'> {props.text} </p>}
      </header>
    </div>
  );
}

export default GeneralScreen;
