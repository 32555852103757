import React from 'react';
import { Box } from '@mui/material';

import { LeftLongArrow } from 'components/Icons';
import { FilterItem } from 'types/filters';

import styles from 'styles/components/FilterMenuHeader.module.scss';

interface FilterMenuHeaderProps {
  selectedItem?: FilterItem;
  defaultTitle: string;
  handleBackClick: () => void;
}

export const FilterMenuHeader: React.FC<FilterMenuHeaderProps> = ({
  selectedItem,
  handleBackClick,
  defaultTitle
}) => (
  <Box className={styles.filter_header}>
    {selectedItem && (
      <Box className={styles.filter_back_icon} onClick={handleBackClick}>
        <LeftLongArrow />
      </Box>
    )}

    {selectedItem?.label || defaultTitle}
  </Box>
);
