import * as React from 'react';

import Box from '@mui/material/Box';
import styles from 'styles/components/ProgressBar.module.scss';

interface ProgressBarProps {
  totalHours?: number;
  progressHours?: number;
  profileName?: string;
  variant?: 'quaternary' | 'primary' | 'tertiary' | 'secondary';
  p1?: number;
  p2?: number;
  p3?: number;
  p4?: number;
  p5?: number;
  p1Color?: string;
  p2Color?: string;
  p3Color?: string;
  p4Color?: string;
  p5Color?: string;
  totalTask?: string;
  assignTask?: string;
  totalTaskFuel?: string;
}

function ProgressBar({
  totalHours,
  progressHours,
  variant = 'tertiary',
  profileName,
  p1,
  p2,
  p3,
  p4,
  p5,
  p1Color,
  p2Color,
  p3Color,
  p4Color,
  p5Color,
  totalTask,
  totalTaskFuel,
  assignTask
}: ProgressBarProps): React.ReactElement {
  const calculateProgress = (): number => {
    return (progressHours! / totalHours!) * 100;
  };

  if (variant == 'primary') {
    return (
      <Box className={styles.primary_progressbar}>
        <Box className={styles.user_name}>{profileName}</Box>
        <Box className={styles.inner_wrap}>
          <Box className={styles.fuel} style={{ width: `${calculateProgress()}%` }}></Box>
          <Box className={styles.hours_txt}>{totalHours}hr</Box>
        </Box>
      </Box>
    );
  } else if (variant == 'secondary') {
    return (
      <Box className={styles.secondary_progressbar}>
        <Box className={styles.inner_wrap}>
          <Box className={styles.fuel} style={{ width: `${calculateProgress()}%` }}></Box>
          <Box className={styles.hours_txt}>{totalHours}hr</Box>
        </Box>
      </Box>
    );
  } else if (variant == 'quaternary') {
    return (
      <Box className={styles.quaternary_progressbar}>
        <Box className={styles.inner_wrap}>
          <Box className={styles.fuel}>
            <Box
              className={styles.p1}
              style={{ width: `${p1}%`, backgroundColor: `${p1Color}` }}
            ></Box>
            <Box
              className={styles.p2}
              style={{ width: `${p2}%`, backgroundColor: `${p2Color}` }}
            ></Box>
            <Box
              className={styles.p3}
              style={{ width: `${p3}%`, backgroundColor: `${p3Color}` }}
            ></Box>
            <Box
              className={styles.p4}
              style={{ width: `${p4}%`, backgroundColor: `${p4Color}` }}
            ></Box>
            <Box
              className={styles.p5}
              style={{ width: `${p5}%`, backgroundColor: `${p5Color}` }}
            ></Box>
            <Box className={styles.hours_txt}>{assignTask} tasks</Box>
          </Box>
        </Box>

        <Box className={styles.secondary_progressbar}>
          <Box className={styles.inner_wrap}>
            <Box className={styles.fuel} style={{ width: `${totalTaskFuel}%` }}></Box>
            <Box className={styles.hours_txt}>{totalTask} tasks</Box>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box className={styles.progressbar_wrap}>
      <Box className={styles.progress_bar}>
        <Box className={styles.progress_fill} style={{ width: `${calculateProgress()}%` }}></Box>
      </Box>
      <Box className={styles.progress_info}>
        <Box>Capacity</Box>
        <Box>{`${progressHours}h/${totalHours}`}</Box>
      </Box>
    </Box>
  );
}

export default ProgressBar;
