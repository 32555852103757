import { IssueComment } from 'types/api';
import { UserStatus } from 'types/user';

export const mockComments = (issueId: string): IssueComment[] => [
  {
    id: '1',
    content: 'test comment one',
    isEdited: false,
    isPinned: false,
    userId: '4',
    issueId,
    originalOrder: 1,
    user: {
      id: '4',
      name: 'Test user',
      email: 'testuser@optimyse.com',
      status: UserStatus.ACTIVE,
      roleId: '1',
      organizationId: '1',
      profilePic: '',
      emailVerified: false
    }
  },
  {
    id: '2',
    content: 'test comment two',
    isEdited: false,
    isPinned: false,
    userId: '4',
    issueId,
    originalOrder: 2,
    user: {
      id: '4',
      name: 'Test user',
      email: 'testuser@optimyse.com',
      status: UserStatus.ACTIVE,
      roleId: '1',
      organizationId: '1',
      profilePic: '',
      emailVerified: false
    }
  }
];
