import { HandlerDependencies } from '../types';
import deleteHandler from './deleteHandler';
import editHandler from './editHandler';

const handlers =  [
    {
        title: "Edit",
        handler: editHandler,
        isActive: ({ editMode }: HandlerDependencies) => !editMode,
        dataTestId: "comment-edit-action"
    },
    {
        title: "Delete",
        handler: deleteHandler,
        isActive: ({ editMode }: HandlerDependencies) => !editMode,
        dataTestId: "comment-delete-action"
    },
];

export default handlers;