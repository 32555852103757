import * as React from 'react';

import Box from '@mui/material/Box';
import styles from '../../styles/components/MessageNotification.module.scss';

interface InputProps {
  icon: React.ReactElement;
  title?: string;
  time: string;
  children: React.ReactNode;
  active?: boolean;
}

function MessageNotification({
  icon,
  title,
  children,
  time,
  active = false
}: InputProps): React.ReactElement {
  return (
    <Box className={`${styles.message_notification_wrap} ${active ? styles.active : ''}`}>
      <Box className={styles.inner_icon}>
        <Box>{icon}</Box>
      </Box>
      <Box className={styles.inner_content}>
        <Box className={styles.title}>{title}</Box>
        <Box className={styles.content}>{children}</Box>
        <Box className={styles.time}>{time}</Box>
      </Box>
    </Box>
  );
}

export default MessageNotification;
