import React, { ReactElement, useState } from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import '../../styles/main.scss';
import IconMenu from 'components/Menu/Menu';
import Grid from '@mui/material/Grid';
import ResponsiveAppBar from 'components/AppBar/ResponsiveAppBar';
import TabNavigation from 'components/TabNavigation/TabNavigation';
import Button from 'components/Button/Button';
import SideSlider, { SideSliderFooter } from 'components/SideSlider/SideSlider';
import Input from 'components/Input/Input';
import AvatarName from 'components/AvatarName/AvatarName';
import { PlusIcon } from 'components/Icons';
import AutocompleteGroup from 'components/AutocompleteGroup/AutocompleteGroup';
import { User } from 'types/user';
import UserTable from 'components/UsersTable/UsersTable';
import Checkbox from 'components/Checkbox/Checkbox';

const user: User = {
  userEmail: '',
  userName: 'Mr robot',
  userPicture:
    'https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png',
  emailVerified: true,
  token: '',
  isAuthenticated: false
};

function UIGroups(): ReactElement {
  const [activeTab, setActiveTab] = useState(3);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number): void => {
    setActiveTab(newValue);
  };

  const tabLabels = ['General', 'Profile', 'Users', 'Groups', 'Billing'];

  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const handleCloseSlider = (): void => {
    setIsSliderOpen(false);
  };

  const [isSliderOpen02, setIsSliderOpen02] = useState(false);
  const handleCloseSlider02 = (): void => {
    setIsSliderOpen02(false);
  };

  const [groupName, setGroupName] = useState('Management');
  const [alias, setAlias] = useState('@mgmt');
  const [description, setDescription] = useState(
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
  );

  const handleEditClick = (): void => {
    setIsSliderOpen(true);
  };

  const [isChecked, setIsChecked] = useState(true);

  const handleCheckboxChange = (event: any): void => {
    setIsChecked(event.target.checked);
  };

  return (
    <div>
      <Box className='wrapper'>
        <ResponsiveAppBar user={user} />
        <Box className='content_area '>
          <Box className='inner_content_area'>
            <Typography variant='h2'  className='page_heading'>Settings</Typography>
            <TabNavigation
              labels={tabLabels}
              value={activeTab}
              onChange={handleTabChange}
            ></TabNavigation>
            {/* Create Group Drawer */}
            <SideSlider
              isOpen={isSliderOpen02}
              handleCloseSlider={handleCloseSlider02}
              title='Create Group'
            >
              <Box>
                <Input label='Group Name' type='text' value={groupName} onChange={setGroupName} />
                <Input label='Alias' type='text' value={alias} onChange={setAlias} />
                <Input
                  label='Description'
                  value={description}
                  onChange={setDescription}
                  multiline
                  rows={5}
                  maxRows={15}
                />
                <Typography variant='subtitle1' className='mt--10 flex d-flex justify-content-end'>
                  89/500
                </Typography>
              </Box>
              <SideSliderFooter>
                <Button size='large' variant='secondary' onClick={() => setIsSliderOpen(false)}>
                  Cancel
                </Button>
                <Button size='large' disabled>
                  Send invite
                </Button>
              </SideSliderFooter>
            </SideSlider>

            {/* Set Group Permissions */}
            <SideSlider
              isOpen={isSliderOpen}
              handleCloseSlider={handleCloseSlider}
              title='Set Group Permissions'
            >
              <Box className='mb-30'>
                <Grid container spacing={2.5} className='mb-20'>
                  <Grid item xs={6.6}>
                    <Typography variant='subtitle2'>
                      <strong>Prioritization</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Typography variant='subtitle1'>
                      <strong>View Only</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Typography variant='subtitle1'>
                      <strong>Modify</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Typography variant='subtitle1'>
                      <strong>Full Access</strong>
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2.5} className='mb-5'>
                  <Grid item xs={6.6}>
                    <Typography variant='subtitle2'>Task List</Typography>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      disabled
                    ></Checkbox>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>
                </Grid>

                <Grid container spacing={2.5} className='mb-5'>
                  <Grid item xs={6.6}>
                    <Typography variant='subtitle2'>Comment</Typography>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>
                </Grid>

                <Grid container spacing={2.5} className='mb-5'>
                  <Grid item xs={6.6}>
                    <Typography variant='subtitle2'>Can post</Typography>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>
                </Grid>

                <Grid container spacing={2.5} className='mb-5'>
                  <Grid item xs={6.6}>
                    <Typography variant='subtitle2'>Can view members</Typography>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>
                </Grid>

                <Grid container spacing={2.5} className='mb-5'>
                  <Grid item xs={6.6}>
                    <Typography variant='subtitle2'>Can manage members</Typography>
                  </Grid>
                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>
                </Grid>
              </Box>

              <Box className='mb-20'>
                <Grid container spacing={2.5} className='mb-20'>
                  <Grid item xs={6.6}>
                    <Typography variant='subtitle2'>
                      <strong>Resource Management</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Typography variant='subtitle1'>
                      <strong>View Only</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Typography variant='subtitle1'>
                      <strong>Modify</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Typography variant='subtitle1'>
                      <strong>Full Access</strong>
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container spacing={2.5} className='mb-5'>
                  <Grid item xs={6.6}>
                    <Typography variant='subtitle2'>Contact group owners</Typography>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>
                </Grid>

                <Grid container spacing={2.5} className='mb-5'>
                  <Grid item xs={6.6}>
                    <Typography variant='subtitle2'>Can view conversations</Typography>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>
                </Grid>

                <Grid container spacing={2.5} className='mb-5'>
                  <Grid item xs={6.6}>
                    <Typography variant='subtitle2'>Can post</Typography>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>
                </Grid>

                <Grid container spacing={2.5} className='mb-5'>
                  <Grid item xs={6.6}>
                    <Typography variant='subtitle2'>Can view members</Typography>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>
                </Grid>

                <Grid container spacing={2.5} className='mb-5'>
                  <Grid item xs={6.6}>
                    <Typography variant='subtitle2'>Can manage members</Typography>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>

                  <Grid item xs={1.8} className='text-center'>
                    <Checkbox checked={isChecked} onChange={handleCheckboxChange}></Checkbox>
                  </Grid>
                </Grid>
              </Box>
              <SideSliderFooter>
                <Button size='large' variant='secondary' onClick={() => setIsSliderOpen(false)}>
                  Cancel
                </Button>
                <Button size='large'>Create group</Button>
              </SideSliderFooter>
            </SideSlider>

            <Grid container spacing={2.5} className='mb-30'>
              <Grid item sm={12} md={12} lg={3.5}>
                <Typography variant='h4'>Groups</Typography>
                <Typography>Add or manage groups, add users, create permissions.</Typography>
              </Grid>
              <Grid item sm={12} md={12} lg={8.5}>
                <Grid container spacing={2.5} className='mb-20'>
                  <Grid item xs={6}>
                    <Typography variant='h4' className='mb-0'>
                      Groups
                    </Typography>
                  </Grid>
                  <Grid item xs={6} justifyContent={'flex-end'} display={'flex'}>
                    <Button
                      variant='tertiary'
                      size='small'
                      onClick={() => setIsSliderOpen(true)}
                      icon={<PlusIcon />}
                    >
                      Create group
                    </Button>
                  </Grid>
                </Grid>

                <UserTable></UserTable>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default UIGroups;
