import React from 'react';
import { PrioritizeContext, PrioritizeContextType } from '../provider/PrioritizeProvider';

export default (): PrioritizeContextType => {
  const context = React.useContext(PrioritizeContext);

  if (!context) {
    throw new Error('PrioritizeProvider is missing');
  }

  return context;
};
