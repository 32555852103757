import * as React from 'react';
import Box from '@mui/material/Box';
import styles from '../../styles/components/Loader.module.scss';
import { ReloadIcon } from '../Icons';

interface LoaderProps {
  isLoading: boolean;
  variant: 'spinner' | 'progress';
}

function Loader({ variant = 'spinner', isLoading = false }: LoaderProps): React.ReactElement {
  if (variant === 'progress') {
    return (
      <Box className={`${styles.loading_text_wrap}`}>
        <ReloadIcon />
        <Box className={styles.loading_text}>Loading...</Box>
      </Box>
    );
  }

  return (
    <Box className={styles.loader_wrap}>
      <div className={styles.spinner}>
        <div className={styles.bounce1}></div>
        <div className={styles.bounce2}></div>
        <div className={styles.bounce3}></div>
      </div>
    </Box>
  );
}

export default Loader;
