import React, { useState, ReactNode } from 'react';
import Box from '@mui/material/Box';
import {
  Accordion as MUIAccordion,
  AccordionSummary,
  AccordionDetails,
  Typography
} from '@mui/material';
import Button from 'components/Button/Button';
import { DownArrow } from 'components/Icons';
import styles from '../../styles/components/Accordion.module.scss';

interface AccordionProps {
  title: string;
  children: ReactNode;
  expandAll: boolean;
  setExpandAll: (expand: boolean) => void;
}

function Accordion({
  title,
  children,
  expandAll,
  setExpandAll
}: AccordionProps): React.ReactElement {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleAccordionToggle = (): void => {
    setIsExpanded((prevExpanded) => !prevExpanded);
  };

  return (
    <Box className={styles.accordian_wrapper}>
      <MUIAccordion
        expanded={isExpanded}
        onChange={handleAccordionToggle}
        className={styles.accordian}
      >
        <AccordionSummary expandIcon={<DownArrow />} className={styles.title_wrap}>
          <Typography className={styles.title} variant='subtitle2'>
            {title}
          </Typography>
          {isExpanded && <Button variant='tertiary'>Clear</Button>}
        </AccordionSummary>
        <AccordionDetails className={styles.content_wrap}>{children}</AccordionDetails>
      </MUIAccordion>
    </Box>
  );
}

export default Accordion;
