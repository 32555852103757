import { shallowEqual, useSelector } from 'react-redux';
import { IssueComment } from 'types/api';
import { RootState } from 'redux/store';
import { useDispatch } from 'react-redux';
import {
  addCommentToIssue,
  deleteComment as deleteCommentAction,
  selectCommentsForIssue,
  setCommentsForIssue,
  togglePinComment as togglePinCommentAction,
  updateComment
} from 'redux/slices/issuesSlice';
import * as commentsService from 'services/CommentService';
import { User } from 'types/user';

interface UseCommentsReturnType {
  comments: IssueComment[];
  fetchComments: () => void;
  addComment: (content: string) => void;
  togglePinComment: (comment: IssueComment) => void;
  editComment: (id: string, content: string) => void;
  deleteComment: (comment: IssueComment) => void;
}

const useComments = (issueId: string): UseCommentsReturnType => {
  const dispatch = useDispatch();
  const comments: IssueComment[] | undefined = useSelector(
    (state: RootState) => selectCommentsForIssue(state, issueId),
    shallowEqual
  );
  const user: User = useSelector((state: RootState) => state.user, shallowEqual);

  const fetchComments = async (): Promise<void> => {
    if (comments === undefined) {
      try {
        const issueComments = await commentsService.getComments(issueId);
        if (issueComments) {
          dispatch(
            setCommentsForIssue({
              issueId,
              comments: issueComments
            })
          );
        }
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    }
  };

  const addComment = async (content: string): Promise<void> => {
    try {
      const issueComment = await commentsService.createComment({
        content: content,
        isEdited: false,
        isPinned: false,
        userId:  user.id || '',
        issueId
      });

      if (issueComment) {
        dispatch(addCommentToIssue({ issueId, comment: issueComment }));
      }
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  const togglePinComment = (comment: IssueComment): void => {
    dispatch(
      togglePinCommentAction({
        issueId,
        commentId: comment.id
      })
    );

    commentsService.updateComment(comment.id, {
      isPinned: !comment.isPinned
    });
  };

  const deleteComment = async (comment: IssueComment): Promise<void> => {
    const deletedComment = await commentsService.deleteComment(comment.id);
    if (deletedComment) {
      dispatch(
        deleteCommentAction({
          issueId,
          commentId: comment.id
        })
      );
    }
  };

  const editComment = (id: string, content: string): void => {
    dispatch(
      updateComment({
        issueId,
        commentId: id,
        data: {
          content
        }
      })
    );
    commentsService.updateComment(id, {
      content
    });
  };

  return {
    comments: comments || [],
    fetchComments,
    addComment,
    editComment,
    togglePinComment,
    deleteComment
  };
};

export default useComments;
