import { useNavigate, useSearchParams } from 'react-router-dom';
import React, { ReactElement, useEffect, useState } from 'react';
import { Box, Button as LinkButton, Divider, Typography } from '@mui/material';

import Loader from 'components/Loader/Loader';
import AddIcon from '@mui/icons-material/Add';
import Button from 'components/Button/Button';
import { ArrowBack } from '@mui/icons-material';
import SubscriptionTable from './components/SubscriptionTable';
import { OptimysePlan, PaymentService, Subscription } from 'services/PaymentService';

export default function PaymentScreen(): ReactElement {
  const [loader, setLoader] = useState(true);
  const [subscribeLoader, setSubscribeLoader] = useState(false);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [plans, setPlans] = useState<OptimysePlan[]>([]);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get('token') as string;

  const handleUnAuthorizedError = (error: any): void => {
    const { statusCode, message } = error.response.data;

    if (statusCode === 401 && message === 'Unauthorized') {
      localStorage.removeItem('token');
      navigate('/payment/unauthorized');
    }
  };

  const fetchPlansAndSubscriptions = async (): Promise<void> => {
    try {
      localStorage.setItem('token', token);
      const userSubscriptions = await PaymentService.getUserSubscriptions(token);
      const opPlans = await PaymentService.getPlans(token);
      setSubscriptions(userSubscriptions);
      setPlans(opPlans);
      setLoader(false);
    } catch (error) {
      handleUnAuthorizedError(error);
    }
  };

  useEffect(() => {
    fetchPlansAndSubscriptions();
  }, [token]);

  const createSubscription = async (): Promise<void> => {
    setSubscribeLoader(true);

    try {
      const session = await PaymentService.createSubscriptionCheckoutSession(token);
      window.location.href = session.sessionUrl;
    } catch (error) {
      handleUnAuthorizedError(error);
    } finally {
      setSubscribeLoader(false);
    }
  };

  const renderSubscriptions = (subscriptions_: Subscription[]): ReactElement => {
    return (
      <>
        {subscriptions_.filter((s) => s.status === 'active').length === 0 && (
          <Box my={3}>
            <Box my={3}>
              {plans.map((p) => (
                <>
                  <Typography variant='h3'>{p.name}</Typography>
                  <Box>{p.description}</Box>
                </>
              ))}
            </Box>

            <Button onClick={createSubscription} disabled={subscribeLoader}>
              <AddIcon fontSize='small' /> Subscribe
            </Button>
          </Box>
        )}

        <SubscriptionTable
          subscriptions={subscriptions}
          reloadSubscriptions={fetchPlansAndSubscriptions}
        />
      </>
    );
  };

  if (!token) {
    navigate('/payment/unauthorized');
    return <></>;
  }

  return (
    <Box height='100vh'>
      <Box paddingX='10%' pt='10%'>
        {loader && <Loader isLoading={loader} variant='spinner' />}
        {!loader && (
          <>
            <Typography variant='h2' mt={2}>
              Manage Subscriptions
            </Typography>
            <Typography variant='h4' mt={2}>
              Manage billings for your Optimyse subscriptions
            </Typography>

            {renderSubscriptions(subscriptions)}

            <Divider />
            <Box mt={4}>
              <LinkButton href='optimyse://Home'>
                <ArrowBack />
                &nbsp;Go to Optimyse
              </LinkButton>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
