import React from 'react';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import { createSelector } from '@reduxjs/toolkit';
import IconButton from '@mui/material/IconButton';
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';

import { RowData } from 'types/table';
import { RootState } from 'redux/store';
import { Issue, Priority } from 'types/api';
import StatusChip from 'components/StatusChip/StatusChip';
import useContext from 'screens/prioritize/effects/useContext';
import IconTextCombo from 'components/IconTextCombo/IconTextCombo';
import PriorityDropdown from 'components/PriorityDropdown/PriorityDropdown';
import CircularProgressBar from 'components/CircularProgressBar/CircularProgressBar';
import { ReactComponent as CalendarIcon } from 'assets/images/global-icons/calendar_icon.svg';
import { ReactComponent as TimeIcon } from 'assets/images/global-icons/estimate-time.svg';
import { ReactComponent as MessageIcon } from 'assets/images/global-icons/feedback-icon.svg';

const getPriorities = createSelector(
  [(state: RootState): Priority[] => state.issuesSettings.priorities || []],
  (priorities) => priorities
);

export default (tasks: Issue[], handleCommentIconClick: (task: Issue) => void): RowData[] => {
  const { updatePriority } = useContext();
  const priorities: Priority[] = useSelector(getPriorities);

  const formatDate = (date: string | null): string => {
    if (!date) {
      return '-';
    }

    return format(new Date(date), 'dd.MM.yy');
  };

  return React.useMemo(
    () =>
      tasks.map((task: Issue) => {
        return {
          task_name: task.name,
          priority: (
            <PriorityDropdown
              issueId={task.id}
              selectedValue={task.priorityId}
              priorities={priorities}
              onChange={updatePriority}
            />
          ),
          status: (
            <StatusChip variant={task.status?.variant}>{task.status?.name || '--'}</StatusChip>
          ),
          progress: (
            <CircularProgressBar
              percentage={task.progress.percent}
              title={`${task.progress.percent}%`}
              width='24px'
            />
          ),
          est_date: <IconTextCombo title={formatDate(task.dueDate)} icon={<CalendarIcon />} />,
          est_time: (
            <IconTextCombo title={task.timeTracking?.originalEstimate || '-'} icon={<TimeIcon />} />
          ),
          category: (
            <StatusChip variant='category'>
              {(task?.categories?.length && task.categories[0]) || 'Uncategorized'}
            </StatusChip>
          ),
          comments: (
            <IconButton onClick={() => handleCommentIconClick(task)} sx={{ p: 0 }}>
              <IconTextCombo
                tooltipMessage={task.pinnedComment?.content}
                title={`${task.commentCount || ''}`}
                icon={<MessageIcon />}
              />
            </IconButton>
          ),
          action: (
            <Tooltip title='Open settings'>
              <IconButton sx={{ p: 0 }}>
                <MoreHorizSharpIcon></MoreHorizSharpIcon>
              </IconButton>
            </Tooltip>
          )
        };
      }),
    [tasks, priorities.length]
  );
};
