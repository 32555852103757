import React, { useState } from 'react';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Grid';
import styles from '../../styles/components/RangeSlider.module.scss';

interface RangeSliderProps {
  onChange: (values: [number, number]) => void;
  rangeValue?: [number, number];
  isPercentage?: boolean;
  isDay?: boolean;
}

function RangeSlider({ onChange, isPercentage, rangeValue, isDay }: RangeSliderProps): React.ReactElement {
  let valueSuffix = '';
  let max = 0;
  if (isPercentage) {
    valueSuffix = '%';
    max = 100;
  } else if (isDay) {
    valueSuffix = 'd';
    max = 15;
  } else {
    valueSuffix = 'h';
    max = 24;
  }

  const handleChange = (event: Event, newValue: number | number[]): void => {
    // setRangeValue(newValue as [number, number]);
    onChange(newValue as [number, number]);
  };

  const valueLabelFormat = (value: number): string => {
    return `${value}${valueSuffix}`;
  };

  return (
    <div>
      <Grid container className={styles.rangeslider_wrap} alignItems='center'>
        <Grid item xs>
          <Slider
            value={rangeValue || [0, max]}
            onChange={handleChange}
            valueLabelDisplay='on'
            valueLabelFormat={valueLabelFormat}
            max={max}
            min={0}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default RangeSlider;