import * as React from 'react';

import Box from '@mui/material/Box';
import AvatarName from 'components/AvatarName/AvatarName';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import Slider from 'components/Slider';
import OnboardingCard from 'components/OnboardingCard/OnboardingCard';
import Avatar01 from 'assets/images/global-icons/avatar-01.svg';

import styles from 'styles/components/TaskBoard.module.scss';
import { mockPriorities } from "mock/priorities";


const taskSliderOptions = {
  responsive: [

    {
      breakpoint: 1360,
      settings: {
        slidesToShow: 2,
      }
    },
  ]
};

function TaskBoard(): React.ReactElement {
  return (
    <Slider options={taskSliderOptions}>
      <Box className={styles.job_board_wrap}>
        <AvatarName name='Roman Suzi' src={Avatar01} alt='image'></AvatarName>
        <ProgressBar totalHours={160} progressHours={60} />

        <Box className={styles.onboarding_wrapper}>
          <OnboardingCard
            priority={mockPriorities[0]}
            statustxt='in-progress'
            status='info'
            category='ux upgrade'
            text='Integration to catalyst One HR system'
            progress={85}
            date='03.03.23'
            time='2h'
            avatarUrl={Avatar01}
          />

          <OnboardingCard
            priority={mockPriorities[5]}
            statustxt='in-progress'
            status='warning'
            category='ux upgrade'
            text='Integration to catalyst One HR system'
            progress={35}
            date='03.03.23'
            time='2h'
            avatarUrl={Avatar01}
          />

          <OnboardingCard
            priority={mockPriorities[4]}
            statustxt='in-progress'
            status='danger'
            category='ux upgrade'
            text='Integration to catalyst One HR system'
            progress={25}
            date='03.03.23'
            time='5h'
            avatarUrl={Avatar01}
          />
        </Box>
      </Box>
      <Box className={styles.job_board_wrap}>
        <AvatarName name='Roman Suzi' src={Avatar01} alt='image'></AvatarName>
        <ProgressBar totalHours={160} progressHours={60} />

        <Box className={styles.onboarding_wrapper}>
          <OnboardingCard
            priority={mockPriorities[1]}
            statustxt='in-progress'
            status='warning'
            category='ux upgrade'
            text='Integration to catalyst One HR system'
            progress={85}
            date='03.03.23'
            time='8h'
            avatarUrl={Avatar01}
          />

          <OnboardingCard
            priority={mockPriorities[2]}
            statustxt='in-progress'
            status='danger'
            category='ux upgrade'
            text='Integration to catalyst One HR system'
            progress={35}
            date='03.03.23'
            time='2h'
            avatarUrl={Avatar01}
          />

          <OnboardingCard
            priority={mockPriorities[4]}
            statustxt='in-progress'
            status='info'
            category='ux upgrade'
            text='Integration to catalyst One HR system'
            progress={25}
            date='03.03.23'
            time='5h'
            avatarUrl={Avatar01}
          />
        </Box>
      </Box>
      <Box className={styles.job_board_wrap}>
        <AvatarName name='Roman Suzi' src={Avatar01} alt='image'></AvatarName>
        <ProgressBar totalHours={160} progressHours={60} />

        <Box className={styles.onboarding_wrapper}>
          <OnboardingCard
            priority={mockPriorities[1]}
            statustxt='in-progress'
            status='info'
            category='ux upgrade'
            text='Integration to catalyst One HR system'
            progress={85}
            date='03.03.23'
            time='8h'
            avatarUrl={Avatar01}
          />

          <OnboardingCard
            priority={mockPriorities[2]}
            statustxt='in-progress'
            status='default'
            category='ux upgrade'
            text='Integration to catalyst One HR system'
            progress={35}
            date='03.03.23'
            time='2h'
            avatarUrl={Avatar01}
          />

          <OnboardingCard
            priority={mockPriorities[4]}
            statustxt='in-progress'
            status='info'
            category='ux upgrade'
            text='Integration to catalyst One HR system'
            progress={25}
            date='03.03.23'
            time='5h'
            avatarUrl={Avatar01}
          />
        </Box>
      </Box>
      <Box className={styles.job_board_wrap}>
        <AvatarName name='Roman Suzi' src={Avatar01} alt='image'></AvatarName>
        <ProgressBar totalHours={160} progressHours={60} />

        <Box className={styles.onboarding_wrapper}>
          <OnboardingCard
            priority={mockPriorities[1]}
            status='danger'
            statustxt='in-progress'
            category='ux upgrade'
            text='Integration to catalyst One HR system'
            progress={85}
            date='03.03.23'
            time='8h'
            avatarUrl={Avatar01}
          />

          <OnboardingCard
            priority={mockPriorities[2]}
            status='info'
            statustxt='in-progress'
            category='ux upgrade'
            text='Integration to catalyst One HR system'
            progress={35}
            date='03.03.23'
            time='2h'
            avatarUrl={Avatar01}
          />

          <OnboardingCard
            priority={mockPriorities[4]}
            status='warning'
            statustxt='in-progress'
            category='ux upgrade'
            text='Integration to catalyst One HR system'
            progress={25}
            date='03.03.23'
            time='5h'
            avatarUrl={Avatar01}
          />
        </Box>
      </Box>
    </Slider>
  );
}

export default TaskBoard;
