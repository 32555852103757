import React, { ReactNode } from 'react';
import MuiButton from '@mui/material/Button';
import styles from '../../styles/components/Button.module.scss';

type ButtonSize = 'small' | 'medium' | 'large';
type ButtonVariant = 'primary' | 'secondary' | 'tertiary';
type IconPosition = 'left' | 'right';

interface ButtonProps {
  size?: ButtonSize;
  variant?: ButtonVariant;
  icon?: ReactNode;
  iconPosition?: IconPosition; // New property for icon position
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement> | undefined;
  anchor?: boolean;
  href?: string;
  target?: string;
  rel?: string;
  className?: string;
  children: ReactNode;
  sx?: any;
}

function Button({
  size = 'medium',
  variant = 'primary',
  icon,
  iconPosition = 'left',
  disabled,
  onClick,
  anchor = false,
  href,
  children,
  target,
  rel,
  className,
  sx,
  ...restProps
}: ButtonProps): React.ReactElement {
  if (anchor) {
    return (
      <a
        href={href}
        onClick={onClick}
        className={`${styles[variant]} ${styles[size]} ${className} ${iconPosition === 'right' ? styles.right_icon : ''}`}
        target={target}
        rel={rel}
        {...restProps}
      >
        {iconPosition === 'right' && <span className={styles.btn_icon}>{icon}</span>}
        {children}
        {iconPosition === 'left' && <span className={styles.btn_icon}>{icon}</span>}
      </a>
    );
  }

  return (
    <MuiButton
      variant='contained'
      className={`${styles[variant]} ${styles[size]} ${className} ${iconPosition === 'right' ? styles.right_icon : ''}`}
      startIcon={iconPosition === 'left' ? icon : undefined}
      endIcon={iconPosition === 'right' ? icon : undefined}
      disabled={disabled}
      onClick={onClick}
      {...restProps}
    >
      {children}
    </MuiButton>
  );
}

export default Button;