import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import { EditIcon, TrashIcon } from 'components/Icons';


interface ActionButtonProps {
  type: 'edit' | 'delete';
  onClick?: () => void;
}

function ActionButton({ type, onClick}: ActionButtonProps): React.ReactElement {

  const icon = type === 'edit' ? <EditIcon /> : <TrashIcon />;
  const color = type === 'edit' ? 'primary' : 'secondary';
  return (
    <IconButton color={color} onClick={onClick}>
      {icon}
    </IconButton>
  );
}

export default ActionButton;
