import axios from 'axios';
import persistedStore from '../redux/store';
import { decryptUserToken, encryptUserToken } from './CryptService';

export const ApiClient = axios.create({
  baseURL: process.env.REACT_APP_BE_URL,
  headers: {
    'request-accept': 'application/json',
    'content-type': 'application/json',
    'request-cache-control': 'no-cache'
  }
});

ApiClient.interceptors.request.use(
  (config) => {
    const user = persistedStore.store.getState().user;
    const encryptedToken = encryptUserToken(JSON.stringify(user));

    config.headers['user-token'] = encryptedToken;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
