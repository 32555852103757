import { ApiClient } from '../ApiClient';
import { IssueCategory } from 'types/settings';

export const getCategories = async (organizationId: string): Promise<IssueCategory[]> => {
  try {
    const { data } = await ApiClient.get<IssueCategory[]>(`/setting/categories/${organizationId}`);
    return data;
  } catch (e: unknown) {
    console.error('GetPriorities Error: ', e);
    throw e;
  }
};
