import React from 'react';
import { Menu, MenuItem, IconButton } from '@mui/material';
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';
import { HandlerDependencies } from './types';

interface Handler {
  title: string;
  handler: (dependencies: HandlerDependencies) => void;
  isActive: (dependencies: HandlerDependencies) => boolean;
  dataTestId?: string;
}

interface ActionsProps {
  handlers: Handler[];
  dependencies: HandlerDependencies;
}

const Actions = ({ handlers: actions, dependencies }: ActionsProps): React.ReactElement => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton data-testid='comment-action-btn' onClick={handleMenuClick} sx={{ p: 0 }}>
        <MoreHorizSharpIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        {actions.map((action) => (
          <MenuItem
            data-testid={action.dataTestId}
            key={action.title}
            onClick={() => {
              action.handler(dependencies);
              handleMenuClose();
            }}
            disabled={!action.isActive(dependencies)}
            sx={{ minWidth: '160px' }}
          >
            {action.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default Actions;
