import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import styles from '../../styles/components/Input.module.scss';
import ChipButton from 'components/ChipButton/ChipButton';

interface Option {
  label: string;
  icon?: React.ReactElement;
}

interface AutocompleteProps {
  options: Option[];
  label?: string;
  icon?: React.ReactElement;
  onSelectedValuesChange: (selectedValues: Option[]) => void;
}

const AutocompleteGroup: React.FC<AutocompleteProps> = ({ options, onSelectedValuesChange, label, icon }) => {
  const [selectedValues, setSelectedValues] = useState<Option[]>([]);

  const handleSelectedValuesChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: Option[],
  ): void => {
    setSelectedValues(value);
    onSelectedValuesChange(value);
  };

  return (
    <Box className={styles.input_wrapper}>
      <Autocomplete
        multiple
        id="autocomplete-multi-values"
        options={options}
        getOptionLabel={(option) => option.label}
        value={selectedValues}
        onChange={handleSelectedValuesChange}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            className={styles.input_feild}
            focused
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <ChipButton
              key={index}
              label={option.label}
              chipIcon={option.icon}
              {...getTagProps}
              onDelete={() => {
                const newSelectedValues = [...selectedValues];
                newSelectedValues.splice(index, 1);
                setSelectedValues(newSelectedValues);
                onSelectedValuesChange(newSelectedValues);
              }}
            />
          ))
        }
      />
    </Box>
  );
};

export default AutocompleteGroup;