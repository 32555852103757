import { PrioritizeIssuesFilters } from 'types/api';
import { SelectedFilter, Filters } from 'types/filters';

export const constructFiltersFromSelectedFilters = (
  _selectedFilters: SelectedFilter[]
): PrioritizeIssuesFilters => {
  const filters: PrioritizeIssuesFilters = {};

  // `priority` filter transformation
  filters.priority = _selectedFilters
    .filter((sf) => sf.filter === Filters.PRIORITY)
    .map((p) => parseInt(p.value));

  // `status` filter transformation
  filters.status = _selectedFilters
    .filter((sf) => sf.filter === Filters.STATUS)
    .map((s) => parseInt(s.value));

  // `categories` filter transformation
  filters.category = _selectedFilters
    .filter((sf) => sf.filter === Filters.CATEGORY)
    .map((s) => s.value);

  // `withComments` filter transformation
  const comment = _selectedFilters.find((sf) => sf.filter === Filters.COMMENTS)?.value;
  if (comment) {
    filters.withComments = comment === 'true';
  }

  // `startEstDate` & `endEstDate` date range filter transformation
  const estimatedDateRange = _selectedFilters.find((sf) => sf.filter === Filters.ESTIMATED_DATE);
  if (estimatedDateRange) {
    const [startEstDate, endEstDate] = estimatedDateRange.value.split('^');
    filters.startEstDate = startEstDate;
    filters.endEstDate = endEstDate;
  }

  // `startEstTime` & `endEstTime` time range filter transformation
  const timeRange = _selectedFilters.find((sf) => sf.filter === Filters.ESTIMATED_TIME);
  if (timeRange) {
    const [startEstTime, endEstTime] = timeRange.value.split('-');
    filters.startEstTime = startEstTime.trim();
    filters.endEstTime = endEstTime.trim();
  }

  // `startProgress` & `endProgress` time range filter transformation
  const progressRange = _selectedFilters.find((sf) => sf.filter === Filters.PROGRESS);
  if (progressRange) {
    const [startProgress, endProgress] = progressRange.value.split('-').map((v) => parseInt(v));
    filters.startProgress = startProgress;
    filters.endProgress = endProgress;
  }

  return filters;
};
