import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Work Sans, sans-serif',
    h1: {
      fontSize: '48px',
      letterSpacing: '-0.96px',
      color: '#1A1F39',
      fontWeight: '600',
      marginBottom: '16px'
    },
    h2: {
      fontSize: '32px',
      color: '#1A1F39',
      fontWeight: '600',
      marginBottom: '16px',
      '@media (max-width: 1060px)': {
        fontSize: '28px',
      },
    },
    h3: {
      fontSize: '24px',
      color: '#1A1F39',
      fontWeight: '600',
      '@media (max-width: 1060px)': {
        fontSize: '22px',
      },
    },
    h4: {
      fontSize: '16px',
      color: '#1A1F39',
      fontWeight: '600',
      marginBottom: '10px',
      lineHeight: '1.1',
      '@media (max-width: 1060px)': {
        fontSize: '14px',
      },
      
    },
    subtitle1:{
      fontSize: '14px',
      color: '#6E7B92',
      lineHeight:'1.455'
    },

    subtitle2:{
      fontSize: '16px',
      color: '#1A1F39',
      '@media (max-width: 1060px)': {
        fontSize: '14px',
      },
    },
    body1:{
      fontSize: '16px',
      '@media (max-width: 1060px)': {
        fontSize: '14px',
      },
    },
    body2:{
      fontSize: '14px',
      color: '#1A1F39',
    }
  },
  components: {
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: '#EBEBEB0'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#FFFFFF',
          color: '#1A1F39',
          border: '1px solid #EBEBEB',
          lineHeight: '18px',
          fontWeight: '400',
          padding: '10px 14px',
          fontSize: '13px',
          boxShadow: '0px 4px 25px 0px rgba(18, 25, 96, 0.05), 0px 20px 15px -15px rgba(18, 25, 96, 0.15)',
        },
        arrow: {
          color: '#FFFFFF',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: '6px',
          color: '#EBEBEB',
          '&$checked': {
            color: '#075bd6',
          },
        },
      },
    },
    MuiSlider: { 
      styleOverrides: {
        thumb: {
          backgroundColor: '#075bd6',
        },
        track: {
          backgroundColor: '#075bd6',
        },
      },
    },
    MuiPaper:{
      styleOverrides: {
        root: {
          boxShadow: '0px 4px 25px 0px rgba(18, 25, 96, 0.05), 0px 20px 15px -15px rgba(18, 25, 96, 0.15)',
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom:'15px',
        },
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#6e7b92',
          fontWeight: '600',
        },
      },
    },
  },
  

  
});

export default theme;