import React from 'react';
import { IconButton, Typography } from '@mui/material';
import Avatar01 from 'assets/images/global-icons/avatar-01.svg';
import { ReactComponent as PinIcon } from 'assets/images/global-icons/pin2.svg';
import { ReactComponent as PinSelectedIcon } from 'assets/images/global-icons/pin2-selected.svg';
import styles from 'styles/components/CommentBox.module.scss';

import { IssueComment } from 'types/api';
import CommentField from '../CommentField/CommentField';
import AvatarName from '../AvatarName/AvatarName';
import { Actions, HandlerDependencies } from './Actions';
import handlers from './Actions/handlers';
import { User } from 'types/user';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

interface CommentBoxProps {
  comment: IssueComment;
  onTogglePinComment: (comment: IssueComment) => void;
  onDelete: (comment: IssueComment) => void;
  onEdit: (id: string, content: string) => void;
}

const CommentBox = ({
  comment,
  onTogglePinComment,
  onDelete,
  onEdit
}: CommentBoxProps): React.ReactElement => {
  const [editMode, setEditMode] = React.useState(false);
  const user: User = useSelector((state: RootState) => state.user);

  const handleEditModeToggle = (): void => {
    setEditMode(!editMode);
  };

  const handlerDependencies: HandlerDependencies = {
    comment,
    onDelete,
    editMode,
    setEditMode
  };

  const CommentActions = (): React.ReactElement | null => {
    if (editMode) return null;

    return (
      <>
        <IconButton
          data-testid='comment-pin-toggle'
          onClick={() => onTogglePinComment(comment)}
          sx={{ p: 0 }}
        >
          {comment.isPinned ? <PinSelectedIcon /> : <PinIcon />}
        </IconButton>
        {comment.userId === user.id && (
          <Actions handlers={handlers} dependencies={handlerDependencies} />
        )}
      </>
    );
  };

  return (
    <div className={styles.comment_container}>
      {comment && (
        <>
          <IconButton sx={{ padding: '0', maxHeight: '20px' }}>
            <AvatarName
              noMargin
              name=''
              src={comment.user.profilePic || Avatar01}
              alt='image'
              width='32px'
            />
          </IconButton>
          <div className={styles.comment_content_container}>
            <div className={styles.comment_info}>
              <Typography variant='h4' component='h4'>
                {comment.user.name}
              </Typography>
              <div className={styles.comment_actions}>
                <CommentActions />
              </div>
            </div>
            <div className={styles.comment_body}>
              {editMode ? (
                <CommentField
                  mode='edit'
                  initialValue={comment.content}
                  onSubmit={(content) => onEdit(comment.id, content)}
                  onCancel={handleEditModeToggle}
                  onEditComplete={handleEditModeToggle}
                />
              ) : (
                <Typography
                  data-testid='comment-content'
                  className={styles.comment_text}
                  component='p'
                >
                  {comment.content}
                </Typography>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CommentBox;
