import { ApiClient } from '../ApiClient';
import { Priority, PriorityResponse } from 'types/api';

export const DEFAULT_PRIORITY_COLOR = '#E5E5E5';

const mapPriorityResponse = function (priorities: PriorityResponse[]): Priority[] {
  return priorities.map((priority) => {
    const { id, name, color } = priority;
    return {
      id,
      name,
      color: color ?? DEFAULT_PRIORITY_COLOR
    };
  });
};

export const getPriorities = async (): Promise<Priority[]> => {
  try {
    const prioritiesResponse = await ApiClient.get<PriorityResponse[]>('/setting/priorities');
    return mapPriorityResponse(prioritiesResponse.data);
  } catch (e: unknown) {
    console.error('GetPriorities Error: ', e);
    throw e;
  }
};
