import React, { ReactElement, useState } from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import '../../styles/main.scss';
import IconMenu from 'components/Menu/Menu';
import Grid from '@mui/material/Grid';
import UserTable from 'components/UsersTable/UsersTable';
import { PlusIcon } from 'components/Icons';
import ResponsiveAppBar from 'components/AppBar/ResponsiveAppBar';
import TabNavigation from 'components/TabNavigation/TabNavigation';
import Button from 'components/Button/Button';
import SideSlider, { SideSliderFooter } from 'components/SideSlider/SideSlider';
import Input from 'components/Input/Input';
import AutocompleteGroup from 'components/AutocompleteGroup/AutocompleteGroup';
import Divider from 'components/Divider/Divider';
import Switch from 'components/Switch/Switch';
import Checkbox from 'components/Checkbox/Checkbox';
import { User } from 'types/user';

const user: User = {
  userEmail: '',
  userName: 'Mr robot',
  userPicture:
    'https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png',
  emailVerified: true,
  token: '',
  isAuthenticated: false
};

function UIGeneral(): ReactElement {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number): void => {
    setActiveTab(newValue);
  };

  const tabLabels = ['General', 'Profile', 'Users', 'Groups', 'Billing'];

  const [isChecked, setIsChecked] = useState(true);

  const handleCheckboxChange = (event: any): void => {
    setIsChecked(event.target.checked);
  };

  return (
    <div>
      <Box className='wrapper'>
        <ResponsiveAppBar user={user} />
        <Box className='content_area '>
          <Box className='inner_content_area'>
            <Typography variant='h2'  className='page_heading'>Settings</Typography>
            <TabNavigation
              labels={tabLabels}
              value={activeTab}
              onChange={handleTabChange}
            ></TabNavigation>

            <Grid container spacing={2.5} className='mb-30'>
              <Grid item sm={12} md={12} lg={3.5}>
                <Typography variant='h4'>Email notifications</Typography>
                <Typography>
                  Get emails to find out what’s going on when you’re not online. You can turn these
                  off.
                </Typography>
              </Grid>
              <Grid item sm={12} md={12} lg={8.5}>
                <Switch checked label='Mobile push notifictaions'></Switch>
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  label='Daily productivity update'
                  disabled
                ></Checkbox>

                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  label='New event created'
                ></Checkbox>
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  label='When added on new team'
                ></Checkbox>
              </Grid>
            </Grid>
            <Divider className='mt-40 mb-40' />

            <Grid container spacing={2.5} className='mb-30'>
              <Grid item sm={12} md={12} lg={3.5}>
                <Typography variant='h4'>Mobile push notifications</Typography>
                <Typography>
                  Get emails to find out what’s going on when you’re not online. You can turn these
                  off.
                </Typography>
              </Grid>
              <Grid item sm={12} md={12} lg={8.5}>
                <Switch checked label='Mobile push notifictaions'></Switch>
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  label='Daily productivity update'
                  disabled
                ></Checkbox>

                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  label='New event created'
                ></Checkbox>
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  label='When added on new team'
                ></Checkbox>
              </Grid>
            </Grid>
            <Divider className='mt-40 mb-40' />

            <Grid container spacing={2.5} className='mb-30'>
              <Grid item sm={12} md={12} lg={3.5}>
                <Typography variant='h4'>Desktop notifications</Typography>
                <Typography>
                  Get emails to find out what’s going on when you’re not online. You can turn these
                  off.
                </Typography>
              </Grid>
              <Grid item sm={12} md={12} lg={8.5}>
                <Switch disabled label='Mobile push notifictaions'></Switch>
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  label='Daily productivity update'
                  disabled
                ></Checkbox>

                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  label='New event created'
                ></Checkbox>
                <Checkbox
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                  label='When added on new team'
                ></Checkbox>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default UIGeneral;
