import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

interface ConfirmCancelSubscriptionModalProps {
  open: boolean;
  disabled?: boolean;
  handleClose: () => void;
  handleCancel: () => void;
}

export const ConfirmCancelSubscriptionModal: React.FC<ConfirmCancelSubscriptionModalProps> = ({
  open,
  disabled,
  handleClose,
  handleCancel
}) => {
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Please Confirm'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to cancel the subscription?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={handleCancel} autoFocus disabled={disabled}>
            Yes, Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
