import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import { useState, MouseEvent } from 'react';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';
import AvatarName from 'components/AvatarName/AvatarName';
import PriorityChip from 'components/PriorityChip/PriorityChip';
import IconTextCombo from 'components/IconTextCombo/IconTextCombo';
import StatusChip, { statusVariantType } from 'components/StatusChip/StatusChip';
import CircularProgressBar from 'components/CircularProgressBar/CircularProgressBar';
import { ReactComponent as TimeIcon } from 'assets/images/global-icons/estimate-time.svg';
import { ReactComponent as CalendarIcon } from 'assets/images/global-icons/calendar_icon.svg';
import styles from 'styles/components/OnboardingCard.module.scss';
import { Priority } from 'types/api';

interface OnBoardingProps {
  priority: Priority;
  status: statusVariantType;
  statustxt: string;
  category: string;
  text: string;
  progress: number;
  date: string;
  time: string;
  avatarUrl: string;
}
const settings = ['Go to JIRA', 'Unassign'];
function OnboardingCard({
  priority,
  status,
  statustxt,
  category,
  text,
  progress,
  date,
  time,
  avatarUrl
}: OnBoardingProps): React.ReactElement {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (event: MouseEvent<HTMLElement>): void => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (): void => {
    setAnchorElUser(null);
  };
  return (
    <Box className={styles.onboarding_card_wrapper}>
      <Box className={styles.top_row}>
        <PriorityChip priority={priority} />
        <StatusChip variant={status}>{statustxt}</StatusChip>
        <StatusChip variant="category">{category}</StatusChip>

        <Tooltip title="Open settings">
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, ml: "auto" }}>
            <MoreHorizSharpIcon></MoreHorizSharpIcon>
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: '20px' }}
          id='menu-appbar'
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          className={styles.table_dropdown}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {settings.map((setting) => (
            <MenuItem key={setting} onClick={handleCloseUserMenu}>
              <Typography textAlign='center'>{setting}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <p>{text}</p>

      <Box className={styles.bottom_row}>
        <CircularProgressBar
          percentage={progress}
          title={`${progress}%`}
          width='24px'
        ></CircularProgressBar>
        <IconTextCombo title={date} icon={<CalendarIcon />}></IconTextCombo>
        <IconTextCombo title={time} icon={<TimeIcon />}></IconTextCombo>
        <Box className={styles.user_thumb}>
          <AvatarName src={avatarUrl} width='24px' alt='image'></AvatarName>
        </Box>
      </Box>
    </Box>
  );
}

export default OnboardingCard;
