import React from 'react';
import { Grid, Typography } from '@mui/material';
import { Issue } from 'types/api';
import styles from 'styles/components/CommentTaskInfo.module.scss';

import AvatarName from '../AvatarName/AvatarName';
import PriorityChip from '../PriorityChip/PriorityChip';
import StatusChip from '../StatusChip/StatusChip';

interface CommentTaskInfoProps {
  task: Issue;
}

export default function CommentTaskInfo({ task }: CommentTaskInfoProps): React.ReactElement {
  return (
    <Grid container spacing={1.25}>
      <Grid item xs={2}>
        <Typography variant='body1' className={styles.custom_label}>
          Task name
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Typography variant='body2' className={styles.custom_text}>
          {task.name}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Typography variant='body1' className={styles.custom_label}>
          Reporter
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <Grid container alignItems='center'>
          <AvatarName
            noMargin
            name={task.reporter.name}
            src={task.reporter.profilePic}
            alt='image'
            width='24px'
            className={styles.reporter_name}
          ></AvatarName>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Typography variant='body1' className={styles.custom_label}>
          Priority
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <PriorityChip priority={task.priority} />
      </Grid>
      <Grid item xs={2}>
        <Typography variant='body1' className={styles.custom_label}>
          Status
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <StatusChip variant={task.status?.variant}>{task.status?.name || '--'}</StatusChip>
      </Grid>
    </Grid>
  );
}
