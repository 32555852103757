import React from 'react';

import { Issue, Pagination } from 'types/api';
import { useDrawer } from 'hooks/useDrawer';
import Table from '../Table/Table';
import CommentsEditorPanel from '../CommentEditorPanel/CommentEditorPanel';
import useColumns from './effects/useColumns';
import useRows from './effects/useRows';

interface TaskListProps {
  tasks: Issue[];
  pagination?: Pagination;
  isLoadMoreLoading?: boolean;
  isTableRecordsLoading?: boolean;
  onLoadMoreClick?: () => void;
}

const TaskList = ({
  tasks,
  pagination,
  isLoadMoreLoading,
  isTableRecordsLoading,
  onLoadMoreClick
}: TaskListProps): React.ReactElement => {
  const { openDrawer, closeDrawer, DrawerContent } = useDrawer();

  const handleCommentIconClick = (issue: Issue): void => {
    openDrawer(<CommentsEditorPanel issue={issue} onClose={closeDrawer} />);
  };

  const columns = useColumns();
  const data = useRows(tasks, handleCommentIconClick);

  return (
    <div>
      <Table
        dataTestId='task-list'
        columns={columns}
        data={data}
        pagination={pagination}
        isLoadMoreLoading={isLoadMoreLoading}
        isTableRecordsLoading={isTableRecordsLoading}
        onLoadMoreClick={onLoadMoreClick}
      />
      {DrawerContent}
    </div>
  );
};

export default TaskList;
