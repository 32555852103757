import React, { useEffect, useState } from 'react';
import { RootState } from 'redux/store';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Typography } from '@mui/material';
import Button from 'components/Button/Button';
import { useSelector, useDispatch } from 'react-redux';

import TaskList from 'components/TaskList/TaskList';
import ResponsiveAppBar from 'components/AppBar/ResponsiveAppBar';
import { ReloadIcon } from 'components/Icons';
import { getIssues } from 'services/IssuesService';
import { cleanUserState } from 'redux/slices/userSlice';
import { getStatuses } from 'services/settings/StatusesService';
import { getCategories } from 'services/settings/CategoryService';
import { getPriorities } from 'services/settings/PriorityService';
import { PrioritizeProvider } from './provider/PrioritizeProvider';
import { updateStatuses } from 'redux/slices/issues-settings/statuses';
import { updateCategories } from 'redux/slices/issues-settings/categories';
import { updatePriorities } from 'redux/slices/issues-settings/priorities';
import { PrioritizeFilters } from 'components/PrioritizeFilters/PrioritizeFilters';
import { setLoading, cleanIssuesState, updateIssues } from 'redux/slices/issuesSlice';
import { constructFiltersFromSelectedFilters } from 'components/PrioritizeFilters/PrioritizeFiltersUtils';

export const prioritizedIssuesLimit = 30;
export const defaultPageNo = 1;

const PrioritizeScreen: React.FC = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const {
    isLoading,
    items: issues,
    pagination
  } = useSelector((s: RootState) => s.issues.prioritizedIssues);
  const selectedFilters = useSelector((s: RootState) => s.prioritizeFilters.selectedFilters);
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [pageNo, setPageNo] = useState(defaultPageNo);
  const { logout } = useAuth0();
  const options = [
    {
      name: 'Logout',
      onclick: () => {
        logout({ logoutParams: { returnTo: window.location.origin } });
        dispatch(cleanUserState());
        dispatch(cleanIssuesState());
      }
    }
  ];

  const fetchIssues = async (): Promise<void> => {
    const filters = selectedFilters.length
      ? constructFiltersFromSelectedFilters(selectedFilters)
      : {};
    const jiraIssues = await getIssues(defaultPageNo, prioritizedIssuesLimit, filters);
    dispatch(updateIssues({ items: jiraIssues.issues, pagination: jiraIssues.pagination }));
  };

  const fetchIssueSettings = async (): Promise<void> => {
    const [priorities, statuses, categories] = await Promise.all([
      getPriorities(),
      getStatuses(),
      getCategories(user.organizationId as string)
    ]);
    dispatch(updatePriorities(priorities));
    dispatch(updateStatuses(statuses));
    dispatch(updateCategories(categories));
  };

  useEffect(() => {
    dispatch(setLoading(true));
    fetchIssueSettings();
    fetchIssues();
    dispatch(setLoading(false));
  }, []);

  const loadMoreIssues = async (): Promise<void> => {
    setIsLoadMoreLoading(true);
    setPageNo(pageNo + 1);

    const filters = selectedFilters.length
      ? constructFiltersFromSelectedFilters(selectedFilters)
      : {};
    const response = await getIssues(pageNo + 1, prioritizedIssuesLimit, filters);

    dispatch(
      updateIssues({ pagination: response.pagination, items: [...issues, ...response.issues] })
    );
    setIsLoadMoreLoading(false);
  };

  const handleRefresh = async (): Promise<void> => {
    setRefreshLoading(true);
    setPageNo(defaultPageNo);
    await fetchIssues();
    await fetchIssueSettings();
    setRefreshLoading(false);
  };

  const renderInnerContentHeader = (): React.ReactElement => {
    return (
      <>
        <Typography variant='h2' className='page_heading'>
          Prioritize
        </Typography>
        <Box display='flex' justifyContent='space-between'>
          <Box display='flex'>
            <h4>Tasks</h4>
            <Box ml={1}>{refreshLoading && <ReloadIcon className='loading_icon' />}</Box>
          </Box>
          <Button variant='secondary' onClick={handleRefresh} disabled={refreshLoading}>
            Refresh
          </Button>
        </Box>
      </>
    );
  };

  return (
    <PrioritizeProvider>
      <Box className='wrapper'>
        <ResponsiveAppBar user={user} options={options} />
        <Box className='content_area'>
          <Box className='inner_content_area'>
            {renderInnerContentHeader()}
            <PrioritizeFilters />
            <TaskList
              tasks={issues || []}
              pagination={pagination}
              onLoadMoreClick={loadMoreIssues}
              isLoadMoreLoading={isLoadMoreLoading}
              isTableRecordsLoading={isLoading}
            />
          </Box>
        </Box>
      </Box>
    </PrioritizeProvider>
  );
};

export default PrioritizeScreen;
