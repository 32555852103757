type Order = 'asc' | 'desc';

export const customOrderBy = <T>(arr: T[], keys: (keyof T)[], order: Order[]): T[] => {
    if (!arr) return []
    return [...arr].sort((a: T, b: T) => {
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const sortOrder = order[i];

            if (a[key] < b[key]) {
                return sortOrder === 'asc' ? -1 : 1;
            }

            if (a[key] > b[key]) {
                return sortOrder === 'asc' ? 1 : -1;
            }
        }

        return 0;
    });
};
