import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from 'components/Button/Button';
import { DownArrow, UpArrow } from '../../components/Icons';

import styles from '../../styles/components/HideShow.module.scss';
import Collapse from '@mui/material/Collapse';

interface HideShowProps {
  initiallyVisible?: boolean;
  children: React.ReactNode;
  className?: string;
}

function HideShow({ initiallyVisible = true, children, className }: HideShowProps): React.ReactElement {
  const [isVisible, setIsVisible] = useState(initiallyVisible);

  const toggleVisibility = (): void => {
    setIsVisible(!isVisible);
  };

  return (
      <Box className={`${styles.hide_show_wrap} ${styles[`${className}`]}`}>
      <Button
        className={styles.btn_wrap}
        variant='tertiary'
        size='small'
        onClick={toggleVisibility}
        icon={isVisible ? <UpArrow /> : <DownArrow />}
      >
        {isVisible ? 'Hide Details' : 'Show Details'}
      </Button>
      <Collapse in={isVisible}>
        <div>{children}</div>
      </Collapse>
    </Box>
  );
}

export default HideShow;
