import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import styles from '../../styles/components/TabNavigation.module.scss';
interface TabMenuItemProps {
  labels: string[];
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

function TabNavigation({ labels, value, onChange }: TabMenuItemProps): React.ReactElement {
  return (
    <Tabs value={value} onChange={onChange} className={styles.tab_wrap}>
      {labels.map((label, index) => (
        <Tab key={index} label={label} className={styles.tab_item} />
      ))}
    </Tabs>
  );
}

export default TabNavigation;
