import * as React from 'react';
import Box from '@mui/material/Box';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import styles from '../../styles/components/IconTextCombo.module.scss';

interface InputProps {
  icon: React.ReactElement;
  title?: string;
  tooltipMessage?: string;
}

function IconTextCombo({ icon, title, tooltipMessage }: InputProps): React.ReactElement {
  return (
    <Tooltip
      title={tooltipMessage || ''}
      placement='bottom-end'
      arrow
      className={styles.customTooltip}
    >
      <Box className={styles.icon_text_wrap}>
        <Box className={styles.inner_icon}>
          <ListItemIcon>{icon}</ListItemIcon>
        </Box>
        <Box>{title}</Box>
      </Box>
    </Tooltip>
  );
}

export default IconTextCombo;
