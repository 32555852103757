import React, { ReactElement, useState } from 'react';
import '../../styles/main.scss';
import Typography from '@mui/material/Typography';
import Button from 'components/Button/Button';
import { Box } from '@mui/material';
import { PriorityIcon } from 'components/Icons';
import { PieChart, Pie, Cell } from 'recharts';
import Grid from '@mui/material/Grid';
import Input from 'components/Input/Input';

function UIComponents(): ReactElement {
  const data01 = [
    { name: 'A', value: 25 },
    { name: 'B', value: 75 }
  ];
  const COLORS = ['darkBlue', 'skyBlue'];

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');

  return (
    <div>

      <Box sx={{ width: '900px', margin: 'auto', marginTop: '50px' }}>
      <br></br>
      <br></br>
      <br></br>
      <Grid container spacing={2.5} className='mb-30'>
        <Grid item xs={4}>
        <Input
        label="Name"
        type="text"
        value={name}
        onChange={setName}    
      />
        </Grid>

        <Grid item xs={4}>
        <Input
        label="Email"
        type="email"
        value={email}
        onChange={setEmail}
        color='warning'

      />
        </Grid>

        <Grid item xs={4}>
        <Input
        label="Email"
        type="email"
        value=""
        onChange={setEmail}
        disabled

      />


        </Grid>
      </Grid>


      <Grid container spacing={2.5} className='mb-30'>
        <Grid item xs={4}>
        <Input
        label="Name"
        type="text"
        value={name}
        onChange={setName} 
        color='error'
        error
        message="This is a error message Prop"
      />
        </Grid>

        <Grid item xs={4}>
        <Input
        label="Email"
        type="email"
        value={email}
        onChange={setEmail}
        color='warning'

      />
        </Grid>

        <Grid item xs={4}>
        <Input
        label="Email"
        type="email"
        value={email}
        onChange={setEmail}
        color='info'
        message="This is message Prop"

      />


        </Grid>
      </Grid>
        <Button size='large' icon={<PriorityIcon />}>
          Button Label
        </Button>
        <Button size='medium' icon={<PriorityIcon />}>
          Button Label
        </Button>
        <Button size='small' icon={<PriorityIcon />}>
          Button Label
        </Button>
        <Button size='large' disabled icon={<PriorityIcon />}>
          Button Label
        </Button>
        <hr></hr>

        <Button anchor={true} size='large' icon={<PriorityIcon />}>
          Button Label
        </Button>
        <Button anchor={true} size='medium' icon={<PriorityIcon />}>
          Button Label
        </Button>
        <Button anchor={true} size='small' icon={<PriorityIcon />}>
          Button Label
        </Button>

        <hr></hr>

        <Button variant='secondary' size='large' icon={<PriorityIcon />}>
          Button Label
        </Button>
        <Button variant='secondary' size='medium' icon={<PriorityIcon />}>
          Button Label
        </Button>
        <Button variant='secondary' size='small' icon={<PriorityIcon />}>
          Button Label
        </Button>
        <Button variant='secondary' size='large' disabled icon={<PriorityIcon />}>
          Button Label
        </Button>

        <hr></hr>

        <Button variant='tertiary' size='large' icon={<PriorityIcon />}>
          Button Label
        </Button>
        <Button variant='tertiary' size='medium' icon={<PriorityIcon />}>
          Button Label
        </Button>
        <Button variant='tertiary' size='small' icon={<PriorityIcon />}>
          Button Label
        </Button>
        <Button variant='tertiary' size='large' disabled icon={<PriorityIcon />}>
          Button Label
        </Button>
      </Box>
      <Typography variant='subtitle1' align='center'>
        Some UI components below
      </Typography>
      <hr />
      Simple pie chart . <br />
      More info
      <a href='https://recharts.org/en-US/examples/PieChartWithCustomizedLabel'>here</a>
      <div data-testid='Pie-chart'>
        <PieChart width={400} height={400} data-testid='Pie-chart'>
          <Pie data={data01} dataKey='value' cx='20%' cy='20%' outerRadius={60}>
            {data01.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </div>
    </div>
  );
}

export default UIComponents;
