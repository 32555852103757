import { Box } from '@mui/material';
import React, { ReactElement } from 'react';
import Button from 'components/Button/Button';
import { ArrowBack } from '@mui/icons-material';
import Typography from '@mui/material/Typography';
import { PaymentUnauthorizedIcon } from 'components/Icons';

export default function PaymentUnauthorizedScreen(): ReactElement {
  return (
    <Box height='100vh'>
      <Box
        paddingX='10%'
        pt='20%'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
      >
        <PaymentUnauthorizedIcon />
        <Typography variant='h2' mt={2}>
          Payment Unauthorized
        </Typography>
        <Typography textAlign='center' color='#1A1F39' className=''>
          Uh-oh! It seems like there&apos;s an issue with your payment. We apologize, but it appears
          that you are not authorized to proceed with this payment at the moment. This could be due
          to a variety of reasons, such as insufficient funds, security concerns, or a problem with
          your payment method.
        </Typography>
        <Box mt={3}>
          <Button>
            <ArrowBack />
            &nbsp; Go Back to Optimyse
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
