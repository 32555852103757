import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Filters, SelectedFilter } from 'types/filters';

interface InitialState {
  selectedFilters: SelectedFilter[];
}

interface RemoveFilterPayload {
  filter: Filters;
  value: string;
}

const initialState: InitialState = {
  selectedFilters: []
};

export const prioritizeFiltersSlice = createSlice({
  name: 'prioritizeFilters',
  initialState,
  reducers: {
    setSelectedFilters: (state: InitialState, { payload }: PayloadAction<SelectedFilter[]>) => {
      state.selectedFilters = payload;
    },

    addSelectedFilter(state: InitialState, { payload }: PayloadAction<SelectedFilter>) {
      state.selectedFilters = state.selectedFilters.concat(payload);
    },

    removeSelectedFilter: (state: InitialState, action: PayloadAction<RemoveFilterPayload>) => {
      const { filter, value } = action.payload;
      const newSelectedFilters = state.selectedFilters.filter(
        (f) => !(f.filter === filter && f.value === value)
      );
      state.selectedFilters = newSelectedFilters;
    },

    removeSelectedFilterByValue: (state: InitialState, action: PayloadAction<string>) => {
      const newSelectedFilters = state.selectedFilters.filter((f) => !(f.value === action.payload));
      state.selectedFilters = newSelectedFilters;
    },

    updateOrAddSelectedFilter: (state: InitialState, action: PayloadAction<SelectedFilter>) => {
      const { filter, value, label } = action.payload;
      const exist = state.selectedFilters.find((f) => f.filter === filter);
      if (exist) {
        state.selectedFilters = state.selectedFilters.map((f) =>
          f.filter === filter ? { filter, value, label } : f
        );
      } else {
        state.selectedFilters = state.selectedFilters.concat({ filter, value, label });
      }
    }
  }
});

export const {
  setSelectedFilters,
  addSelectedFilter,
  removeSelectedFilter,
  updateOrAddSelectedFilter,
  removeSelectedFilterByValue
} = prioritizeFiltersSlice.actions;

export default prioritizeFiltersSlice.reducer;
