import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import { combineReducers, configureStore } from '@reduxjs/toolkit';

import userReducer from './slices/userSlice';
import issuesReducer from './slices/issuesSlice';
import usersSlice from './slices/issues-settings/usersSlice';
import prioritizeFilters from './slices/prioritizeFiltersSlice';
import statusesReducer from './slices/issues-settings/statuses';
import categoriesReducer from './slices/issues-settings/categories';
import prioritiesReducer from './slices/issues-settings/priorities';
import backlogIssuesSlice from './slices/backlogIssuesSlice';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['issues', 'settings']
  // ^ Blacklisting to clean jira.issues from persisted state
  // TODO: remove after sometime, we do this to clean persisted state OR swap persisted state variables.
};

const issuesConfig = {
  key: 'issues',
  storage: storage,
  blacklist: ['issues']
};

const rootReducer = combineReducers({
  user: userReducer,
  issues: persistReducer(issuesConfig, issuesReducer),
  prioritizeFilters: prioritizeFilters,
  settings: combineReducers({
    users: usersSlice
  }),
  issuesSettings: combineReducers({
    priorities: prioritiesReducer,
    statuses: statusesReducer,
    categories: categoriesReducer
  }),
  backlogIssues: backlogIssuesSlice
});

const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: [thunk]
});

const persister = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default { store, persister };
