import React from 'react';
import { Box } from '@mui/material';

const Divider = ({ className }: { className?: string }): React.ReactElement => {
  return (
    <Box
      data-testid='divider-box'
      className={className} 
      sx={{
        borderBottom: 'solid 1px #EBEBEB',
        marginTop: '24px',
        marginBottom: '24px'
      }}
    />
  );
};

export default Divider;