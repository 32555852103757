import * as React from 'react';
import Box from '@mui/material/Box';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import styles from 'styles/components/CircularProgressBar.module.scss';

interface InputProps {
  percentage: number;
  width?: string;
  title?: string;
  show?: boolean;
}

function CircularProgressBar({
  percentage = 25,
  width,
  title,
  show = true
}: InputProps): React.ReactElement {
  if (show == false) {
    return <></>;
  }
  return (
    <Box className={styles.progressbar_wrap_main}>
      <div className={styles.progressbar_wrap} style={{ width: width }}>
        <CircularProgressbar
          value={percentage}
          strokeWidth={50}
          styles={buildStyles({
            strokeLinecap: 'butt'
          })}
        />
      </div>
      <span>{title}</span>
    </Box>
  );
}

export default CircularProgressBar;
