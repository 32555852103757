import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IssueComment, Issue, Pagination } from 'types/api';
import { customOrderBy } from 'utils/customOrderBy';

interface IssuesState {
  prioritizedIssues: {
    isLoading: boolean;
    items: Issue[];
    pagination: Pagination;
  };
  commentsByIssueId: Record<string, IssueComment[]>;
}

const initialState: IssuesState = {
  prioritizedIssues: {
    isLoading: false,
    items: [],
    pagination: { count: 0, page: 0, hasMore: false }
  },
  commentsByIssueId: {}
};

const sortCommentsByPinStatus = (comments: IssueComment[]): IssueComment[] => {
  return customOrderBy(comments, ['isPinned', 'originalOrder'], ['desc', 'asc']);
};

const getMinOriginalOrder = (comments: IssueComment[] | undefined): number => {
  if (!comments || comments.length === 0) {
    return 0;
  }

  return Math.min(...comments.map((comment) => comment.originalOrder));
};

export const issuesSlice = createSlice({
  name: 'issues',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.prioritizedIssues.isLoading = action.payload;
    },

    updateIssues: (state, action) => {
      state.prioritizedIssues = {
        isLoading: false,
        items: action.payload.items,
        pagination: action.payload.pagination
      };
      state.commentsByIssueId = {};
    },

    updateIssueById: (state, action: PayloadAction<{ id: string; data: Partial<Issue> }>) => {
      const { id, data } = action.payload;

      state.prioritizedIssues.items = state.prioritizedIssues.items.map((issue: Issue) =>
        issue.id === id ? { ...issue, ...data } : issue
      );
    },

    setCommentsForIssue: (
      state,
      action: PayloadAction<{ issueId: string; comments: IssueComment[] }>
    ) => {
      const { issueId, comments } = action.payload;
      state.commentsByIssueId[issueId] = sortCommentsByPinStatus(comments);
    },

    addCommentToIssue: (
      state,
      action: PayloadAction<{ issueId: string; comment: IssueComment }>
    ) => {
      const { issueId, comment } = action.payload;
      if (!state.commentsByIssueId[issueId]) {
        state.commentsByIssueId[issueId] = [];
      }

      comment.originalOrder = getMinOriginalOrder(state.commentsByIssueId[issueId]);
      state.commentsByIssueId[issueId].unshift(comment);

      const issueInState = state.prioritizedIssues.items.find((i) => i.id === issueId);

      if (issueInState) {
        let count = issueInState.commentCount as number;
        count += 1;
        issueInState.commentCount = count;
      }
    },

    deleteComment: (state, action: PayloadAction<{ issueId: string; commentId: string }>) => {
      const { issueId, commentId } = action.payload;
      const comments = state.commentsByIssueId[issueId];
      if (comments) {
        const existingComment = comments.find((comment) => comment.id === commentId);
        state.commentsByIssueId[issueId] = comments.filter((comment) => comment.id !== commentId);
        const issueInState = state.prioritizedIssues.items.find((i) => i.id === issueId);
        if (issueInState) {
          let count = issueInState.commentCount as number;
          count -= 1;
          issueInState.commentCount = count;

          // check pinned
          if (existingComment?.isPinned) {
            issueInState.pinnedComment = undefined;
          }
        }
      }
    },

    updateComment: (
      state,
      action: PayloadAction<{ issueId: string; commentId: string; data: Partial<IssueComment> }>
    ) => {
      const { issueId, commentId, data } = action.payload;
      const comments = state.commentsByIssueId[issueId];

      if (comments) {
        const foundCommentIndex = comments.findIndex((comment) => comment.id === commentId);
        if (foundCommentIndex !== -1) {
          comments[foundCommentIndex] = { ...comments[foundCommentIndex], ...data };

          const issueInState = state.prioritizedIssues.items.find((i) => i.id === issueId);
          if (issueInState && comments[foundCommentIndex].isPinned) {
            issueInState.pinnedComment = comments[foundCommentIndex];
          }
        }
      }
    },

    togglePinComment: (state, action: PayloadAction<{ issueId: string; commentId: string }>) => {
      const { issueId, commentId } = action.payload;
      const comments = state.commentsByIssueId[issueId];

      if (comments.length) {
        const unpinAllComments = (): void => {
          comments.forEach((comment) => {
            comment.isPinned = false;
          });
        };

        const foundComment = comments.find((comment) => comment.id === commentId);
        if (foundComment) {
          const wasPinned = foundComment.isPinned;
          unpinAllComments();
          foundComment.isPinned = !wasPinned;

          const issueInState = state.prioritizedIssues.items.find((i) => i.id === issueId);
          if (issueInState) {
            issueInState.pinnedComment = foundComment;

            if (wasPinned) {
              issueInState.pinnedComment = undefined;
            }
          }
        }
        state.commentsByIssueId[issueId] = sortCommentsByPinStatus(
          state.commentsByIssueId[issueId]
        );
      }
    },

    cleanIssuesState: () => initialState
  }
});

export const {
  setLoading,
  updateIssues,
  updateIssueById,
  setCommentsForIssue,
  addCommentToIssue,
  deleteComment,
  togglePinComment,
  updateComment,
  cleanIssuesState
} = issuesSlice.actions;

export default issuesSlice.reducer;

// Selectors
export const selectCommentsForIssue = (
  state: { issues: IssuesState },
  issueId: string
): IssueComment[] | undefined => {
  const comments = state.issues.commentsByIssueId[issueId];
  return comments ? sortCommentsByPinStatus(comments) : undefined;
};
