import * as React from 'react';

import styles from 'styles/components/StatusChip.module.scss';

export type statusVariantType = 'danger' | 'success' | 'info' | 'warning' | 'category' | 'default';

interface StatusChipProps {
  variant: statusVariantType;
  children: React.ReactNode;
}

function StatusChip({ variant, children }: StatusChipProps): React.ReactElement {
  return (
    <div className={`${styles.statuschip_wrap} ${styles[`status-${variant}`]}`}>{children}</div>
  );
}

export default StatusChip;
