import * as React from 'react';
import Box from '@mui/material/Box';
import MUIRadio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import styles from '../../styles/components/RadioButton.module.scss';
import {
  RadioUncheckIcon,
  RadioCheckedIcon
} from '../../components/Icons';

interface RadioButtonProps {
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  value: string;
}

function RadioButton({ checked, onChange, label, value }: RadioButtonProps): React.ReactElement {
  const uniqueId = React.useMemo(() => `radio-${Math.random().toString(36).substr(2, 9)}`, []);

  return (
    <Box className={styles.radio_wrap}>
      <FormControlLabel
        control={
          <MUIRadio
            checked={checked}
            onChange={onChange}
            id={uniqueId}
            value={value}
            icon={<RadioUncheckIcon />}
            checkedIcon={<RadioCheckedIcon />}
          />
        }
        label={label}
        htmlFor={uniqueId}
      />
    </Box>
  );
}

export default RadioButton;
