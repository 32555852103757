import { Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import React, { ReactElement, useEffect, useState } from 'react';
import {
  RightArrowLongIcon,
  SearchIcon,
  EstimateTimeIconSmall,
  ArrowDownUpIcon,
  UpLongArrow,
  DownLongArrow
} from 'components/Icons';
import { RootState } from 'redux/store';
import { Issue, Priority } from 'types/api';
import { BacklogFilters } from 'screens/backlog/BacklogFilters';

import Input from 'components/Input/Input';
import Button from 'components/Button/Button';
import Divider from 'components/Divider/Divider';
import Checkbox from 'components/Checkbox/Checkbox';
import TaskStats from 'components/TaskStats/TaskStats';
import ResponsiveAppBar from 'components/AppBar/ResponsiveAppBar';
import IconTextCombo from 'components/IconTextCombo/IconTextCombo';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import { getIssues, updateIssue } from 'services/IssuesService';
import { setBacklogIssues } from 'redux/slices/backlogIssuesSlice';
import { BacklogTaskDetailsDrawer } from './BacklogTaskDetailsDrawer';

import '../../styles/main.scss';

const options = [
  { value: 'ascending', label: 'Ascending', icon: <UpLongArrow /> },
  { value: 'descending', label: 'Descending', icon: <DownLongArrow /> }
];

function BacklogScreen(): ReactElement {
  const [searchTask, setSearchTask] = useState('');
  const [selectedIssue, setSelectedIssue] = useState<Issue>();
  const [isCheckAllChecked, setIsCheckAllChecked] = useState(false);
  const [openIssueDetailsDrawer, setOpenIssueDetailsDrawer] = useState(false);
  const [selectedIssuesIdsMap, setSelectedIssuesIdsMap] = useState<Record<string, boolean>>({});

  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const backlogIssues = useSelector((state: RootState) => state.backlogIssues);
  const priorities = useSelector((state: RootState) => state.issuesSettings.priorities);

  const handleCheckboxChange = (issueId: string) => {
    return (event: React.ChangeEvent<HTMLInputElement>): void => {
      const checked = event.target.checked;
      setSelectedIssuesIdsMap((prevState) => ({ ...prevState, [issueId]: checked }));
    };
  };

  const handleSelectAllCheckbox = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const checked = event.target.checked;
    setIsCheckAllChecked(checked);
    const issueIds = backlogIssues.map((issue) => issue.id);
    const checkedMap = issueIds.reduce((record: Record<string, boolean>, id: string) => {
      record[id] = checked;
      return record;
    }, {});
    setSelectedIssuesIdsMap(checkedMap);
  };

  const showIssueInSlider = (issueId: string) => () => {
    const issue = backlogIssues.find((i) => i.id === issueId);
    if (!issue) return;
    setSelectedIssue(issue);
  };

  const getBacklogIssues = async (): Promise<void> => {
    const jiraIssues = await getIssues(1, 1000, { onlyBacklogs: true });
    dispatch(setBacklogIssues(jiraIssues.issues));
  };

  const getSelectedIssuesCount = (): number => {
    return Object.values(selectedIssuesIdsMap).filter((i) => i).length;
  };

  const handleMoveToPrioritize = async (): Promise<void> => {
    const priority = priorities.find((p: Priority) => p.name === 'P6');
    if (priority) {
      const selectedIssueIds = Object.keys(selectedIssuesIdsMap);
      const issues = backlogIssues.filter((i) => selectedIssueIds.includes(i.id));
      const updateIssuesPromises = issues.map((i) =>
        updateIssue(i.id, { priorityId: priority.id.toString() })
      );
      try {
        await Promise.allSettled(updateIssuesPromises);
        dispatch(setBacklogIssues(backlogIssues.filter((i) => !selectedIssueIds.includes(i.id))));
        setSelectedIssuesIdsMap({});
      } catch (error) {
        console.log('Something went wrong while updating the issues.', error);
      }
    }
  };

  useEffect(() => {
    getBacklogIssues();
  }, []);

  useEffect(() => {
    setOpenIssueDetailsDrawer(true);
  }, [selectedIssue]);

  const disableMoveIssues = Object.values(selectedIssuesIdsMap).some((value) => value === false);

  return (
    <div>
      <Box className='wrapper'>
        <ResponsiveAppBar user={user} />
        <Box className='content_area '>
          <Box className='inner_content_area'>
            <Box className='d-flex justify-content-between align-items-center mb-20'>
              <Typography variant='h2' className='mb-0 page_heading'>
                Backlog
              </Typography>
              <Button
                size='large'
                iconPosition='right'
                disabled={disableMoveIssues}
                icon={<RightArrowLongIcon />}
                onClick={handleMoveToPrioritize}
              >
                Move to prioritize
              </Button>
            </Box>

            <Grid container spacing={2.5} style={{ height: 'calc(100vh - 90px)' }}>
              {/* Filters */}
              <BacklogFilters />

              {selectedIssue && (
                <BacklogTaskDetailsDrawer
                  open={openIssueDetailsDrawer}
                  onClose={() => setOpenIssueDetailsDrawer(false)}
                  issue={selectedIssue}
                />
              )}

              {/* Main Table Conte */}
              <Grid item xs>
                <Box className='backlog_task_content_area'>
                  <Box className='box_panel md'>
                    <Typography variant='subtitle2' className='mb-5'>
                      <strong>Add task to prioritize</strong>
                    </Typography>
                    <Typography variant='subtitle1' className='mb-10'>
                      Select tasks from the backlog to set their priority. Any task that you don’t
                      add will remain in the backlog, and you can come back to it in the future.
                    </Typography>

                    <TaskStats
                      statsData={[
                        { num: backlogIssues.length, label: 'Tasks imported' },
                        { num: getSelectedIssuesCount(), label: 'Selected' }
                      ]}
                    />

                    <Grid container spacing={2.5}>
                      <Grid item xs={12} lg={5}>
                        <Input
                          placeholder='Search tasks'
                          type='text'
                          value={searchTask}
                          onChange={setSearchTask}
                          leftIcon={<SearchIcon />}
                          className='mb-0'
                        />
                      </Grid>
                      <Grid item xs={12} lg={7}>
                        <Box className='d-flex align-items-center justify-content-end'>
                          <Checkbox
                            checked={isCheckAllChecked || false}
                            onChange={handleSelectAllCheckbox}
                            label='Select all'
                            className='mb-0 mr-20'
                          />
                          <Box className='d-flex' width={190}>
                            <SelectDropdown
                              size='small'
                              className='mb-0'
                              options={options}
                              label='Sort by'
                              baseIcon={<ArrowDownUpIcon />}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Divider className='divider'></Divider>
                    <Box className='thin_scrollbar overflow_scroll backlog_inner_content'>
                      {backlogIssues.map((issue) => {
                        return (
                          <Box
                            key={issue.id}
                            className='d-flex  align-items-center box_panel xs mb-10'
                          >
                            <Box className='d-flex'>
                              <Checkbox
                                checked={selectedIssuesIdsMap[issue.id] || false}
                                onChange={handleCheckboxChange(issue.id)}
                                label={''}
                                className='mb-0'
                              />
                            </Box>

                            <Box
                              onClick={showIssueInSlider(issue.id)}
                              sx={{ flex: 1 }}
                              className='cursor-pointer'
                            >
                              {issue.name}
                            </Box>

                            <Box width={220} className='d-flex justify-content-end'>
                              <IconTextCombo title='12h ago' icon={<EstimateTimeIconSmall />} />
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default BacklogScreen;
