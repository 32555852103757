import { ReactElement } from 'react';

export enum Filters {
  SORT_BY = 'sort-by',
  PRIORITY = 'priority',
  PROGRESS = 'progress',
  ESTIMATED_DATE = 'estimated-date',
  ESTIMATED_TIME = 'estimated-time',
  STATUS = 'status',
  COMMENTS = 'comments',
  CATEGORY = 'category'
}

export interface SelectedFilter {
  filter: Filters;
  value: string;
  label: string;
}

export interface CheckboxItem {
  label: string;
  checked: boolean;
  value: number | string;
}

export interface StatusCheckbox {
  label: string;
  checked: boolean;
  value: number;
}

export type NumberedRange = [number, number];

export interface FilterItem {
  id: number;
  label: string;
  icon: ReactElement;
  active: boolean;
  filter: Filters;
}
