import React, { ReactElement, useState } from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import '../../styles/main.scss';
import IconMenu from 'components/Menu/Menu';
import Grid from '@mui/material/Grid';
import ResponsiveAppBar from 'components/AppBar/ResponsiveAppBar';
import TabNavigation from 'components/TabNavigation/TabNavigation';
import Button from 'components/Button/Button';
import SideSlider, { SideSliderFooter } from 'components/SideSlider/SideSlider';
import Input from 'components/Input/Input';
import AvatarName from 'components/AvatarName/AvatarName';
import ActionButton from 'components/ActionButton/ActionButton';
import FileUpload from 'components/FileUpload/FileUpload';
import { User } from 'types/user';

const user:User = {
  userEmail: '',
  userName: 'Mr robot',
  userPicture:
    'https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png',
  emailVerified: true,
  token: '',
  isAuthenticated: false
};

function UIProfile(): ReactElement {
  const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number): void => {
    setActiveTab(newValue);
  };

  const tabLabels = ['General', 'Profile', 'Users', 'Groups', 'Billing'];

  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const handleCloseSlider = (): void => {
    setIsSliderOpen(false);
  };

  const [firstName, setFirstName] = useState('Owais');
  const [lastName, setLastName] = useState('Afaq');
  const [email, setEmail] = useState('owais@optimyse.com');

  const handleEditClick = (): void => {
    setIsSliderOpen(true);
  };


  return (
    <div>
      <Box className='wrapper'>
        <ResponsiveAppBar user={user} />
        <Box className='content_area '>
          <Box className='inner_content_area'>
            <Typography variant='h2'  className='page_heading'>Settings</Typography>
            <TabNavigation
              labels={tabLabels}
              value={activeTab}
              onChange={handleTabChange}
            ></TabNavigation>
            <SideSlider
              isOpen={isSliderOpen}
              handleCloseSlider={handleCloseSlider}
              title='Edit Profile Info'
            >
              <Input label='First name' type='text' value={firstName} onChange={setFirstName}  />
              <Input label='Last name' type='text' value={lastName} onChange={setLastName}  />
              <Input label='Email' type='email' value={email} onChange={setEmail} />
              <Box>
                <FileUpload></FileUpload>
              </Box>
              <SideSliderFooter>
                <Button size='large' variant='secondary' onClick={() => setIsSliderOpen(false)}>Cancel</Button>
                <Button size='large' disabled>Save</Button>
              </SideSliderFooter>
            </SideSlider>

            <Grid container spacing={2.5} className='mb-30'>
              <Grid item sm={12} md={12} lg={3.5}>
                <Typography variant='h4'>Manage Profile</Typography>
                <Typography>
                  Add details to your profile
                </Typography>
              </Grid>
              <Grid item sm={12} md={12} lg={8.5}>
                <Box display='flex' justifyContent='space-between' alignItems='flex-start'>
                  <AvatarName noMargin className='profile_view' alt='Shoaib Ahmed' src="/broken-image.jpg" width='100px' name='Shoaib Ahmed' email='owais@optimyse.com'>SH</AvatarName>
                  <ActionButton type='edit' onClick={handleEditClick}></ActionButton>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default UIProfile;
