import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import React, { ReactElement, useState } from 'react';

import Button from 'components/Button/Button';
import { FilterIcon } from 'components/Icons';
import { FilterMenuItem } from './FilterMenuItem';
import { FilterMenuHeader } from './FilterMenuHeader';
import ChipButton from 'components/ChipButton/ChipButton';
import { FilterItem, Filters } from 'types/filters';

import styles from 'styles/components/FilterDropdown.module.scss';

interface InputProps {
  title: string;
  active?: boolean;
  buttonName: string;
  className?: string;
  isActive?: boolean;
  footer?: ReactElement;
  children: React.ReactNode;
  ButtonIcon?: ReactElement;
  dropdownItems: FilterItem[];
  onFilterDeactivate?: () => void;
  onFilterItemClick: (filterItemClicked: Filters) => void;
}

function FilterDropdown({
  title,
  dropdownItems,
  className,
  footer,
  buttonName,
  ButtonIcon,
  active = false,
  onFilterItemClick,
  children,
  isActive: isActiveProp,
  onFilterDeactivate
}: InputProps): React.ReactElement {
  const [isActive, setIsActive] = useState(false);
  const [menuItems, setMenuItems] = useState(dropdownItems ?? []);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const selectedMenuItem = menuItems.find((item) => item.active);

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
    setIsActive(true);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
    setIsActive(false);
  };

  const setMenuActive = (menuItem: FilterItem): void => {
    setMenuItems(
      menuItems.map((item: FilterItem) => ({
        ...item,
        active: menuItem.id === item.id
      }))
    );
  };

  const handleDeactivateFilter = (): void => {
    setIsActive(false);

    if (onFilterDeactivate) {
      onFilterDeactivate();
    }
  };

  const onClickMenuItem = (menuItem: FilterItem): void => {
    setMenuActive(menuItem);
    onFilterItemClick(menuItem.filter);
  };

  const handleBackClick = (): void => {
    setMenuItems(menuItems.map((item) => ({ ...item, active: false })));
  };

  const filtersButton =
    isActive || isActiveProp ? (
      <Button
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        variant='tertiary'
        onClick={handleClick}
        className='disabled-style'
      >
        <ChipButton
          chipIcon={<FilterIcon />}
          label='Filter'
          onDelete={handleDeactivateFilter}
          active={true}
        />
      </Button>
    ) : (
      <Button
        variant='secondary'
        size='small'
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        icon={ButtonIcon}
        className={`${styles.filter_btn} ${active ? styles.active : ''} `}
        sx={{ position: 'static' }}
      >
        {buttonName}
      </Button>
    );

  return (
    <Box sx={{ position: 'relative' }}>
      {filtersButton}
      <Box>
        <Menu
          id='demo-positioned-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          className={styles.filter_dropdown_wrap}
          transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        >
          {/* Selected menu item's header */}
          <FilterMenuHeader
            selectedItem={selectedMenuItem}
            defaultTitle={title}
            handleBackClick={handleBackClick}
          />

          {!selectedMenuItem &&
            menuItems.map((item: FilterItem, index: number) => {
              return (
                <FilterMenuItem
                  item={item}
                  id={index}
                  onItemClick={onClickMenuItem}
                  key={index}
                  className={className}
                />
              );
            })}

          {/* selected Items child component */}
          {selectedMenuItem && <Box className={styles.filter_sub_menu}>{children}</Box>}

          {/* Footer */}
          {footer && <Box className={styles.filter_footer}>{footer}</Box>}
        </Menu>
      </Box>
    </Box>
  );
}

export default FilterDropdown;
