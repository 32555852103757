import {
  CreateCommentPayload,
  IssueComment,
  IssueCommentResponse,
  UpdateCommentPayload
} from 'types/api';
import { ApiClient } from './ApiClient';
import persistedStore from '../redux/store';

const mapCommentResponse = function (
  comment: IssueCommentResponse,
  originalOrder = 1
): IssueComment {
  return {
    ...comment,
    originalOrder
  };
};

export const getComments = async function (issueId: string): Promise<IssueComment[] | undefined> {
  try {
    const endpoint = `/comment/${issueId}`;
    const res = await ApiClient.get(endpoint);
    const commentsData: IssueCommentResponse[] = res.data;

    return commentsData.map((comment, index) => mapCommentResponse(comment, index + 1));
  } catch (e: unknown) {
    console.error('GetIssueComments Error: ', e);
  }
};

export const createComment = async function (
  payload: CreateCommentPayload
): Promise<IssueComment | undefined> {
  try {
    const res = await ApiClient.post(`/comment`, payload);
    return res.data;
  } catch (e: unknown) {
    console.error('CreateComment Error: ', e);
  }
};

export const updateComment = async function (
  id: string,
  payload: UpdateCommentPayload
): Promise<IssueComment | undefined> {
  const userId = persistedStore.store.getState().user.id;
  try {
    const res = await ApiClient.patch(`/comment/${id}/${userId}`, payload);
    return res.data;
  } catch (e: unknown) {
    console.error('UpdateComment Error: ', e);
  }
};

export const deleteComment = async function (id: string): Promise<IssueComment | undefined> {
  const userId = persistedStore.store.getState().user.id;
  try {
    const res = await ApiClient.delete(`/comment/${id}/${userId}`);
    return res.data;
  } catch (e: unknown) {
    console.error('DeleteComment Error: ', e);
  }
};
