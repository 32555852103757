import { createSlice } from '@reduxjs/toolkit';

export const categories = createSlice({
  name: 'issueCategories',
  initialState: [],
  reducers: {
    updateCategories: (_, action) => action.payload
  }
});

export const { updateCategories } = categories.actions;

export default categories.reducer;
