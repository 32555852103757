import * as React from 'react';
import Menu from '@mui/material/Menu';
import Table from '@mui/material/Table';
import { useState, MouseEvent } from 'react';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import MoreHorizSharpIcon from '@mui/icons-material/MoreHorizSharp';

import { InviteUser, UserStatus } from 'types/user';
import AvatarName from 'components/AvatarName/AvatarName';
import avatarUrl from 'assets/images/global-icons/avatar-01.svg';
import StatusChip, { statusVariantType } from '../StatusChip/StatusChip';

import styles from '../../styles/components/Table.module.scss';

export enum UserActions {
  EDIT_USER = 'edit_user',
  RESEND_INVITE = 'resend_invite',
  ACTIVATE = 'activate',
  DEACTIVATE = 'deactivate'
}

export interface UserSettingAction {
  label: string;
  command: UserActions;
}

const settings: Record<UserStatus, UserSettingAction[]> = {
  ACTIVE: [
    { label: 'Edit user', command: UserActions.EDIT_USER },
    { label: 'Deactivate user', command: UserActions.DEACTIVATE }
  ],
  BLOCKED: [
    { label: 'Edit user', command: UserActions.EDIT_USER },
    { label: 'Activate user', command: UserActions.ACTIVATE },
    { label: 'Deactivate user', command: UserActions.DEACTIVATE }
  ],
  INACTIVE: [
    { label: 'Edit user', command: UserActions.EDIT_USER },
    { label: 'Activate user', command: UserActions.ACTIVATE }
  ],
  INVITED: [
    { label: 'Edit user', command: UserActions.EDIT_USER },
    { label: 'Re-send Invite', command: UserActions.RESEND_INVITE },
    { label: 'Activate user', command: UserActions.ACTIVATE },
    { label: 'Deactivate user', command: UserActions.DEACTIVATE }
  ]
};

const statusColorMap: Record<string, statusVariantType> = {
  ACTIVE: 'warning',
  INACTIVE: 'danger',
  INVITED: 'success',
  BLOCKED: 'danger'
};

export interface UsersTableProps {
  users?: InviteUser[];
  onUserSettingActionClick?: (action: UserSettingAction, selectedUser: InviteUser) => void;
}

const UsersTable: React.FC<UsersTableProps> = ({
  users,
  onUserSettingActionClick
}): React.ReactElement => {
  const [selectedUser, setSelectedUser] = useState<InviteUser>();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const handleOpenUserMenu = (user: InviteUser) => {
    return (event: MouseEvent<HTMLElement>): void => {
      setSelectedUser(user);
      setAnchorElUser(event.currentTarget);
    };
  };

  const handleCloseUserMenu = (): void => {
    setAnchorElUser(null);
  };

  const handleSelectUserActions = (event: React.MouseEvent<HTMLElement>, index: number): void => {
    const actionItem = settings[selectedUser?.status as UserStatus][index];
    if (onUserSettingActionClick) {
      onUserSettingActionClick(actionItem, selectedUser as InviteUser);
    }
    setAnchorElUser(null);
  };

  return (
    <TableContainer className={`${styles.table_wrap} ${styles.user_table_wrap}`}>
      <Menu
        id='menu-appbar'
        keepMounted
        sx={{ mt: '20px' }}
        anchorEl={anchorElUser}
        open={Boolean(anchorElUser)}
        className={styles.table_dropdown}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={handleCloseUserMenu}
      >
        {selectedUser &&
          settings[selectedUser.status].map((setting, index) => {
            return (
              <MenuItem key={index} onClick={(event) => handleSelectUserActions(event, index)}>
                <Typography textAlign='center'>{setting.label}</Typography>
              </MenuItem>
            );
          })}
      </Menu>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Groups</TableCell>
            <TableCell>Status</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users?.map((user, index) => {
            return (
              <TableRow key={user.id}>
                <TableCell>
                  <AvatarName
                    alt='image'
                    width='24px'
                    name={user.name}
                    className='avatar_info'
                    src={user.profilePic || avatarUrl}
                  />
                </TableCell>
                <TableCell>{user.email}</TableCell>
                {/* TODO: we need to add user's group count here. */}
                <TableCell>-</TableCell>
                <TableCell>
                  <StatusChip variant={statusColorMap[user.status]}>
                    {user.status.toLowerCase()}
                  </StatusChip>
                </TableCell>
                <TableCell>
                  <IconButton onClick={handleOpenUserMenu(user)} sx={{ p: 0 }}>
                    <MoreHorizSharpIcon></MoreHorizSharpIcon>
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default UsersTable;
