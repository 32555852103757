import { Box } from '@mui/material';
import React, { ReactElement } from 'react';

import { RightArrowIcon } from 'components/Icons';
import { FilterItem } from 'types/filters';

import styles from 'styles/components/FilterMenuItem.module.scss';

interface FilterMenuItemProps {
  item: FilterItem;
  id: string | number;
  className?: string;
  onItemClick: (item: FilterItem) => void;
}

export const FilterMenuItem: React.FC<FilterMenuItemProps> = ({
  id,
  item,
  className,
  onItemClick
}): ReactElement => (
  <Box
    key={id}
    onClick={() => onItemClick(item)}
    className={`${styles.filter_item} ${styles[`${className}`]}`}
  >
    {item.icon}
    <span>{item.label}</span>
    <RightArrowIcon className={styles.filter_right_arrow} />
  </Box>
);
