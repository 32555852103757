import React, { ReactElement, useState } from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import Slider from 'components/Slider';
import ProgressBar from 'components/ProgressBar/ProgressBar';
import CircularProgressBar from 'components/CircularProgressBar/CircularProgressBar';
import MessageNotification from 'components/MessageNotification/MessageNotification';
import StatusChip from 'components/StatusChip/StatusChip';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import {
  CalendarAddedIcon,
  CalendarMissedIcon,
  EstimateTimeIcon,
  StatusChangeIcon,
  FlagIcon
} from 'components/Icons';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import ResponsiveAppBar from 'components/AppBar/ResponsiveAppBar';
import { User } from 'types/user';

const overviewSliderOptions = {
  infinite: true,
  slidesToShow: 3,
  centerMode: true,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
      }
    },
    {
      breakpoint: 1060,
      settings: {
        slidesToShow: 2,
      }
    },
  ]
};

const weeklySliderOptions = {
  infinite: true,
  variableWidth: false,
  slidesToShow: 3,
  centerMode: false
};

const options = [
  { value: 'weekly', label: 'Weekly',  },
  { value: 'monthly', label: 'Monthly' },
  { value: 'yearly', label: 'Yearly' }
];

const user:User = {
  userEmail: '',
  userName: 'Mr robot',
  userPicture:
    'https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png',
  emailVerified: false,
  token: '',
  isAuthenticated: false
};

function UIDashboard(): ReactElement {
  const [selectedValue] = useState('weekly');
  return (
    <div>
      <Box className='wrapper'>
        <ResponsiveAppBar user={user} />
        <Box className='content_area right_space'>
          <Box className='inner_content_area'>
            <Typography variant='h2' className='page_heading'>Dashboard</Typography>
            <Typography variant='h4'>Backlog overview</Typography>
            <Slider options={overviewSliderOptions} className='overview_slider mb-20'>
              <Box className='box_panel sm mr-10'>
                <StatusChip variant='category'>innovation</StatusChip>
                <ProgressBar
                  variant='quaternary'
                  p1={10}
                  p2={10}
                  p3={20}
                  p4={30}
                  p5={30}
                  p1Color='#FF0000'
                  p2Color='#FF9900'
                  p3Color='#FFFF00'
                  p4Color='#7FFF00'
                  p5Color='#007FFF'
                  totalHours={51}
                  progressHours={40}
                  assignTask='400'
                  totalTask='500'
                  totalTaskFuel='100'
                ></ProgressBar>
              </Box>
              <Box className='box_panel sm mr-10'>
                <StatusChip variant='category'>innovation</StatusChip>
                <ProgressBar
                  variant='quaternary'
                  p1={5}
                  p2={10}
                  p3={10}
                  p4={10}
                  p5={30}
                  p1Color='#FF0000'
                  p2Color='#FF9900'
                  p3Color='#FFFF00'
                  p4Color='#7FFF00'
                  p5Color='#007FFF'
                  progressHours={40}
                  assignTask='400'
                  totalTask='500'
                  totalTaskFuel='50'
                ></ProgressBar>
              </Box>
              <Box className='box_panel sm mr-10'>
                <StatusChip variant='category'>UX upgrade</StatusChip>
                <ProgressBar
                  variant='quaternary'
                  p1={10}
                  p2={5}
                  p3={15.5}
                  p4={5}
                  p5={5}
                  p1Color='#FF0000'
                  p2Color='#FF9900'
                  p3Color='#FFFF00'
                  p4Color='#7FFF00'
                  p5Color='#007FFF'
                  totalHours={51}
                  assignTask='400'
                  totalTask='650'
                  totalTaskFuel='35'
                ></ProgressBar>
              </Box>
              <Box className='box_panel sm mr-10'>
                <StatusChip variant='category'>Customer request</StatusChip>
                <ProgressBar
                  variant='quaternary'
                  p1={5}
                  p2={5}
                  p3={20}
                  p4={10}
                  p5={30}
                  p1Color='#FF0000'
                  p2Color='#FF9900'
                  p3Color='#FFFF00'
                  p4Color='#7FFF00'
                  p5Color='#007FFF'
                  progressHours={40}
                  assignTask='350'
                  totalTask='800'
                  totalTaskFuel='40'
                ></ProgressBar>
              </Box>
              <Box className='box_panel sm mr-10'>
                <StatusChip variant='category'>Stability</StatusChip>
                <ProgressBar
                  variant='quaternary'
                  p1={10}
                  p2={10}
                  p3={10}
                  p4={10}
                  p5={10}
                  p1Color='#FF0000'
                  p2Color='#FF9900'
                  p3Color='#FFFF00'
                  p4Color='#7FFF00'
                  p5Color='#007FFF'
                  progressHours={40}
                  assignTask='10'
                  totalTask='150'
                  totalTaskFuel='40'
                ></ProgressBar>
              </Box>
            </Slider>

            <Grid container spacing={2.5} className='mb-30'>
              <Grid item sm={12} lg={6}>
                <Box className='box_panel lg'>
                  <Typography variant='h4'>Task Allocation</Typography>
                  <Box className='thin_scrollbar allocation_scroll mr--25'>
                    <Box className='mr-25'>
                      <ProgressBar
                        variant='primary'
                        profileName='Brooklyn Simmons'
                        totalHours={900}
                        progressHours={900}
                      />

                      <ProgressBar
                        variant='primary'
                        profileName='Adam Sandler'
                        totalHours={800}
                        progressHours={600}
                      />
                      <ProgressBar
                        variant='primary'
                        profileName='Mark Twain'
                        totalHours={750}
                        progressHours={550}
                      />
                      <ProgressBar
                        variant='primary'
                        profileName='Keen Reeves'
                        totalHours={730}
                        progressHours={450}
                      />
                      <ProgressBar
                        variant='primary'
                        profileName='Tim Cruiser'
                        totalHours={600}
                        progressHours={350}
                      />
                      <ProgressBar
                        variant='primary'
                        profileName='Emeline Watson'
                        totalHours={450}
                        progressHours={300}
                      />
                      <ProgressBar
                        variant='primary'
                        profileName='Taylor Switch'
                        totalHours={375}
                        progressHours={250}
                      />
                      <ProgressBar
                        variant='primary'
                        profileName='Lenny DiCaprio'
                        totalHours={200}
                        progressHours={20}
                      />
                      <ProgressBar
                        variant='primary'
                        profileName='Angeline Jooli'
                        totalHours={100}
                        progressHours={10}
                      />
                      <ProgressBar
                        variant='primary'
                        profileName='Brooklyn Simmons'
                        totalHours={900}
                        progressHours={900}
                      />
                      <ProgressBar
                        variant='primary'
                        profileName='Brooklyn Simmons'
                        totalHours={900}
                        progressHours={900}
                      />
                      <ProgressBar
                        variant='primary'
                        profileName='Brooklyn Simmons'
                        totalHours={900}
                        progressHours={900}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={12} lg={6}>
                <Box className='box_panel lg'>
                  <Typography variant='h4'>Efficiency</Typography>

                  <SelectDropdown options={options} value={selectedValue} />

                  <Slider options={weeklySliderOptions} className='weekly_slider'>
                    <Box className='week_box_wrap'>
                      <Box className='name'>Week 1</Box>
                      <Box className='date'>Jan 08 - Jan 14</Box>
                    </Box>
                    <Box className='week_box_wrap'>
                      <Box className='name'>Week 1</Box>
                      <Box className='date'>Jan 08 - Jan 14</Box>
                    </Box>
                    <Box className='week_box_wrap'>
                      <Box className='name'>Week 1</Box>
                      <Box className='date'>Jan 08 - Jan 14</Box>
                    </Box>
                    <Box className='week_box_wrap'>
                      <Box className='name'>Week 1</Box>
                      <Box className='date'>Jan 08 - Jan 14</Box>
                    </Box>
                  </Slider>

                  <Box className='graph_indicator'>
                    <Box className='inner_wrap'>
                      <span className='indicator'></span>
                      <span>Working Hours</span>
                    </Box>
                    <Box className='inner_wrap'>
                      <span className='indicator'></span>
                      <span>Tasks Completed</span>
                    </Box>
                  </Box>
                  <Box className='thin_scrollbar efficiency_scroll mr--25'>
                    <Box className='mb-20 mr-25'>
                      <Grid container spacing={2.5}>
                        <Grid item sm={6}>
                          <ProgressBar
                            variant='primary'
                            profileName='Brooklyn Simmons'
                            totalHours={900}
                            progressHours={900}
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <Box className='d-flex justify-content-end mt-10'>
                            <CircularProgressBar
                              percentage={20}
                              title='20%'
                              width='24px'
                            ></CircularProgressBar>
                          </Box>
                        </Grid>
                      </Grid>
                      <ProgressBar variant='secondary' totalHours={40} progressHours={40} />
                    </Box>

                    <Box className='mb-20 mr-25'>
                      <Grid container spacing={2.5}>
                        <Grid item sm={6}>
                          <ProgressBar
                            variant='primary'
                            profileName='Brooklyn Simmons'
                            totalHours={900}
                            progressHours={900}
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <Box className='d-flex justify-content-end mt-10'>
                            <CircularProgressBar
                              percentage={20}
                              title='20%'
                              width='24px'
                            ></CircularProgressBar>
                          </Box>
                        </Grid>
                      </Grid>
                      <ProgressBar variant='secondary' totalHours={30} progressHours={25} />
                    </Box>

                    <Box className='mb-20 mr-25'>
                      <Grid container spacing={2.5}>
                        <Grid item sm={6}>
                          <ProgressBar
                            variant='primary'
                            profileName='Brooklyn Simmons'
                            totalHours={500}
                            progressHours={200}
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <Box className='d-flex justify-content-end mt-10'>
                            <CircularProgressBar
                              percentage={20}
                              title='20%'
                              width='24px'
                            ></CircularProgressBar>
                          </Box>
                        </Grid>
                      </Grid>
                      <ProgressBar variant='secondary' totalHours={20} progressHours={10} />
                    </Box>

                    <Box className='mb-20 mr-25'>
                      <Grid container spacing={2.5}>
                        <Grid item sm={6}>
                          <ProgressBar
                            variant='primary'
                            profileName='Brooklyn Simmons'
                            totalHours={900}
                            progressHours={900}
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <Box className='d-flex justify-content-end mt-10'>
                            <CircularProgressBar
                              percentage={20}
                              title='20%'
                              width='24px'
                            ></CircularProgressBar>
                          </Box>
                        </Grid>
                      </Grid>
                      <ProgressBar variant='secondary' totalHours={40} progressHours={40} />
                    </Box>

                    <Box className='mb-20 mr-25'>
                      <Grid container spacing={2.5}>
                        <Grid item sm={6}>
                          <ProgressBar
                            variant='primary'
                            profileName='Brooklyn Simmons'
                            totalHours={900}
                            progressHours={900}
                          />
                        </Grid>
                        <Grid item sm={6}>
                          <Box className='d-flex justify-content-end mt-10'>
                            <CircularProgressBar
                              percentage={20}
                              title='20%'
                              width='24px'
                            ></CircularProgressBar>
                          </Box>
                        </Grid>
                      </Grid>
                      <ProgressBar variant='secondary' totalHours={30} progressHours={25} />
                    </Box>
                  </Box>

                  <Box className='hours_graphbar'>
                    <Box>0h</Box>
                    <Box>10h</Box>
                    <Box>20h</Box>
                    <Box>30h</Box>
                    <Box>40h</Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box className='right_sidebar'>
          <Box className='message_notification_header'>
            <Box className='icon_wrap'>
              <FlagIcon />
              <Typography variant='h4'>Deviations from plan</Typography>
            </Box>
            <Box>
              <Link href='javnascript:;'>Mark all as read</Link>
            </Box>
          </Box>
          <MessageNotification
            active={true}
            title='Due date changed'
            time='5 min ago'
            icon={<CalendarAddedIcon />}
          >
            <strong>Adam Sandler</strong> changed the due date of{' '}
            <Link>Enable users to add GIFs in the chat</Link> from <strong>01.08.2023</strong> to{' '}
            <strong>09.09.2023</strong>
          </MessageNotification>
          <MessageNotification
            active={true}
            title='Estimated date missed'
            time='5 min ago'
            icon={<CalendarMissedIcon />}
          >
            <strong>Brooklyn Simmons</strong> missed the estimated date of{' '}
            <Link>Implement custom roles & permissions to manage HR data access securely </Link>
          </MessageNotification>
          <MessageNotification title='Hours changed' time='5 min ago' icon={<EstimateTimeIcon />}>
            <strong>Mark Twain</strong> changed the Estimated Effort of{' '}
            <Link>Side Menu on the Analytics page should be collapsible</Link> from{' '}
            <strong>4hrs</strong> to <strong>12hrs</strong>
          </MessageNotification>
          <MessageNotification
            title='Task status changed'
            time='5 min ago'
            icon={<StatusChangeIcon />}
          >
            <strong>Taylor Switch</strong> changed the status of{' '}
            <Link>Integrate with HR Connect for seamless employee data synchronization</Link> from{' '}
            <strong>in-progress</strong> to <strong>blocked</strong>
          </MessageNotification>
        </Box>
      </Box>
    </div>
  );
}

export default UIDashboard;
