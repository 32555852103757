export interface User {
  isAuthenticated: boolean;
  userEmail: string;
  userName: string;
  userPicture: string;
  emailVerified: boolean;

  token?: string;
  id?: string;
  status?: UserStatus;
  roleId?: string;
  organizationId?: string;
}

export interface InviteUser {
  id: string;
  name: string;
  email: string;
  emailVerified: boolean;
  profilePic: string;
  status: UserStatus;
  roleId: string;
  organizationId: string;
  resendInvite: boolean;
}

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  INVITED = 'INVITED',
  BLOCKED = 'BLOCKED'
}

export interface UserResponse {
  name: string;
  email: string;
  emailVerified: boolean;
  profilePic: string;

  roleId?: string;
  status?: UserStatus;
  organizationId?: string;
  id?: string;
}
