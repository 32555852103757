import * as React from 'react';
import styles from 'styles/components/PriorityChip.module.scss';
import { Priority } from 'types/api';

interface PriorityChipProps {
  priority: Priority;
}

function PriorityChip({ priority }: PriorityChipProps): React.ReactElement {
  return (
    <div className={`${styles.prioritychip_wrap}`} style={{ borderColor: priority.color }}>
      <span className={styles.circle} style={{ backgroundColor: priority.color }}></span>
      {priority.name}
    </div>
  );
}

export default PriorityChip;
