import React from 'react';
import Box from '@mui/material/Box';
import styles from '../../styles/components/TaskStats.module.scss';

interface TaskStatsProps {
  title?: string;
  statsData: { num: number; label: string }[];
}

function TaskStats({ title, statsData }: TaskStatsProps): React.ReactElement {
  return (
    <Box className={styles.infox_box_wrap}>
      {statsData.map((data, index) => (
        <div key={index} className={styles.inner_info}>
          <div className={styles.num_count}>{data.num}</div>
          <div className={styles.label_txt}>{data.label}</div>
        </div>
      ))}
    </Box>
  );
}

export default TaskStats;