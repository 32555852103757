import React, { ReactElement, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';

import 'styles/main.scss';
import ResponsiveAppBar from 'components/AppBar/ResponsiveAppBar';
import IconMenu from 'components/Menu/Menu';
import TaskBoard from 'components/TaskBoard/TaskBoard';
import TaskList from 'components/TaskList/TaskList';
import { setCommentsForIssue, updateIssues } from 'redux/slices/issuesSlice';
import { updatePriorities } from 'redux/slices/issues-settings/priorities';
import { mockTasks } from 'mock/tasks';
import { mockPriorities } from 'mock/priorities';
import { mockComments } from 'mock/comments';
import { PrioritizeProvider } from '../prioritize/provider/PrioritizeProvider';
import Loader from 'components/Loader/Loader';
import FilterDropdown from 'components/FilterDropdown/FilterDropdown';
import Button from 'components/Button/Button';
import {
  SortIcon,
  PriorityIcon,
  StatusChangeIcon,
  FeedbackIcon,
  ProgressIcon,
  CalendarIcon,
  EstimateTimeIconSmall,
  TrashIcon,
  CategoryIcon,
  FilterIcon,
  PlusIcon,
  FlashIcon
} from 'components/Icons';
import Checkbox from 'components/Checkbox/Checkbox';
import RangeSlider from 'components/RangeSlider/RangeSlider';
import ChipButton from 'components/ChipButton/ChipButton';
import RadioButton from 'components/RadioButton/RadioButton';
import DateRange from 'components/DateRange/DateRange';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import TaskStats from 'components/TaskStats/TaskStats';

const user = {
  userEmail: '',
  userName: 'Mr robot',
  userPicture:
    'https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png',
  emailVerified: false,
  token: '',
  isAuthenticated: false
};

interface ChipData {
  label: string;
  chipIcon: React.ReactElement;
}

const options = [{ value: 'priority', label: 'Priority' }];

function UIScreen(): ReactElement {
  const [selectedValue] = useState('priority');

  const [selectedOption, setSelectedOption] = useState('option1');
  const handleOptionChange = (value: string): void => {
    setSelectedOption(value);
  };

  const [checkboxes, setCheckboxes] = useState([
    { label: 'P1', checked: false },
    { label: 'P2', checked: true },
    { label: 'P3', checked: false },
    { label: 'P4', checked: true },
    { label: 'P5', checked: false },
    { label: 'P6', checked: true }
  ]);

  const [checkboxes02, setCheckboxes02] = useState([
    { label: 'In-progress', checked: false },
    { label: 'On-track', checked: true },
    { label: 'Off-track partially', checked: false },
    { label: 'Off-track significantly', checked: true },
    { label: 'Blocked', checked: false },
    { label: 'Not started', checked: true }
  ]);

  const handleCheckboxChange = (index: any) => (event: any) => {
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[index].checked = event.target.checked;
    setCheckboxes(updatedCheckboxes);
  };

  const handleCheckboxChange02 = (index: any) => (event: any) => {
    const updatedCheckboxes = [...checkboxes02];
    updatedCheckboxes[index].checked = event.target.checked;
    setCheckboxes02(updatedCheckboxes);
  };

  const [percentageRange, setPercentageRange] = useState<[number, number]>([0, 100]);
  const [timeRange, setTimeRange] = useState<[number, number]>([0, 24]);

  // percentage range change
  const PercentageChange = (values: [number, number]): void => {
    setPercentageRange(values);
  };

  // time range change
  const TimeChange = (values: [number, number]): void => {
    setTimeRange(values);
  };

  //Chip Data
  const [chips, setChips] = useState<ChipData[]>([
    { label: 'P1', chipIcon: <PriorityIcon /> },
    { label: 'P2', chipIcon: <PriorityIcon /> },
    { label: 'not started', chipIcon: <StatusChangeIcon /> },
    { label: 'off-track significantly', chipIcon: <StatusChangeIcon /> },
    { label: 'on-track', chipIcon: <StatusChangeIcon /> },
    { label: 'blocked', chipIcon: <StatusChangeIcon /> },
    { label: '0% - 25%', chipIcon: <ProgressIcon /> },
    { label: '02.02.2023 - 10.02.2023', chipIcon: <CalendarIcon /> },
    { label: '2h - 10h', chipIcon: <EstimateTimeIconSmall /> },
    { label: 'innovation', chipIcon: <CategoryIcon /> },
    { label: 'with comments', chipIcon: <FeedbackIcon /> }
  ]);

  const handleDelete = (chipToDelete: string): void => {
    setChips((currentChips) => currentChips.filter((chipData) => chipData.label !== chipToDelete));
  };

  const singleHandleDelete = (): void => {
    // Handle chip deletion logic here
  };

  const filterItems = [
    {
      id: 1,
      label: 'Sort By',
      icon: <SortIcon />,
      subMenu: {
        cmp: () => (
          <>
            <SelectDropdown options={options} value={selectedValue} />
          </>
        )
      },
      active: false
    },
    {
      id: 2,
      label: 'Priority',
      icon: <PriorityIcon />,
      active: false,
      subMenu: {
        cmp: () => (
          <>
            {checkboxes.map((checkbox, index) => (
              <Checkbox
                key={index}
                checked={checkbox.checked}
                onChange={(event) => handleCheckboxChange(index)(event)}
                label={checkbox.label}
              />
            ))}
          </>
        )
      }
    },
    {
      id: 3,
      label: 'Progress',
      icon: <ProgressIcon />,
      active: false,
      subMenu: {
        cmp: () => <RangeSlider onChange={PercentageChange} isPercentage={true} />
      }
    },

    {
      id: 4,
      label: 'Estimated Date',
      icon: <CalendarIcon />,
      active: false,
      subMenu: {
        cmp: () => (
          <DateRange
            placeholder='10.01.2023 - 11.02.2023'
            onChange={(startDate, endDate) => {
              console.log(startDate, endDate);
            }}
          />
        )
      }
    },

    {
      id: 5,
      label: 'Estimated Time',
      icon: <EstimateTimeIconSmall />,
      active: false,
      subMenu: {
        cmp: () => <RangeSlider onChange={TimeChange} isPercentage={false} />
      }
    },

    {
      id: 6,
      label: 'Status',
      icon: <StatusChangeIcon />,
      active: false,
      subMenu: {
        cmp: () => (
          <>
            {checkboxes02.map((checkbox02, index) => (
              <Checkbox
                key={index}
                checked={checkbox02.checked}
                onChange={handleCheckboxChange02(index)}
                label={checkbox02.label}
              />
            ))}
          </>
        )
      }
    },

    {
      id: 7,
      label: 'Comments',
      icon: <FeedbackIcon />,
      active: false,
      subMenu: {
        cmp: () => (
          <>
            <RadioButton
              checked={selectedOption === 'option1'}
              onChange={() => handleOptionChange('option1')}
              label='No Comments'
              value='option1'
            />

            <RadioButton
              checked={selectedOption === 'option2'}
              onChange={() => handleOptionChange('option2')}
              label='With Comments'
              value='option2'
            />
          </>
        )
      }
    }
  ];

  const quickFilter = [
    {
      id: 1,
      label: 'High priority with progress',
      icon: <TrashIcon />
    },

    {
      id: 2,
      label: 'Estimated date first',
      icon: <TrashIcon />
    },

    {
      id: 3,
      label: 'High alert',
      icon: <TrashIcon />
    }
  ];

  const saveAsQuickFilter = [
    {
      id: 1,
      label: 'Progress checker'
    }
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(updateIssues(mockTasks));
    dispatch(updatePriorities(mockPriorities));
    dispatch(
      setCommentsForIssue({
        issueId: mockTasks[0].id,
        comments: mockComments(mockTasks[0].id)
      })
    );
    dispatch(
      setCommentsForIssue({
        issueId: mockTasks[1].id,
        comments: mockComments(mockTasks[1].id)
      })
    );
  }, []);

  return (
    <div>
      <Box className='wrapper'>
        <ResponsiveAppBar user={user} />
        <Box className='content_area'>
          <Box className='inner_content_area'>
            <Typography variant='h2'  className='page_heading'>Resource Planning</Typography>
            <h4>Unassigned Tasks</h4>
            <TaskStats
              statsData={[
                { num: 4, label: 'Total tasks' },
                { num: 18, label: 'Estimated hours' }
              ]}
            />
            <Box className='chipbox_wrap'>
              {chips.map((chipData, index) => (
                <ChipButton
                  key={index}
                  label={chipData.label}
                  onDelete={() => handleDelete(chipData.label)}
                  chipIcon={chipData.chipIcon}
                />
              ))}

              {/* <FilterDropdown
                buttonName='Add filters'
                title='Filters'
                dropdownItem={filterItems}
                ButtonIcon={<PlusIcon className='blue_icon' />}
              ></FilterDropdown>

              <FilterDropdown
                buttonName='Save as Quick Filter'
                title='Save as Quick Filter'
                className='save_as_filter'
                ButtonIcon={<FlashIcon className='blue_icon' />}
                footer={
                  <Box>
                    <Button size='small' variant='secondary' className='min-width-none'>
                      Cancel
                    </Button>
                    <Button size='small' className='min-width-none'>
                      Save
                    </Button>
                  </Box>
                }
                dropdownItem={saveAsQuickFilter}
              ></FilterDropdown>
            </Box>

            <Box sx={{ display: 'flex', marginBottom: '6px' }}>
              <ChipButton
                chipIcon={<FilterIcon />}
                label='Filter'
                onDelete={singleHandleDelete}
                active={true}
              ></ChipButton>

              <FilterDropdown
                buttonName='Filter'
                title='Filters'
                dropdownItem={filterItems}
                ButtonIcon={<FilterIcon />}
                active={true}
              ></FilterDropdown>

              <FilterDropdown
                buttonName='Quick Filters'
                title='Quick Filters'
                dropdownItem={quickFilter}
                ButtonIcon={<FlashIcon />}
                active={true}
                className='quick_filter'
              ></FilterDropdown> */}
            </Box>

            <PrioritizeProvider
              value={{
                updatePriority: (id: string, priorityId: string): void => {
                  //
                }
              }}
            >
              <TaskList tasks={mockTasks} />
            </PrioritizeProvider>
            <Box sx={{ position: 'relative', height: '150px', marginBottom: '25px' }}>
              <Loader variant='spinner' isLoading={true}></Loader>
            </Box>
            <h4>
              Developers <Loader variant='progress' isLoading={true}></Loader>
            </h4>
            <TaskStats
              statsData={[
                { num: 640, label: 'Total hours' },
                { num: 320, label: 'Assigned hours' },
                { num: 320, label: 'Available hours' }
              ]}
            />
            <TaskBoard></TaskBoard>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default UIScreen;
