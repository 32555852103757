import React from 'react';
import styles from 'styles/components/Footer.module.scss';

interface FooterProps {
  onClose: () => void;
}

const Footer: React.FC<FooterProps> = ({ onClose }) => (
  <>
    <button className={styles.cancel_button} onClick={onClose}>
      Cancel
    </button>
  </>
);

export default Footer;
