import { ArrowBack } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Button from 'components/Button/Button';
import { useNavigate } from 'react-router-dom';

export default function PaymentFailureScreen(): ReactElement {
  const navigate = useNavigate();

  const handleGoToOpScreen = (): void => {
    const token = localStorage.getItem('token');
    if (!token) navigate('/payment/unauthorized');

    return navigate(`/payment?token=${token}`);
  };

  return (
    <Box height='100vh'>
      <Box
        paddingX='10%'
        pt='20%'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
      >
        <ReportProblemIcon sx={{ color: '#075bd6', fontSize: '100px' }} />
        <Typography variant='h2' mt={2}>
          Payment Failed
        </Typography>
        <Typography textAlign='center' color='#1A1F39'>
          We apologize for the inconvenience, but it seems that your payment was not successful.
          This could be due to various reasons, such as insufficient funds, an expired card, or a
          temporary issue with our payment processing system.
        </Typography>
        <Box mt={3}>
          <Button onClick={handleGoToOpScreen}>
            <ArrowBack />
            &nbsp; Go Back to Optimyse
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
