import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Issue } from 'types/api';

const initialState: Issue[] = [];

export const backlogIssues = createSlice({
  name: 'backlogIssues',
  initialState,
  reducers: {
    setBacklogIssues: (_, action: PayloadAction<Issue[]>) => action.payload
  }
});

export const { setBacklogIssues } = backlogIssues.actions;

export default backlogIssues.reducer;
