import { createSlice } from '@reduxjs/toolkit';
import { Priority } from 'types/api';

const initialState: Priority[] = [];

export const prioritiesSlice = createSlice({
  name: 'issuePriorities',
  initialState,
  reducers: {
    updatePriorities: (_, action) => action.payload
  }
});

export const { updatePriorities } = prioritiesSlice.actions;

export default prioritiesSlice.reducer;
