import * as React from 'react';
import Divider from '@mui/material/Divider';
import { useLocation, useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import {
  DashboardIcon,
  PrioritizeIcon,
  // DevelopersIcon,
  ResourcePlanningIcon,
  SupportIcon,
  SettingsIcon
} from '../Icons';
import styles from 'styles/components/Menu.module.scss';

interface IconMenuProps {
  className?: string;
}

function IconMenu(props: IconMenuProps): React.ReactElement {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const navigateTo = (route: string) => () => {
    if (pathname !== route) {
      navigate(route);
    }
  };

  return (
    <MenuList className={`${styles.side_menu_wrap} ${props.className}`}>
      <Typography variant='h6'>Menu</Typography>
      <Tooltip title='Dashboard' placement='top' arrow={true}>
        <MenuItem
          className={pathname === '/dashboard' ? styles.active : ''}
          onClick={navigateTo('/dashboard')}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText>Dashboard</ListItemText>
        </MenuItem>
      </Tooltip>

      {/* TODO: = We should be maintaining the path in some constants */}
      <Tooltip title='Prioritize' placement='top' arrow={true}>
        <MenuItem
          className={pathname === '/prioritize' ? styles.active : ''}
          onClick={navigateTo('/prioritize')}
        >
          <ListItemIcon>
            <PrioritizeIcon />
          </ListItemIcon>
          <ListItemText>Prioritize</ListItemText>
        </MenuItem>
      </Tooltip>

      {/* <MenuItem
        className={pathname === '/developers' ? styles.active : ''}
        onClick={navigateTo('/developers')}
      >
        <ListItemIcon>
          <DevelopersIcon />
        </ListItemIcon>
        <ListItemText>Developers</ListItemText>
      </MenuItem> */}
      <Tooltip title='Resource Planning' placement='top' arrow={true}>
        <MenuItem
          className={pathname === '/resource-planning' ? styles.active : ''}
          onClick={navigateTo('/resource-planning')}
        >
          <ListItemIcon>
            <ResourcePlanningIcon />
          </ListItemIcon>
          <ListItemText>Resource Planning</ListItemText>
        </MenuItem>
      </Tooltip>
      <Divider />

      <Typography variant='h6'>General</Typography>
      {/* 
      <MenuItem
        className={pathname === '/support' ? styles.active : ''}
        onClick={navigateTo('/support')}
      >
        <ListItemIcon>
          <SupportIcon />
        </ListItemIcon>
        <ListItemText>Support</ListItemText>
      </MenuItem> */}
      <Tooltip title='Settings' placement='top' arrow={true}>
        <MenuItem
          className={pathname === '/settings' ? styles.active : ''}
          onClick={navigateTo('/settings')}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText>Settings</ListItemText>
        </MenuItem>
      </Tooltip>

      <Tooltip title='Backlog' placement='top' arrow={true}>
        <MenuItem
          className={pathname === '/backlog' ? styles.active : ''}
          onClick={navigateTo('/backlog')}
        >
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText>Backlog</ListItemText>
        </MenuItem>
      </Tooltip>
    </MenuList>
  );
}

export default IconMenu;
