import * as React from 'react';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import { CrossIcon } from 'components/Icons';
import styles from 'styles/components/ChipButton.module.scss';

interface ChipButtonProps {
  label: string;
  active?: boolean;
  chipIcon?: React.ReactElement;
  onDelete: () => void;
  className?: string; // Add className prop
}

const ChipButton: React.FC<ChipButtonProps> = ({ label, active, chipIcon, onDelete, className, ...props }) => (
  <Chip
    label={label}
    onDelete={onDelete}
    deleteIcon={<CrossIcon />}
    avatar={chipIcon ? <IconButton size='small'>{chipIcon}</IconButton> : undefined}
    className={`${styles.chip_btn_wrap} ${active ? styles.active : ''} ${className || ''}`} // Include className prop
    {...props}
  />
);

export default ChipButton;