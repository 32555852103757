import React from "react";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import styles from "styles/components/SideSlider.module.scss";

interface SideSliderProps {
  isOpen: boolean;
  handleCloseSlider: () => void;
  children: React.ReactNode;
  title: string;
}

interface DrawerFooterProps {
  children: React.ReactNode;
  className?: string;
}

export const SideSliderFooter: React.FC<DrawerFooterProps> = ({
  children,
  className,
}) => {
  return (
    <div className={`${styles.slider_footer} ${className}`}>{children}</div>
  );
};

const SideSlider: React.FC<SideSliderProps> = ({
  isOpen,
  handleCloseSlider,
  children,
  title,
}) => {
  return (
    <Drawer anchor="right" hideBackdrop open={isOpen} transitionDuration={{ enter: 800, exit: 1000 }}>
      <div className={styles.slider_container}>
        <div className={styles.slider_header}>
          <Typography variant="h3" component="h3">
            {title}
          </Typography>
          <IconButton onClick={handleCloseSlider}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={styles.slider_body}>{children}</div>
      </div>
    </Drawer>
  );
};

export default SideSlider;
