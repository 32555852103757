import { Box, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ArrowBack } from '@mui/icons-material';
import Button from 'components/Button/Button';
import { useNavigate } from 'react-router-dom';

export default function PaymentSuccessfulScreen(): ReactElement {
  const navigate = useNavigate();

  const handleGoToOpScreen = (): void => {
    const token = localStorage.getItem('token');
    if (!token) navigate('/payment/unauthorized');

    return navigate(`/payment?token=${token}`);
  };

  return (
    <Box height='100vh'>
      <Box
        paddingX='10%'
        pt='20%'
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
      >
        <CheckCircleIcon sx={{ color: '#075bd6', fontSize: '100px' }} />
        <Typography variant='h2' mt={2}>
          Payment Successful!
        </Typography>
        <Typography textAlign='center' color='#1A1F39'>
          Congratulations! Your payment has been successfully processed. Thank you for your
          purchase! We&apos;re excited to confirm that your payment has gone through without any
          issues. You can now enjoy your product/service hassle-free.
        </Typography>
        <Box mt={3}>
          <Button onClick={handleGoToOpScreen}>
            <ArrowBack />
            &nbsp; Go Back to Optimyse
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
