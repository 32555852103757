import React, { ReactElement, useState } from 'react';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import '../../styles/main.scss';
import IconMenu from 'components/Menu/Menu';
import Grid from '@mui/material/Grid';
import ResponsiveAppBar from 'components/AppBar/ResponsiveAppBar';
import TabNavigation from 'components/TabNavigation/TabNavigation';
import Button from 'components/Button/Button';
import SideSlider, { SideSliderFooter } from 'components/SideSlider/SideSlider';
import Input from 'components/Input/Input';
import Divider from 'components/Divider/Divider';
import {
  VisaIcon,
  LocatonIcon,
  EmailIcon,
  VerifiedGreenIcon,
  SheildIcon,
  VisaSmallIcon
} from '../../components/Icons';
import { User } from 'types/user';
import UserTable from 'components/UsersTable/UsersTable';
import Subscription from 'components/Subscription/Subscription';
import BillingInfo from 'components/BillingInfo/BillingInfo';
import ActionButton from 'components/ActionButton/ActionButton';
import RadioButton from 'components/RadioButton/RadioButton';
import Checkbox from 'components/Checkbox/Checkbox';

const user: User = {
  userEmail: '',
  userName: 'Mr robot',
  userPicture:
    'https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png',
  emailVerified: true,
  token: '',
  isAuthenticated: false
};

function UIProfile(): ReactElement {
  const [activeTab, setActiveTab] = useState(4);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number): void => {
    setActiveTab(newValue);
  };

  const tabLabels = ['General', 'Profile', 'Users', 'Groups', 'Billing'];

  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const handleCloseSlider = (): void => {
    setIsSliderOpen(false);
  };

  const handleEditClick = (): void => {
    setIsSliderOpen(true);
  };

  const [isSliderOpen02, setIsSliderOpen02] = useState(false);
  const handleCloseSlider02 = (): void => {
    setIsSliderOpen02(false);
  };

  const handleEditClick02 = (): void => {
    setIsSliderOpen02(true);
  };

  const [isSliderOpen03, setIsSliderOpen03] = useState(false);
  const handleCloseSlider03 = (): void => {
    setIsSliderOpen03(false);
  };

  const handleEditClick03 = (): void => {
    setIsSliderOpen03(true);
  };

  const [firstName, setFirstName] = useState('Owais');
  const [lastName, setLastName] = useState('Afaq');
  const [email, setEmail] = useState('owais@optimyse.com');
  const [companyName, setcompanyName] = useState('Mikaels');
  const [streetAddress, setstreetAddress] = useState('Viru Valjak 2');
  const [postalCode, setPostalCode] = useState('10111');
  const [city, setCity] = useState('Tallinn');
  const [district, setDistrict] = useState('Kesklinn');
  const [country, setCountry] = useState('Estonia');
  const [taxID, settaxID] = useState('EE125365875');
  const [cardHolderName, setCardHolderName] = useState('Robert Peterson ');
  const [creditDebitCardNumber, setCreditDebitCardNumber] = useState('2348 5648 5412 2358');
  const [expiration, setExpiration] = useState('05/2027');
  const [cvc, setCvc] = useState('737');

  const [isChecked, setIsChecked] = useState(true);

  const handleCheckboxChange = (event: any): void => {
    setIsChecked(event.target.checked);
  };

  const [selectedOption, setSelectedOption] = useState('option1');
  const handleOptionChange = (value: string): void => {
    setSelectedOption(value);
  };

  return (
    <div>
      <Box className='wrapper'>
        <ResponsiveAppBar user={user} />
        <Box className='content_area '>
          <Box className='inner_content_area'>
            <Typography variant='h2' className='page_heading'>Settings</Typography>
            <TabNavigation
              labels={tabLabels}
              value={activeTab}
              onChange={handleTabChange}
            ></TabNavigation>
            <SideSlider
              isOpen={isSliderOpen}
              handleCloseSlider={handleCloseSlider}
              title='Edit payment details'
            >
              <Box>
                <Input
                  label='Card holder name*'
                  type='text'
                  value={cardHolderName}
                  onChange={setCardHolderName}
                />
                <Input
                  label='Credit/debit card number*'
                  type='text'
                  value={creditDebitCardNumber}
                  onChange={setCreditDebitCardNumber}
                  rightIcon={<VisaSmallIcon />}
                />
                <Grid container spacing={2.5}>
                  <Grid item sm={6} md={6} lg={6}>
                    <Input
                      label='Expiration month/year*'
                      type='text'
                      value={expiration}
                      onChange={setExpiration}
                    />
                  </Grid>
                  <Grid item sm={6} md={6} lg={6}>
                    <Input label='CVC*' type='text' value={cvc} onChange={setCvc} />
                  </Grid>
                </Grid>
                <Box className='mb-10'>
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    label='Set this card as default'
                  ></Checkbox>
                </Box>

                <Box className='d-flex mt-10 mb-10 align-items-center'>
                  <SheildIcon className='mr-4'></SheildIcon>
                  <Typography variant='subtitle1' className='mb-5 line-height-1 font-size-12 mb-0'>
                    Card information is stored on a secure server.
                  </Typography>
                </Box>
              </Box>

              <SideSliderFooter>
                <Button size='large' variant='secondary' onClick={() => setIsSliderOpen(false)}>
                  Cancel
                </Button>
                <Button size='large' disabled>
                  Update
                </Button>
              </SideSliderFooter>
            </SideSlider>

            <SideSlider
              isOpen={isSliderOpen02}
              handleCloseSlider={handleCloseSlider02}
              title='Edit billing address'
            >
              <Box>
                <Grid container spacing={2.5}>
                  <Grid item sm={6} md={6} lg={6}>
                    <Input
                      label='First name'
                      type='text'
                      value={firstName}
                      onChange={setFirstName}
                    />
                  </Grid>
                  <Grid item sm={6} md={6} lg={6}>
                    <Input label='Last name' type='text' value={lastName} onChange={setLastName} />
                  </Grid>
                </Grid>
              </Box>

              <Input
                label='Company name'
                type='text'
                value={companyName}
                onChange={setcompanyName}
              />
              <Input
                label='Street address'
                type='text'
                value={streetAddress}
                onChange={setstreetAddress}
              />

              <Grid container spacing={2.5}>
                <Grid item sm={6} md={6} lg={6}>
                  <Input
                    label='Postal code'
                    type='text'
                    value={postalCode}
                    onChange={setPostalCode}
                  />
                </Grid>
                <Grid item sm={6} md={6} lg={6}>
                  <Input label='City' type='text' value={city} onChange={setCity} />
                </Grid>
              </Grid>

              <Grid container spacing={2.5}>
                <Grid item sm={6} md={6} lg={6}>
                  <Input label='District' type='text' value={district} onChange={setDistrict} />
                </Grid>
                <Grid item sm={6} md={6} lg={6}>
                  <Input label='Country' type='text' value={cvc} onChange={setCvc} />
                </Grid>
              </Grid>

              <SideSliderFooter>
                <Button size='large' variant='secondary' onClick={() => setIsSliderOpen02(false)}>
                  Cancel
                </Button>
                <Button size='large' disabled>
                  Update
                </Button>
              </SideSliderFooter>
            </SideSlider>

            <SideSlider
              isOpen={isSliderOpen03}
              handleCloseSlider={handleCloseSlider03}
              title='Edit tax ID'
            >
              <Box>
                <Input label='Tax ID' type='text' value={taxID} onChange={settaxID} />
                <Input label='Country' type='text' value={country} onChange={setCountry} />
              </Box>

              <SideSliderFooter>
                <Button size='large' variant='secondary' onClick={() => setIsSliderOpen03(false)}>
                  Cancel
                </Button>
                <Button size='large' disabled>
                  Update
                </Button>
              </SideSliderFooter>
            </SideSlider>

            <Grid container spacing={2.5} className='mb-20'>
              <Grid item sm={12} md={8} lg={8.5}>
                <Subscription subscriptionName='Basic Yearly' noOfSeat='50' date='01.05.2023'>
                  <Box>
                    <Button variant='secondary' size='small'>
                      Change plan
                    </Button>
                  </Box>
                  <Box className='d-flex mr--5'>
                    <Box>
                      <Button variant='secondary' size='small'>
                        Add seats
                      </Button>
                    </Box>
                    <Box>
                      <Button variant='secondary' size='small'>
                        Remove seats
                      </Button>
                    </Box>
                  </Box>
                </Subscription>
              </Grid>

              <Grid item sm={12} md={4} lg={3.5}>
                <BillingInfo
                  price='€3450'
                  date='01.05.2024'
                  subscriptionName='Basic yearly subscription'
                  prorductInfo='50 seats x €69'
                  totalPrice='€3450'
                ></BillingInfo>
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={2.5} className='mb-20'>
              <Grid item sm={12} md={12} lg={3.5}>
                <Typography variant='h4' className='mb-5'>
                  Payment details
                </Typography>
                <Typography>Change how you pay for your plan.</Typography>
              </Grid>
              <Grid item sm={12} md={12} lg={8.5}>
                <Box display='flex' justifyContent='space-between' alignItems='flex-start'>
                  <Box className='d-flex'>
                    <VisaIcon className='mr-10'></VisaIcon>
                    <Box>
                      <Typography variant='body2'>
                        <strong>Visa **** 2358</strong>
                      </Typography>
                      <Typography variant='body1'>Expiry 05.2027</Typography>
                    </Box>
                  </Box>
                  <ActionButton type='edit' onClick={handleEditClick}></ActionButton>
                </Box>
              </Grid>
            </Grid>
            <Divider />

            <Grid container spacing={2.5} className='mb-30'>
              <Grid item sm={12} md={12} lg={3.5}>
                <Typography variant='h4' className='mb-5'>
                  Billing address
                </Typography>
                <Typography>This address will appear on your invoices.</Typography>
              </Grid>
              <Grid item sm={12} md={12} lg={8.5}>
                <Box display='flex' justifyContent='space-between' alignItems='flex-start'>
                  <Box className='d-flex'>
                    <LocatonIcon className='mr-10'></LocatonIcon>
                    <Box>
                      <Typography variant='body2'>
                        <strong>Owais Afaq Akhter</strong>
                      </Typography>
                      <Typography variant='body1'>
                        Mikaels
                        <br />
                        Viru Valjak 2, 10111 Kesklinn
                        <br />
                        Tallinn, Estonia
                      </Typography>
                    </Box>
                  </Box>

                  <ActionButton type='edit' onClick={handleEditClick02}></ActionButton>
                </Box>
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={2.5} className='mb-30'>
              <Grid item sm={12} md={12} lg={3.5}>
                <Typography variant='h4' className='mb-5'>
                  Tax ID
                </Typography>
                <Typography>Your tax ID can affect how your taxes are handled.</Typography>
              </Grid>
              <Grid item sm={12} md={12} lg={8.5}>
                <Box display='flex' justifyContent='space-between' alignItems='flex-start'>
                  <Box className='d-flex'>
                    <Typography variant='body2' className='mr-4'>
                      EE125365875
                    </Typography>
                    <VerifiedGreenIcon></VerifiedGreenIcon>
                  </Box>
                  <ActionButton type='edit' onClick={handleEditClick03}></ActionButton>
                </Box>
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={2.5} className='mb-30'>
              <Grid item sm={12} md={12} lg={3.5}>
                <Typography variant='h4' className='mb-5'>
                  Contact email
                </Typography>
                <Typography>Invoices will be sent to this email address.</Typography>
              </Grid>
              <Grid item sm={12} md={12} lg={8.5}>
                <RadioButton
                  checked={selectedOption === 'option1'}
                  onChange={() => handleOptionChange('option1')}
                  label='Send to my account email'
                  value='option1'
                />
                <Input
                  label=''
                  leftIcon={<EmailIcon />}
                  type='email'
                  value={email}
                  disabled
                  onChange={setEmail}
                />

                <RadioButton
                  checked={selectedOption === 'option2'}
                  onChange={() => handleOptionChange('option2')}
                  label='Send to an alternate email'
                  value='option2'
                />
                <Input
                  label=''
                  leftIcon={<EmailIcon />}
                  type='email'
                  value={email}
                  onChange={setEmail}
                />
              </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={2.5} className='mb-30'>
              <Grid item sm={12} md={12} lg={3.5}>
                <Typography variant='h4' className='mb-5'>
                  Transaction history
                </Typography>
                <Typography>Download your previous plan receipts.</Typography>
              </Grid>
              <Grid item sm={12} md={12} lg={8.5}>
                <UserTable></UserTable>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default UIProfile;
