import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'bootstrap';
import 'bootstrap-daterangepicker/daterangepicker';
import $ from 'jquery';
import styles from '../../styles/components/DateRange.module.scss';
import '../../styles/components/DateRangeCalendar.scss';
import { CalendarIcon } from 'components/Icons';
import { add, format } from 'date-fns';

interface DateRangeProps {
  onChange: (startDate: Date, endDate: Date) => void;
  placeholder?: string;
  startDate?: Date;
  endDate?: Date;
}

const DateRange: React.FC<DateRangeProps> = ({ onChange, startDate, endDate, placeholder }) => {
  const defaultPlaceholder = `${format(new Date(), 'MM.dd.yyyy')} - ${format(
    add(new Date(), { days: 5 }),
    'MM.dd.yyyy'
  )}`;
  const dateRangeRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    $(dateRangeRef.current).daterangepicker({
      showCustomRangeLabel: true,
      alwaysShowCalendars: true,
      opens: 'center',
      locale: {
        format: 'MM.DD.YYYY',
        separator: ' - ',
        applyLabel: 'Apply',
        cancelLabel: 'Cancel'
      }
    });

    $(dateRangeRef.current).on('apply.daterangepicker', function (ev: any, picker: any) {
      onChange(picker.startDate.toDate(), picker.endDate.toDate());
    });

    const $dateRangePicker = $(dateRangeRef.current);
    $dateRangePicker.val('');

    return () => {
      $dateRangePicker.data('daterangepicker').remove();
    };
  }, []);

  return (
    <Box className={styles.calendar_wrap}>
      <Box className={styles.inner_row}>
        <CalendarIcon />
        <input
          type='text'
          className='form-control'
          ref={dateRangeRef}
          placeholder={placeholder || defaultPlaceholder}
        />
      </Box>
    </Box>
  );
};

export default DateRange;
