import React, { ReactElement, useEffect } from 'react';
import logo from './assets/optimyse-logo.png';
import './styles/main.scss';
import 'slick-carousel/slick/slick.css';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import * as userService from './services/UserService';
import { User } from 'types/user';

function App(): ReactElement {
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated && user) {
      const userObj: User = {
        isAuthenticated: isAuthenticated,
        userEmail: user.email || '',
        userName: user.nickname || '',
        userPicture: user.picture || '',
        emailVerified: user.email_verified || false,
        organizationId: ''
      };

      userService.setToken(dispatch, getAccessTokenSilently);
      userService.setUser(dispatch, userObj);
      userService.postUser(dispatch, userObj);
    }
  }, [user, isAuthenticated, getAccessTokenSilently, dispatch]);

  return (
    <header className='Full-page-centered'>
      <img src={logo} className='App-logo' data-testid='App-logo' alt='Optimyse' />
    </header>
  );
}

export default App;
