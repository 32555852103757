import React, { ReactElement, useState } from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { PaymentService, Subscription } from 'services/PaymentService';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ConfirmCancelSubscriptionModal } from './CancelSubscriptionModal';

interface SubscriptionTableProps {
  subscriptions: Subscription[];
  reloadSubscriptions?: () => void;
}

export default function SubscriptionTable({
  subscriptions,
  reloadSubscriptions
}: SubscriptionTableProps): ReactElement {
  const [cancelling, setCancelling] = useState(false);
  const [openConfirmCancelModal, setOpenConfirmCancelModal] = useState(false);
  const [activeSubscriptionId, setActiveSubscriptionId] = useState('');
  const [subscriptionActionMenu, setSubscriptionActionMenu] = useState<null | HTMLElement>(null);

  const rows = subscriptions;
  const open = Boolean(subscriptionActionMenu);

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
    setSubscriptionActionMenu(event.currentTarget);
  };

  const handleMenuClose = (): void => {
    setSubscriptionActionMenu(null);
  };

  const handleCancelSubscriptionClick = (subscriptionId: string) => (): void => {
    setActiveSubscriptionId(subscriptionId);
    setOpenConfirmCancelModal(true);
    handleMenuClose();
  };

  const handleConfirmCancelSubscriptionModalClose = (): void => {
    setActiveSubscriptionId('');
    setOpenConfirmCancelModal(false);
  };

  const handleCancelSubscription = async (): Promise<void> => {
    try {
      setCancelling(true);
      await PaymentService.cancelSubscription(
        activeSubscriptionId,
        localStorage.getItem('token') as string
      );
      if (reloadSubscriptions) reloadSubscriptions();
    } catch (error) {
      console.log(error);
    } finally {
      setCancelling(false);
      setOpenConfirmCancelModal(false);
    }
  };

  return (
    <TableContainer>
      <Table sx={{ minWidth: 700 }} aria-label='subscriptions table'>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Start Date</TableCell>
            <TableCell>End Date</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Currency</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.length === 0 && (
            <TableRow>
              <TableCell>You do not have any items.</TableCell>
            </TableRow>
          )}

          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.productName}</TableCell>
              <TableCell>{new Date(row.startDate).toDateString()}</TableCell>
              <TableCell>{new Date(row.endDate).toDateString()}</TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell>{row.amount}</TableCell>
              <TableCell>{row.currency}</TableCell>
              <TableCell>
                <IconButton aria-label='subscription-actions' onClick={handleMenuOpen}>
                  <MoreVertIcon />
                </IconButton>

                <Menu
                  id='subscription-actions-menu'
                  anchorEl={subscriptionActionMenu}
                  open={open}
                  onClose={handleMenuClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button'
                  }}
                >
                  <MenuItem
                    disabled={row.status !== 'active'}
                    onClick={handleCancelSubscriptionClick(row.id)}
                  >
                    Cancel
                  </MenuItem>
                </Menu>

                <ConfirmCancelSubscriptionModal
                  open={openConfirmCancelModal}
                  disabled={cancelling}
                  handleClose={handleConfirmCancelSubscriptionModalClose}
                  handleCancel={handleCancelSubscription}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
