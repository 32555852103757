import { Priority } from 'types/api';

export const mockPriorities: Priority[] = [
  {
    id: '1',
    name: 'P1',
    color: '#F00'
  },
  {
    id: '2',
    name: 'P2',
    color: '#F90'
  },
  {
    id: '3',
    name: 'P3',
    color: '#FF0'
  },
  {
    id: '4',
    name: 'P4',
    color: '#7FFF00'
  },
  {
    id: '5',
    name: 'P5',
    color: '#007FFF'
  },
  {
    id: '6',
    name: 'P6',
    color: '#E5E5E5'
  }
];
