import { IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import React, { ChangeEvent } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import styles from 'styles/components/CommentField.module.scss';
import Avatar01 from 'assets/images/global-icons/avatar-01.svg';
import { ReactComponent as SendIcon } from 'assets/images/global-icons/send.svg';

import AvatarName from '../AvatarName/AvatarName';
import { User } from 'types/user';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

interface AddIconsProps {
  handleCommentSubmit: () => void;
  toBeDisabled: boolean;
}

interface EditIconsProps {
  onSubmit: () => void;
  onCancel: () => void;
  toBeDisabled: boolean;
}

interface CommentFieldProps {
  onSubmit: (comment: string) => void;
  onCancel?: () => void;
  onEditComplete?: () => void;
  mode?: 'add' | 'edit';
  initialValue?: string;
}

const LIMIT = 250;

const AddCommentTitle = (): React.ReactElement => (
  <Typography className={styles.title} variant='h4' component='h4'>
    Add a comment
  </Typography>
);

const UserAvatar = (): React.ReactElement => (
  <IconButton sx={{ padding: '0' }}>
    <AvatarName noMargin name='' src={Avatar01} alt='image' />
  </IconButton>
);

const AddIcons = ({ handleCommentSubmit, toBeDisabled }: AddIconsProps): React.ReactElement => (
  <IconButton
    className={styles.add_icon_button}
    type='submit'
    disabled={toBeDisabled}
    onClick={handleCommentSubmit}
    data-testid='send-comment-button'
  >
    <SendIcon />
  </IconButton>
);

const EditIcons = ({ onSubmit, onCancel, toBeDisabled }: EditIconsProps): React.ReactElement => (
  <>
    <IconButton
      data-testid='comment-edit-close-button'
      onClick={() => {
        onCancel();
      }}
    >
      <CloseIcon fontSize='small' />
    </IconButton>
    <IconButton
      data-testid='comment-edit-submit-button'
      className={`${styles.check_icon_button} ${toBeDisabled ? 'disabled' : 'active'}`}
      disabled={toBeDisabled}
      onClick={() => {
        onSubmit();
      }}
      sx={{ color: toBeDisabled ? '#6E7B92' : '#075bd6' }}
    >
      <CheckIcon fontSize='small' />
    </IconButton>
  </>
);

const CharacterLimit = ({ trimComment }: { trimComment: () => string }): React.ReactElement => (
  <Typography variant='body1' className={styles.character_limit}>
    {trimComment().length}/{LIMIT}
  </Typography>
);

const CommentField = ({
  onSubmit,
  onCancel,
  onEditComplete,
  mode = 'add',
  initialValue = ''
}: CommentFieldProps): React.ReactElement => {
  const [comment, setComment] = React.useState(initialValue);
  const user: User = useSelector((state: RootState) => state.user);

  const trimComment = (): string => comment.trim();

  const isCommentValid = (): boolean => {
    const trimmedComment = trimComment();
    return trimmedComment.length > 0 && trimmedComment.length <= LIMIT;
  };

  const handleCommentChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    if(e.target.value.length<= LIMIT){
      setComment(e.target.value);
    }
    
  };

  const handleCommentSubmit = (): void => {
    if (isCommentValid()) {
      onSubmit(trimComment());
      if (mode === 'edit') {
        onEditComplete?.();
      }
      setComment('');
    }
  };

  const handleCommentCancel = (): void => {
    if (onCancel) {
      if (mode === 'edit') {
        onEditComplete?.();
      }
      onCancel();
    }
  };

  const CommentTitle = mode === 'add' ? <AddCommentTitle /> : null;
  const Avatar = mode === 'add' && (
    <AvatarName noMargin name='' src={user.userPicture || Avatar01} alt='image' width='32px' />
  );
  const EndAdornmentIcons =
    mode === 'add' ? (
      <AddIcons handleCommentSubmit={handleCommentSubmit} toBeDisabled={!isCommentValid()} />
    ) : (
      <EditIcons
        onSubmit={handleCommentSubmit}
        onCancel={handleCommentCancel}
        toBeDisabled={!isCommentValid()}
      />
    );

  return (
    <div className={styles.comment_field_container}>
      {CommentTitle}
      {user && (
        <>
          <div
            className={`${styles.comment_input_container} ${
              isCommentValid() ? '' : styles.invalid_comment
            }`}
          >
            {Avatar}
            <TextField
              data-testid='comment-textbox'
              fullWidth
              variant='outlined'
              multiline
              rows={5}
              value={comment}
              onChange={handleCommentChange}
              placeholder='Write a comment...'
              className={styles.comment_txt_area}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end' sx={{ alignSelf: 'flex-end', height: 'auto' }}>
                    {EndAdornmentIcons}
                  </InputAdornment>
                )
              }}
              sx={{
                padding: 0,
                '& textarea': {
                  '&::-webkit-scrollbar': {
                    width: '0px'
                  },
                  scrollbarWidth: 'none'
                },
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none'
                  },
                  '&:hover fieldset': {
                    border: 'none'
                  },
                  '&.Mui-focused fieldset': {
                    border: 'none'
                  },
                  '&.MuiOutlinedInput-root': {
                    padding: 0
                  }
                }
              }}
            />
          </div>
        </>
      )}
      <CharacterLimit trimComment={trimComment} />
    </div>
  );
};

export default CommentField;
