import { format } from 'date-fns';
import { Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';

import {
  addSelectedFilter,
  removeSelectedFilter,
  setSelectedFilters,
  updateOrAddSelectedFilter,
  removeSelectedFilterByValue
} from 'redux/slices/prioritizeFiltersSlice';
import { RootState } from 'redux/store';
import { debounce } from 'utils/debounce';
import { getIssues } from 'services/IssuesService';
import { IssueCategory, IssueStatus } from 'types/settings';
import { PrioritizeIssuesFilters, Priority } from 'types/api';
import { setLoading, updateIssues } from 'redux/slices/issuesSlice';
import { filterItems, selectedFiltersIconMap } from './FilterConfigs';
import { FilterIcon, RadioCheckedIcon, RadioUncheckIcon } from 'components/Icons';
import { CheckboxItem, Filters, NumberedRange, SelectedFilter } from 'types/filters';
import Checkbox from 'components/Checkbox/Checkbox';
import DateRange from 'components/DateRange/DateRange';
import ChipButton from 'components/ChipButton/ChipButton';
import RangeSlider from 'components/RangeSlider/RangeSlider';
import SelectDropdown from 'components/SelectDropdown/SelectDropdown';
import FilterDropdown from 'components/FilterDropdown/FilterDropdown';
import { prioritizedIssuesLimit } from 'screens/prioritize/PrioritizeScreen';
import { constructFiltersFromSelectedFilters } from './PrioritizeFiltersUtils';

const sortingOptions = [{ value: 'priority', label: 'Priority' }];

export const PrioritizeFilters: React.FC = () => {
  const [sortBy] = useState('');
  const [withComments, setWithComments] = useState<string>('');
  const [activeFilter, setActiveFilter] = useState<Filters | null>();
  const [statusCheckboxes, setStatusCheckboxes] = useState<CheckboxItem[]>([]);
  const [priorityCheckBoxes, setPriorityCheckBoxes] = useState<CheckboxItem[]>([]);
  const [categoryCheckboxes, setCategoryCheckBoxes] = useState<CheckboxItem[]>([]);
  const [estimatedTimeRange, setEstimatedTimeRange] = useState<NumberedRange>([0, 24]);
  const [estimatedProgressRange, setEstimatedProgress] = useState<NumberedRange>([0, 100]);

  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const selectedFilters = useSelector((s: RootState) => s.prioritizeFilters.selectedFilters);
  const { priorities, statuses, categories } = useSelector((s: RootState) => s.issuesSettings);

  useEffect(() => {
    const filters = searchParams.get('filters');

    if (filters) {
      const parsedFilters = JSON.parse(filters);
      dispatch(setSelectedFilters(parsedFilters));
    }
  }, []);

  useEffect(() => {
    if (selectedFilters.length === 0) {
      return;
    }

    const withCommentsFilter = selectedFilters.find((f) => f.filter === Filters.COMMENTS);
    if (withCommentsFilter) {
      setWithComments(withCommentsFilter.value);
    }

    const estTimeFilter = selectedFilters.find((f) => f.filter === Filters.ESTIMATED_TIME);
    if (estTimeFilter) {
      const [startTime, endTime] = estTimeFilter.value.split('-').map(parseInt);
      setEstimatedTimeRange([startTime, endTime]);
    }

    const progressFilter = selectedFilters.find((f) => f.filter === Filters.PROGRESS);
    if (progressFilter) {
      const [startProg, endProg] = progressFilter.value.split('-').map(parseInt);
      setEstimatedTimeRange([startProg, endProg]);
    }

    const estDateFilter = selectedFilters.find((f) => f.filter === Filters.ESTIMATED_TIME);
    if (estDateFilter) {
      /** estimated date to be completed */
    }
  }, []);

  useEffect(() => {
    setPriorityCheckBoxes(
      priorities.map((p: Priority) => {
        const selected = selectedFilters.some(
          (f) => f.filter == Filters.PRIORITY && parseInt(f.value) === parseInt(p.id)
        );
        return { label: p.name, value: p.id, checked: selected };
      })
    );
  }, [priorities.length]);

  useEffect(() => {
    setCategoryCheckBoxes(
      categories.map((c: IssueCategory) => {
        const selected = selectedFilters.some(
          (f) => f.filter == Filters.CATEGORY && parseInt(f.value) === c.id
        );
        return { label: c.name, value: c.name, checked: selected };
      })
    );
  }, [categories.length]);

  useEffect(() => {
    setStatusCheckboxes(
      statuses.map((s: IssueStatus) => {
        const selected = selectedFilters.some(
          (f) => f.filter == Filters.STATUS && parseInt(f.value) === s.id
        );
        return { checked: selected, label: s.name, value: s.id };
      })
    );
  }, [statuses.length]);

  const filterIssues = async (filters: PrioritizeIssuesFilters): Promise<void> => {
    dispatch(setLoading(true));
    const response = await getIssues(1, prioritizedIssuesLimit, filters);
    dispatch(updateIssues({ items: response.issues, pagination: response.pagination }));
    dispatch(setLoading(false));
  };

  const updateSearchParams = (filters: SelectedFilter[]): void => {
    searchParams.set('filters', JSON.stringify(filters));

    setSearchParams(searchParams);
  };

  useEffect(() => {
    const filters = constructFiltersFromSelectedFilters(selectedFilters);
    updateSearchParams(selectedFilters);
    filterIssues(filters);
  }, [selectedFilters]);

  const removeSelectedFilterFromLocalFormState = (deletedFilter: SelectedFilter): void => {
    const { filter, value, label } = deletedFilter;

    switch (filter) {
      case Filters.PRIORITY:
        setPriorityCheckBoxes(
          priorityCheckBoxes.map((p) => {
            return p.label === label && p.value === parseInt(value) ? { ...p, checked: false } : p;
          })
        );
        break;

      case Filters.CATEGORY:
        setCategoryCheckBoxes(
          categoryCheckboxes.map((c) => {
            return c.label === label && c.value === value ? { ...c, checked: false } : c;
          })
        );
        break;

      case Filters.PROGRESS:
        setEstimatedProgress([0, 100]);
        break;

      case Filters.STATUS:
        setStatusCheckboxes(
          statusCheckboxes.map((s) => {
            return s.label === label && s.value === parseInt(value) ? { ...s, checked: false } : s;
          })
        );
        break;

      case Filters.COMMENTS:
        setWithComments('');
        break;

      case Filters.ESTIMATED_TIME:
        setEstimatedTimeRange([0, 24]);
        break;

      default:
        return;
    }
  };

  const handleDelete = (deletedFilter: SelectedFilter, filterToDelete: string): void => {
    removeSelectedFilterFromLocalFormState(deletedFilter);
    dispatch(removeSelectedFilterByValue(filterToDelete));
  };

  const handlePriorityCheckBoxChange = (index: number) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, value } = event.target;
      const updatedPriorities = [...priorityCheckBoxes];
      updatedPriorities[index].checked = checked;
      setPriorityCheckBoxes(updatedPriorities);

      if (checked) {
        dispatch(addSelectedFilter({ filter: Filters.PRIORITY, label: `P${value}`, value }));
      } else {
        dispatch(removeSelectedFilter({ filter: Filters.PRIORITY, value }));
      }
    };
  };

  const handleStatusCheckboxChange =
    (filterItem: CheckboxItem, index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, value } = event.target;
      const updatedCheckboxes = [...statusCheckboxes];
      updatedCheckboxes[index].checked = event.target.checked;
      setStatusCheckboxes(updatedCheckboxes);

      if (checked) {
        dispatch(addSelectedFilter({ filter: Filters.STATUS, label: filterItem.label, value }));
      } else {
        dispatch(removeSelectedFilter({ filter: Filters.STATUS, value }));
      }
    };

  const handleCategoryCheckboxChange = (filterItem: CheckboxItem, index: number) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      const { checked, value } = event.target;
      const updatedCategories = [...categoryCheckboxes];
      updatedCategories[index].checked = checked;
      setPriorityCheckBoxes(updatedCategories);

      if (checked) {
        dispatch(addSelectedFilter({ filter: Filters.CATEGORY, label: filterItem.label, value }));
      } else {
        dispatch(removeSelectedFilter({ filter: Filters.CATEGORY, value }));
      }
    };
  };
  const handleWithCommentsChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setWithComments(value);
    dispatch(
      updateOrAddSelectedFilter({
        value,
        filter: Filters.COMMENTS,
        label: value === 'true' ? 'With Comments' : 'Without Comments'
      })
    );
  };

  const debouncedEstimatedTimeChange = useMemo(
    () =>
      debounce((selectedFilter: SelectedFilter) => {
        dispatch(updateOrAddSelectedFilter(selectedFilter));
      }, 500),
    []
  );

  const handleEstimatedTimeChange = (value: NumberedRange): void => {
    setEstimatedTimeRange(value);

    const [startTime, endTime] = value;
    const range = `${startTime}h - ${endTime}h`;
    debouncedEstimatedTimeChange({
      filter: Filters.ESTIMATED_TIME,
      value: range,
      label: range
    });
  };

  const debouncedEstimatedProgressChange = useMemo(
    () =>
      debounce((selectedFilter: SelectedFilter) => {
        dispatch(updateOrAddSelectedFilter(selectedFilter));
      }, 500),
    []
  );

  const handleEstimatedProgressChange = (value: NumberedRange): void => {
    setEstimatedProgress(value);

    const [startProgress, endProgress] = value;
    const range = `${startProgress}% - ${endProgress}%`;
    debouncedEstimatedProgressChange({
      filter: Filters.PROGRESS,
      value: value.join('-'),
      label: range
    });
  };

  const handleOnFilterItemClick = (filterClicked: Filters): void => {
    setActiveFilter(filterClicked);
  };

  const handleDateRangeChange = (startDate: Date, endDate: Date): void => {
    const dateRangeISOValue = `${startDate.toISOString()}^${endDate.toISOString()}`;
    const formattedStartDate = format(startDate, 'MM.dd.yyyy');
    const formattedEndDate = format(endDate, 'MM.dd.yyyy');
    const dateRangeLabel = `${formattedStartDate} - ${formattedEndDate}`;

    dispatch(
      updateOrAddSelectedFilter({
        filter: Filters.ESTIMATED_DATE,
        value: dateRangeISOValue,
        label: dateRangeLabel
      })
    );
  };

  const renderChildren = (): ReactNode => {
    switch (activeFilter) {
      case Filters.SORT_BY:
        return <SelectDropdown options={sortingOptions} value={sortBy} />;
      case Filters.CATEGORY:
        return categoryCheckboxes.map((ch, index) => (
          <Checkbox
            key={index}
            checked={ch.checked}
            onChange={handleCategoryCheckboxChange(ch, index)}
            label={ch.label}
            value={ch.value}
          />
        ));
      case Filters.COMMENTS:
        return (
          <FormControl>
            <RadioGroup
              aria-labelledby='demo-controlled-radio-buttons-group'
              name='controlled-radio-buttons-group'
              value={withComments}
              onChange={handleWithCommentsChange}
            >
              <FormControlLabel
                value='true'
                control={<Radio icon={<RadioUncheckIcon />} checkedIcon={<RadioCheckedIcon />} />}
                label='With Comments'
              />
              <FormControlLabel
                value='false'
                control={<Radio icon={<RadioUncheckIcon />} checkedIcon={<RadioCheckedIcon />} />}
                label='Without Comments'
              />
            </RadioGroup>
          </FormControl>
        );

      case Filters.ESTIMATED_DATE:
        return <DateRange onChange={handleDateRangeChange} />;

      case Filters.ESTIMATED_TIME:
        return (
          <RangeSlider
            onChange={handleEstimatedTimeChange}
            isPercentage={false}
            rangeValue={estimatedTimeRange}
          />
        );

      case Filters.PRIORITY:
        return priorityCheckBoxes.map((ch, index) => (
          <Checkbox
            key={index}
            checked={ch.checked}
            onChange={handlePriorityCheckBoxChange(index)}
            label={ch.label}
            value={ch.value}
          />
        ));

      case Filters.PROGRESS:
        return (
          <RangeSlider
            rangeValue={estimatedProgressRange}
            onChange={handleEstimatedProgressChange}
            isPercentage={true}
          />
        );

      case Filters.STATUS:
        return statusCheckboxes.map((ch, index) => (
          <Checkbox
            key={index}
            checked={ch.checked}
            onChange={handleStatusCheckboxChange(ch, index)}
            label={ch.label}
            value={ch.value}
          />
        ));

      default:
        return <></>;
    }
  };

  const handleDeactivateFilter = (): void => {
    dispatch(setSelectedFilters([]));
  };

  return (
    <>
      <Box sx={{ display: 'flex', mb: 2 }}>
        <FilterDropdown
          active={true}
          isActive={selectedFilters.length > 0}
          title='Filters'
          buttonName='Filters'
          dropdownItems={filterItems}
          ButtonIcon={<FilterIcon />}
          onFilterDeactivate={handleDeactivateFilter}
          onFilterItemClick={handleOnFilterItemClick}
        >
          {renderChildren()}
        </FilterDropdown>
      </Box>

      {selectedFilters.length > 0 && (
        <Box className='chipbox_wrap'>
          {selectedFilters.map((chipData, index) => (
            <ChipButton
              key={index}
              label={chipData.label}
              onDelete={() => handleDelete(chipData, chipData.value)}
              chipIcon={selectedFiltersIconMap[chipData.filter]}
            />
          ))}
        </Box>
      )}
    </>
  );
};
