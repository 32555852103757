import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { InviteUser } from 'types/user';

export interface UsersInitialState {
  items: InviteUser[];
  loading: boolean;
}

const initialState: UsersInitialState = { items: [], loading: false };

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setLoading: (state: UsersInitialState, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    setUsers: (state: UsersInitialState, action: PayloadAction<InviteUser[]>) => {
      state.items = action.payload;
    },

    addNewInvitedUser: (state: UsersInitialState, action: PayloadAction<InviteUser>) => {
      state.items = state.items.concat(action.payload);
    },

    updateInvitedUser: (
      state: UsersInitialState,
      action: PayloadAction<{ id: string; updatedUser: InviteUser }>
    ) => {
      console.log('Action: ', action);

      const { id, updatedUser } = action.payload;
      state.items = state.items.map((user) => (user.id === id ? updatedUser : user));
    }
  }
});

export const { setLoading, setUsers, addNewInvitedUser, updateInvitedUser } = usersSlice.actions;

export default usersSlice.reducer;
