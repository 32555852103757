import * as React from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';


import { DownArrow } from '../Icons'; // Import your custom DownArrow icon
import styles from '../../styles/components/SelectDropdown.module.scss';

interface SelectProps {
  label?: string;
  baseIcon?: React.ReactElement;
  className?: string;
  options: { value: string; label: string; icon?: React.ReactElement }[];
  errorMessage?: string;
  value?: string;
  size?: 'small' | 'medium';
}

function SelectDropdown({
  label,
  options,
  errorMessage,
  value,
  baseIcon,
  size='medium',
  className,
  ...rest
}: SelectProps): React.ReactElement {
  return (
    <FormControl variant='outlined' error={Boolean(errorMessage)} className={`${styles.select_wrapper} ${className}`} size={size}>
      <InputLabel>{label}</InputLabel>
      <Select
        defaultValue={value}
        label={label}
        {...rest}
        className={styles.select_dropdown}
        IconComponent={() => baseIcon || <DownArrow />}
      >
        {options.map((option, index) => (
          <MenuItem
            key={option.value}
            value={option.value}
            className={styles.dropdown_items}
          >
            {option.icon}
            {option.label}
          </MenuItem>
        ))}
      </Select>

      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
}

export default SelectDropdown;
