import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ReloadIcon } from '../Icons';
import * as React from 'react';
import { Pagination } from 'types/api';
import { ColumnData, RowData } from 'types/table';
import styles from 'styles/components/Table.module.scss';
import Loader from 'components/Loader/Loader';

interface TableProps {
  columns: ColumnData[];
  data: RowData[];
  dataTestId: string;
  pagination?: Pagination;
  isLoadMoreLoading?: boolean;
  isTableRecordsLoading?: boolean;
  onLoadMoreClick?: () => void;
}

const BasicTable = ({
  columns,
  data,
  dataTestId,
  pagination,
  isLoadMoreLoading,
  isTableRecordsLoading,
  onLoadMoreClick
}: TableProps): React.ReactElement => {
  const handleLoadMoreClick = (): void => {
    if (onLoadMoreClick) {
      onLoadMoreClick();
    }
  };

  if (isTableRecordsLoading) {
    return (
      <TableContainer className={styles.table_wrap}>
        <Box display='flex' alignItems='center' justifyContent='center' minHeight='65vh'>
          <Loader variant='spinner' isLoading={true} />
        </Box>
      </TableContainer>
    );
  }

  return (
    <TableContainer className={styles.table_wrap}>
      <Table data-testid={dataTestId} sx={{ minWidth: 650 }} aria-label='simple table'>
        <TableHead>
          <TableRow>
            {columns.map((column, idx) => (
              <TableCell key={column.id || idx} style={{ minWidth: column.minWidth }}>
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((dataItem, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((column, columnIndex) => (
                <TableCell key={`row_cell_${column.id}`}>
                  {columnIndex === 0 ? (
                    <span className={styles.task_name}>{dataItem[column.id]}</span>
                  ) : (
                    dataItem[column.id]
                  )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {pagination?.hasMore && (
        <Box sx={{ width: '100%' }} className={styles.load_more_task_btn}>
          <Link onClick={handleLoadMoreClick}>
            <ReloadIcon className={isLoadMoreLoading ? styles.active : ''} />
            Load more tasks
          </Link>
        </Box>
      )}
    </TableContainer>
  );
};

export default BasicTable;
