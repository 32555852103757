import * as React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import styles from '../../styles/components/Input.module.scss';

interface InputProps {
  label?: string;
  type?: string;
  value: any;
  onChange: (value: any) => void;
  placeholder?: string;
  required?: boolean;
  color?: 'error' | 'info' | 'success' | 'warning';
  disabled?: boolean;
  error?: boolean;
  message?: React.ReactNode;
  rows?: number;
  maxRows?: number;
  multiline?: boolean;
  leftIcon?: React.ReactNode; // Add leftIcon prop
  rightIcon?: React.ReactNode; // Add rightIcon prop
  helperText?: string;
  className?: string;
}

function Input({
  label,
  type,
  value,
  required,
  onChange,
  placeholder,
  color,
  error,
  message,
  disabled,
  rows = 4,
  maxRows,
  multiline = false,
  helperText,
  leftIcon, // Destructure leftIcon prop
  rightIcon, // Destructure rightIcon prop
  className
}: InputProps): React.ReactElement {
  return (
    <Box className={styles.input_wrapper}>
      <TextField
        fullWidth
        label={label}
        type={type}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        variant='outlined'
        focused
        color={color}
        helperText={message || helperText}
        error={error}
        disabled={disabled}
        rows={rows}
        maxRows={maxRows}
        multiline={multiline}
        required={required}
        className={`${styles.input_feild} ${className}`}
        InputProps={{
          startAdornment: leftIcon ? (
            <InputAdornment position='start'>{leftIcon}</InputAdornment>
          ) : null,
          endAdornment: rightIcon ? (
            <InputAdornment position='end'>{rightIcon}</InputAdornment>
          ) : null
        }}
      />
    </Box>
  );
}

export default Input;
