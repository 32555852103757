import React, { ReactNode } from 'react';
import { useDispatch } from 'react-redux';

import { Issue } from 'types/api';
import { updateIssue } from 'services/IssuesService';
import { updateIssueById } from 'redux/slices/issuesSlice';

export interface PrioritizeContextType {
  updatePriority: (issueId: string, priority: string) => void;
}

interface PriorityProviderProps {
  children: ReactNode;
  value?: PrioritizeContextType;
}

export const PrioritizeContext = React.createContext<PrioritizeContextType | undefined>(undefined);

export const PrioritizeProvider: React.FC<PriorityProviderProps> = ({ children }) => {
  const dispatch = useDispatch();

  const updatePriority = (id: string, priorityId: string): void => {
    updateIssue(id, {
      priorityId: `${priorityId}`
    }).then((issue: Issue | undefined) => {
      if (issue) {
        dispatch(
          updateIssueById({
            id,
            data: issue
          })
        );
      }
    });
  };

  return (
    <PrioritizeContext.Provider value={{ updatePriority }}>{children}</PrioritizeContext.Provider>
  );
};
