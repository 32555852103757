import React, { ReactElement, useState } from 'react';
import Button from 'components/Button/Button';
import Box from '@mui/material/Box';
import Cookies from 'universal-cookie';
import styles from '../../styles/components/CookieConsent.module.scss';

export const CookieConsent = (): ReactElement => {
  const cookies = new Cookies();
  const [consent, setConsent] = useState(cookies.get('cookie-consent') || 'false');

  if (!consent || consent !== 'true') {
    return (
      <Box
        className={styles.cookie_box_wrap}
      >
        <Box  className={styles.inner_cookie_row}>
        <Box className={styles.cookie_txt}>
        This website requires cookies to save user related information, read our <a href='#'>cookie policy</a> for more details.
        </Box>
        <Button
        className={styles.cookie_btn}
        variant='secondary'
          size='medium'
          onClick={() => {
            cookies.set('cookie-consent', 'true');
            setConsent('true');
            
          }}
        >
          Allow cookies
        </Button>
        </Box>
      </Box>
    );
  }

  return <></>;
};
