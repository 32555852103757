import React, { useState } from 'react';

import SlickSlider from 'react-slick';

interface Props {
  children: React.ReactNode;
  options?: any;
  className?: string;
}

function Slider({ options, children, className }: Props): React.ReactElement {
  const sliderOptions = {
    // Default options
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    // Merge with provided options
    ...options
  };

  const [display, setDisplay] = useState(true);

  return (
    <div>
      <div
        // className={styles[className]}
        className={className}
        style={{
          // width: `${this.state.width}px`,
          display: display ? 'block' : 'none'
        }}
      >
        <SlickSlider {...sliderOptions}>{children}</SlickSlider>
      </div>
    </div>
  );
}

export default Slider;
