import { ApiClient } from './ApiClient';

export interface Subscription {
  id: string;
  startDate: string;
  endDate: string;
  currency: string;
  interval: string;
  productName: string;
  status: string;
  amount: number;
}

export interface OptimysePlan {
  id: string;
  active: boolean;
  created: number;
  default_price: string;
  description: string;
  name: string;
  type: string;
}

export class PaymentService {
  static async getUserSubscriptions(authToken: string): Promise<Subscription[]> {
    const response = await ApiClient.get(`/subscriptions`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  }

  static async createSubscriptionCheckoutSession(
    authToken: string
  ): Promise<{ sessionUrl: string }> {
    const response = await ApiClient.post(
      '/subscriptions/buy',
      {},
      { headers: { Authorization: `Bearer ${authToken}` } }
    );

    return response.data;
  }

  static async getPlans(authToken: string): Promise<OptimysePlan[]> {
    const response = await ApiClient.get(`/subscriptions/plans`, {
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    return response.data;
  }

  static async cancelSubscription(subscriptionId: string, authToken: string): Promise<void> {
    const response = await ApiClient.post(
      `/subscriptions/cancel/${subscriptionId}`,
      {},
      { headers: { Authorization: `Bearer ${authToken}` } }
    );

    return response.data;
  }
}
