import React, { useState } from 'react';

interface DrawerHook {
  openDrawer: (content: React.ReactNode) => void;
  closeDrawer: () => void;
  DrawerContent: React.ReactNode | null;
}

const useDrawer = (): DrawerHook => {
  const [content, setContent] = useState<React.ReactNode | null>(null);

  const openDrawer = (_content: React.ReactNode): void => {
    setContent(_content);
  };

  const closeDrawer = (): void => {
    setContent(null);
  };

  const DrawerContent = content;

  return { openDrawer, closeDrawer, DrawerContent };
};

export default useDrawer;
