import React from 'react';
import { ColumnData } from 'types/table';

export default (): ColumnData[] => {
  return React.useMemo(
    () => [
      {
        id: 'task_name',
        label: 'Task name'
      },
      {
        id: 'priority',
        label: 'Priority'
      },
      {
        id: 'status',
        label: 'Status'
      },
      {
        id: 'progress',
        label: 'Progress'
      },
      {
        id: 'est_date',
        label: 'Est. date'
      },
      {
        id: 'est_time',
        label: 'Est. time'
      },
      {
        id: 'category',
        label: 'Category'
      },
      {
        id: 'comments',
        label: ''
      },
      {
        id: 'action',
        label: ''
      }
    ],
    []
  );
};
