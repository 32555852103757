import React from 'react';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiSwitch, { SwitchProps } from '@mui/material/Switch';
import { styled } from '@mui/material/styles';
import styles from '../../styles/components/Switch.module.scss';

interface CustomSwitchProps {
  label?: string;
  checked?: boolean | undefined;
  disabled?: boolean;
}

const IOSSwitch = styled((props: SwitchProps) => (
  <MuiSwitch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 28,
  height: 18,
  padding: 0,  
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(9px)',
      color: '#fff',
      '& .MuiSwitch-thumb': {

        background:'#FFFFFF',
        
      },
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#075bd6' : '#075bd6',
        opacity: 1,
        border: 0,
        background:'#075bd6',
        
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 1,
        background: '#075bd6',
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
      
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
      background:'#C6C6C6',
      
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 1 : 1,
      background: '#EBEBEB',
      border: '1px solid #D4D4D4',
      
      
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 14,
    height: 14,
    background:'#6E7B92',
    
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#FFFFFF' : '#FFFFFF',
    border: '1px solid #EBEBEB',
    opacity: 1,
    
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

function Switch({ label, checked, disabled }: CustomSwitchProps): React.ReactElement {
  return (
    <Box>
      <FormControlLabel className={styles.switcher_wrap}
        control={<IOSSwitch sx={{ m: 1 }} defaultChecked={checked} disabled={disabled} />}
        label={label}
      />
    </Box>
  );
}

export default Switch;