import * as Crypto from 'crypto-js';

export const encryptUserToken = (
	originalText: string,
	encryptionLevel?: number
): string => {
	const enc = Crypto.enc;
	encryptionLevel = encryptionLevel || 2;
	const KEY = `${process.env.REACT_APP_OPTIMIYSE_SECRET}`;
	const IV = enc.Hex.parse(KEY);

	let hashCopy = Crypto.AES.encrypt(JSON.stringify({ originalText }), KEY, {
		iv: IV,
	}).toString();

	for (let index = 0; index < encryptionLevel; index++) {
		hashCopy = Crypto.AES.encrypt(JSON.stringify({ hashCopy }), KEY, {
			iv: IV,
		}).toString();
	}

	const hashText = hashCopy.toString();
	return hashText;
};

export const decryptUserToken = (
	hashText: string,
	encryptionLevel?: number
): string => {
	const enc = Crypto.enc;
	encryptionLevel = encryptionLevel || 2;
	const KEY = `${process.env.REACT_APP_OPTIMIYSE_SECRET}`;
	const IV = enc.Hex.parse(KEY);

	let stingifiedHash = Crypto.AES.decrypt(hashText, KEY, { iv: IV }).toString(
		enc.Utf8
	);
	let hash = JSON.parse(stingifiedHash);

	for (let index = 0; index < encryptionLevel; index++) {
		stingifiedHash = Crypto.AES.decrypt(hash.hashCopy, KEY, {
			iv: IV,
		}).toString(enc.Utf8);
		hash = JSON.parse(stingifiedHash);
	}

	return hash.originalText;
};
