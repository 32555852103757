import React from 'react';
import {
  SortIcon,
  PriorityIcon,
  ProgressIcon,
  CalendarIcon,
  EstimateTimeIconSmall,
  StatusChangeIcon,
  CategoryIcon,
  FeedbackIcon
} from 'components/Icons';
import { ReactElement } from 'react';
import { FilterItem, Filters } from 'types/filters';

export const filterItems: FilterItem[] = [
  // {
  //   id: 1,
  //   label: 'Sort By',
  //   icon: <SortIcon />,
  //   active: false,
  //   filter: Filters.SORT_BY
  // },

  {
    id: 2,
    label: 'Priority',
    icon: <PriorityIcon />,
    active: false,
    filter: Filters.PRIORITY
  },
  {
    id: 3,
    label: 'Progress',
    icon: <ProgressIcon />,
    active: false,
    filter: Filters.PROGRESS
  },
  {
    id: 4,
    label: 'Estimated Date',
    icon: <CalendarIcon />,
    active: false,
    filter: Filters.ESTIMATED_DATE
  },
  {
    id: 5,
    label: 'Estimated Time',
    icon: <EstimateTimeIconSmall />,
    active: false,
    filter: Filters.ESTIMATED_TIME
  },
  {
    id: 6,
    label: 'Status',
    icon: <StatusChangeIcon />,
    active: false,
    filter: Filters.STATUS
  },
  {
    id: 7,
    label: 'Category',
    icon: <CategoryIcon />,
    active: false,
    filter: Filters.CATEGORY
  },
  {
    id: 8,
    label: 'Comments',
    icon: <FeedbackIcon />,
    active: false,
    filter: Filters.COMMENTS
  }
];

export const selectedFiltersIconMap: Record<Filters, ReactElement> = {
  [Filters.PRIORITY]: <PriorityIcon />,
  [Filters.SORT_BY]: <PriorityIcon />,
  [Filters.STATUS]: <StatusChangeIcon />,
  [Filters.PROGRESS]: <ProgressIcon />,
  [Filters.ESTIMATED_DATE]: <CalendarIcon />,
  [Filters.ESTIMATED_TIME]: <EstimateTimeIconSmall />,
  [Filters.CATEGORY]: <CategoryIcon />,
  [Filters.COMMENTS]: <FeedbackIcon />
};
