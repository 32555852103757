import React, { Component, ReactElement } from 'react';
import { Navigate, RouteObject, createBrowserRouter } from 'react-router-dom';
import { guardedRoute } from './routeGuard';
import { uiRoutes } from './uiRoutes';
import App from 'App';
import logo from '../assets/optimyse-logo.png';
import LoginScreen from '../screens/login/Login';
import GeneralScreen from '../screens/general-screen/GeneralScreen';
import GuestUserScreen from 'screens/guest-user/GuestUserScreen';
import PrioritizeScreen from '../screens/prioritize/PrioritizeScreen';
import SettingsScreenWrapper from '../screens/settings/SettingsScreenWrapper';
import BacklogScreen from 'screens/backlog/BacklogScreen';
import PaymentScreen from 'screens/payment/PaymentScreen';
import PaymentUnauthorizedScreen from 'screens/payment/PaymentUnauthorizedScreen';
import PaymentSuccessfulScreen from 'screens/payment/PaymentSuccessfulScreen';
import PaymentFailureScreen from 'screens/payment/PaymentFailureScreen';

const hideRoute = (_: ReactElement): any => {
  return <Navigate to={'/'} />;
};

export const AppRoutes: RouteObject[] = [
  {
    path: '/login',
    element: hideRoute(<LoginScreen text='login page' imgUrl={logo} />)
  },

  ////////// Payment Module Routes //////////////
  {
    path: '/payment',
    element: <PaymentScreen />
  },

  {
    path: '/payment/unauthorized',
    element: <PaymentUnauthorizedScreen />
  },

  {
    path: '/payment/success',
    element: <PaymentSuccessfulScreen />
  },

  {
    path: '/payment/failure',
    element: <PaymentFailureScreen />
  },

  {
    path: '/',
    element: hideRoute(guardedRoute(<App />))
  },
  {
    path: '/dashboard',
    element: hideRoute(guardedRoute(<App />))
  },
  {
    path: '/prioritize',
    element: hideRoute(guardedRoute(<PrioritizeScreen />))
  },
  {
    path: '/resource-planning',
    element: hideRoute(guardedRoute(<GeneralScreen text='Resource planning page' imgUrl={logo} />))
  },
  {
    path: '/roadmap',
    element: hideRoute(guardedRoute(<GeneralScreen text='Roadmap page' imgUrl={logo} />))
  },
  {
    path: '/settings',
    element: hideRoute(<SettingsScreenWrapper />)
  },
  {
    path: '/backlog',
    element: hideRoute(<BacklogScreen />)
  },
  {
    path: '/support',
    element: hideRoute(guardedRoute(<GeneralScreen text='Support page' imgUrl={logo} />))
  },
  {
    path: '/guest-user',
    element: hideRoute(
      guardedRoute(<GuestUserScreen text='Guest page' imgUrl={logo} />, '/guest-user')
    )
  },
  {
    path: '/*',
    element: hideRoute(<Navigate to={'/'} />)
  },
  ...uiRoutes
];

export const router = createBrowserRouter(AppRoutes);
