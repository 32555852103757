import { createSlice } from '@reduxjs/toolkit';
import { User, UserStatus } from 'types/user';

export const initialUserState: User = {
  token: '',
  userName: '',
  userEmail: '',
  userPicture: '',
  emailVerified: false,
  isAuthenticated: false,
  id: '',
  status: UserStatus.INACTIVE,
  roleId: '',
  organizationId: ''
};

export const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    updateToken: (state, action) => {
      state.token = action.payload;
    },
    updateUser: (state, action) => {
      const {
        userEmail,
        userName,
        userPicture,
        emailVerified,
        isAuthenticated,
        id,
        status,
        roleId,
        organizationId
      } = action.payload;
      state.userEmail = userEmail;
      state.userName = userName;
      state.userPicture = userPicture;
      state.emailVerified = emailVerified;
      state.isAuthenticated = isAuthenticated;

      state.id = id || initialUserState.id;
      state.status = status || initialUserState.status;
      state.roleId = roleId || initialUserState.roleId;
      state.organizationId = organizationId || initialUserState.organizationId;
    },
    cleanUserState: (state) => {
      state.token = '';
      state.userEmail = '';
      state.userName = '';
      state.userPicture = '';
      state.emailVerified = false;
      state.isAuthenticated = false;
    }
  }
});

// Action creators are generated for each case reducer function
export const { updateToken, updateUser, cleanUserState } = userSlice.actions;

export default userSlice.reducer;
