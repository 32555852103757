import * as React from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { Priority } from 'types/api';
import { ReactComponent as downArrow } from 'assets/images/global-icons/down-arrow.svg';
import styles from 'styles/components/PriorityDropdown.module.scss';

const DEFAULT_COLOR = '#E5E5E5';

interface PriorityOption {
  label: string;
  value: string;
  color: string;
}

interface PriorityDropdownProps {
  issueId: string;
  selectedValue: string;
  priorities: Priority[];
  onChange: (issueId: string, priority: string) => void;
}

const getOptions = (priorities: Priority[]): PriorityOption[] => {
  return priorities.map(({ id, name, color }) => ({
    label: name,
    value: id,
    color
  }));
};

export default function PriorityDropdown({
  issueId,
  selectedValue,
  priorities,
  onChange
}: PriorityDropdownProps): React.ReactElement {
  const options: PriorityOption[] = getOptions(priorities);

  const [value, setValue] = React.useState(selectedValue);
  const selectedColor = React.useMemo(() => {
    return options.find((opt) => opt.value === value)?.color || DEFAULT_COLOR;
  }, [options, value]);

  const handleChange = (e: SelectChangeEvent<string>): void => {
    const priorityId = e.target.value;
    setValue(priorityId);
    onChange(issueId, priorityId);
  };

  const renderOptions = (): React.ReactElement[] => {
    return options.map((item, index) => {
      return (
        <MenuItem
          className={`${styles.priorityDropdownItem}`}
          key={`${item.value}-${index}`}
          aria-roledescription={item.value}
          value={item.value}
          style={{ borderColor: item.color }}
        >
          <span className={styles.circle} style={{ backgroundColor: item.color }}></span>
          {item.label}
        </MenuItem>
      );
    });
  };

  return (
    <div className={styles.priorityDropdownMain}>
      {
        <Select
          labelId='select-label'
          id='demo-customized-select'
          value={value}
          onChange={handleChange}
          className={styles.priorityDropdown}
          style={{
            borderColor: selectedColor
          }}
          IconComponent={downArrow}
        >
          {renderOptions()}
        </Select>
      }
    </div>
  );
}
